import React, { ReactElement } from "react";
import { useLocation, useParams } from "react-router-dom";

import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Unstable_Grid2";

import SectionsBreadCrumbs from "components/shared/breadCrumbs/SectionsBreadCrumbs";

import ServerWebsitePage from "./server/ServerWebsitePage";
import MailsPage from "./MailsPage";
import ZonePage from "./ZonePage";

const AppsPage = (): ReactElement => {
 const location = useLocation();
 const params = useParams<{ section: string }>();

 const RenderAddon = (): ReactElement => {
  switch (params.section) {
   case "sites":
    return <ServerWebsitePage />;
   case "mail":
    return <MailsPage />;
   case "zone":
    return <ZonePage />;
   default:
    return <ServerWebsitePage />;
  }
 };
 return (
  <Container maxWidth="xl">
   <Stack mt={2}>
    <SectionsBreadCrumbs
     links={[
      { name: "home", url: "/" },
      { name: "apps", url: "/app" },
      { name: params?.section || "", url: location.pathname }
     ]}
    />
   </Stack>
   <Grid container>
    <Grid xs={12}>
     <RenderAddon />
    </Grid>
   </Grid>
  </Container>
 );
};

export default AppsPage;
