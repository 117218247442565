import React, { ReactElement, useContext, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useDebounce from "react-use/lib/useDebounce";
import { AppContext } from "AppContext";

import { isNil } from "ramda";

import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";

import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TablePagination from "@mui/material/TablePagination";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { domainsTable } from "constants/tables";

import AdminWrapper from "components/shared/admin/AdminWrapper";
import SearchForPartner from "components/shared/search/SearchForPartner";
import SkeletonProfile from "components/shared/skeletons/SkeletonProfile";
import SortingTable from "components/shared/tables/SortingTable";

import { getAllDomains } from "redux/handlers/domainsHandler";
import { getUsersList } from "redux/handlers/userHandler";

import { getDomainsList, getDomainsNumber } from "redux/selectors/domainsSelector";
import { getIsAdmin } from "redux/selectors/userSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { IUsersList } from "types/api/userApiInterface";

const DomainsList = (): ReactElement => {
 const { socket } = useContext(AppContext);
 const dispatch = useAppDispatch();
 const navigate = useNavigate();

 const domainsList = useSelector(getDomainsList);
 const domainsNumber = useSelector(getDomainsNumber);
 const isAdmin = useSelector(getIsAdmin);

 const searchRef = useRef<HTMLInputElement>(null);

 const [currentPage, setCurrentPage] = useState<number>(0);
 const [rowPerPage, setRowPerPage] = useState<number>(10);
 const [searchValue, setSearchValue] = useState<string>("");
 const [isLoading, setIsLoading] = useState<boolean>(true);
 const [contactsList, setContactsList] = useState<Array<IUsersList>>([]);
 const [selectedPartner, setSelectedPartner] = useState<string>("");
 const [reloadData, setReloadData] = useState<boolean>(false);

 useEffect(() => {
  (async () => {
   setCurrentPage(0);
   await dispatch(getAllDomains(0, rowPerPage, searchRef?.current?.value, selectedPartner));
   isAdmin && setContactsList(await dispatch(getUsersList()));
   setIsLoading(false);
  })();
 }, []);

 useEffect(() => {
  !isNil(socket) &&
   socket.on("domain_data", (value): void => {
    if (value?.completed) {
     setReloadData(true);
    }
   });
  return () => {
   !isNil(socket) && socket.off("domain_data");
  };
 }, [socket]);

 useEffect(() => {
  (async () => {
   if (reloadData) {
    dispatch(getAllDomains(currentPage, rowPerPage, searchRef?.current?.value, selectedPartner));
    setReloadData(false);
   }
  })();
 }, [reloadData]);

 useDebounce(
  () => {
   if (!isLoading) {
    setCurrentPage(0);
    dispatch(getAllDomains(currentPage, rowPerPage, searchRef?.current?.value, selectedPartner));
   }
  },
  1000,
  [searchValue]
 );

 const handleOnRowsPerPageChange = (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
 ) => {
  const newRowsPerPage = parseInt(event.target.value, 10);
  setRowPerPage(newRowsPerPage);
  setCurrentPage(0);
  dispatch(getAllDomains(0, newRowsPerPage, searchRef?.current?.value, selectedPartner));
 };

 const handleOnPageChange = async (
  event: React.MouseEvent<HTMLButtonElement> | null,
  newPage: number
 ) => {
  const startIndex = newPage * rowPerPage;
  setCurrentPage(newPage);
  dispatch(getAllDomains(startIndex, rowPerPage, searchRef?.current?.value, selectedPartner));
 };

 const handleRedirectToMailCreateDomain = () => {
  navigate("/domains/add");
 };

 const handleSearchPartner = (partnerIdValue: string) => {
  setCurrentPage(0);
  setSelectedPartner(partnerIdValue);
  dispatch(getAllDomains(currentPage, rowPerPage, searchRef?.current?.value || "", partnerIdValue));
 };

 return (
  <Stack>
   {isLoading ? (
    <SkeletonProfile id="app.domains" />
   ) : (
    <Stack mt={5}>
     <Stack justifyContent="space-between" alignItems="flex-end" direction="row" pb={1}>
      <Typography fontWeight="bold" fontSize={16} textTransform="uppercase">
       <FormattedMessage id="app.domains" />
       {` (${domainsNumber})`}
      </Typography>
      <Button
       variant="kxActionButton"
       endIcon={<AddIcon />}
       onClick={handleRedirectToMailCreateDomain}>
       <FormattedMessage id="app.addDomain" />
      </Button>
     </Stack>
     <Paper elevation={0} sx={{ borderRadius: "10px", boxShadow: 0 }}>
      <Stack justifyContent="flex-start" alignItems="center" p={2} direction="row">
       <TextField
        size="small"
        autoComplete="off"
        inputRef={searchRef}
        label={<FormattedMessage id="search.domains" />}
        InputLabelProps={{ shrink: true }}
        InputProps={{ startAdornment: <SearchIcon color="disabled" /> }}
        onChange={({ currentTarget }) => setSearchValue(currentTarget.value)}
       />
       <AdminWrapper sx={{ ml: 2 }}>
        <SearchForPartner
         contactList={contactsList}
         handleSearchPartner={(partner) => handleSearchPartner(partner)}
        />
       </AdminWrapper>
      </Stack>
      <Divider sx={{ borderColor: "#f86e06" }} />
      <Stack>
       <SortingTable
        tableData={domainsTable}
        maxWidth={600}
        caller="domains"
        listToShow={domainsList}
       />
       <TablePagination
        component="div"
        count={domainsNumber || 0}
        page={currentPage}
        rowsPerPage={rowPerPage}
        onPageChange={handleOnPageChange}
        onRowsPerPageChange={handleOnRowsPerPageChange}
       />
      </Stack>
     </Paper>
    </Stack>
   )}
  </Stack>
 );
};

export default DomainsList;
