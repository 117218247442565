import React, { ReactElement, useState } from "react";
import { Controller } from "react-hook-form";
import { useIntl } from "react-intl";

import { isNil } from "ramda";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";

import { IAllAddonsAPI, IAllAddonsDataset } from "types/api/addonsApiInterface";
import { addonsPermissionType, addonsSelectedList } from "types/global/user";

type sectionType =
 | "cloudboxes"
 | "volumes"
 | "load_balancers"
 | "floating_ips"
 | "snapshots"
 | "networks"
 | "firewalls";

type permissionType =
 | "showCloudbox"
 | "showFirewall"
 | "showFloatingIp"
 | "showNetwork"
 | "showVolume"
 | "showLoadBalancer"
 | "editFloatingIp"
 | "editFloatingIpRdns"
 | "editFloatingIpServer"
 | "editVolume"
 | "editVolumeMount"
 | "editVolumeServer"
 | "editLoadBalancer"
 | "showCloudboxCommands"
 | "showCloudboxDetails"
 | "editCloudboxDetails"
 | "showCloudboxSettings"
 | "editCloudboxSettings"
 | "showCloudboxServer"
 | "editCloudboxServer"
 | "showCloudboxSnapshot"
 | "editCloudboxSnapshot"
 | "showNetworkDetails"
 | "editNetworkDetails"
 | "showNetworkSubnet"
 | "editNetworkSubnet"
 | "showNetworkRoute"
 | "editNetworkRoute"
 | "showFirewallDetails"
 | "editFirewallDetails"
 | "showFirewallServer"
 | "editFirewallServer"
 | "showFirewallInboundRules"
 | "editFirewallInboundRules"
 | "showFirewallOutboundRules"
 | "editFirewallOutboundRules"
 | "showLoadBalancerDetails"
 | "editLoadBalancerDetails"
 | "showLoadBalancerGraph"
 | "showLoadBalancerCertificate"
 | "editLoadBalancerCertificate"
 | "showLoadBalancerService"
 | "showLoadBalancerTarget"
 | "showLoadBalancerNetwork"
 | "editLoadBalancerNetwork"
 | "showSnapshot"
 | "editSnapshot";

const AddonsSection = ({
 control,
 addons,
 selectedAddons,
 addonsPermissions,
 setAddons,
 addAddons,
 setPermission
}: {
 control: any;
 addons: IAllAddonsAPI | null;
 selectedAddons: addonsSelectedList;
 addonsPermissions: addonsPermissionType;
 setAddons: (section: sectionType, value: Array<number>) => void;
 addAddons: (section: sectionType, value: Array<{ id: number; label: string }>) => void;
 setPermission: (section: permissionType, value: boolean) => void;
}): ReactElement => {
 const intl = useIntl();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));

 const [section, setSection] = useState<number>(0);

 const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
  setSection(newValue);
 };

 const RenderTabContent = (): ReactElement => {
  switch (section) {
   case 0:
    return (
     <Stack spacing={2}>
      <Controller
       name="cloudboxes"
       control={control}
       render={({ field }) => (
        <Autocomplete
         {...field}
         multiple
         id="tags-outlined"
         options={
          addons?.cloudboxes
           ? addons?.cloudboxes?.map((element: IAllAddonsDataset) => {
              return {
               id: element.id,
               label: element.name
              };
             })
           : []
         }
         filterSelectedOptions
         value={addons?.cloudboxes
          .filter((element) => selectedAddons.cloudboxes.includes(element.id))
          .map((element) => {
           return {
            id: element.id,
            label: element.name
           };
          })}
         getOptionLabel={(option) => option.label}
         onChange={(e, value) => {
          isNil(value)
           ? setAddons("cloudboxes", selectedAddons.cloudboxes)
           : addAddons("cloudboxes", value);
         }}
         renderInput={(params) => (
          <TextField
           {...params}
           label={intl.formatMessage({ id: "teams.usersettings.selectCloudboxes" })}
           placeholder="Cloudbox..."
          />
         )}
        />
       )}
      />
      <Stack direction={"row"}>
       <Controller
        name="showCloudbox"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            {...field}
            checked={addonsPermissions.showCloudbox}
            onChange={(e, checked) => {
             setPermission("showCloudbox", checked);
            }}
           />
          }
          label={intl.formatMessage({ id: "teams.usersettings.showCloudbox" })}
         />
        )}
       />
       <Controller
        name="showCloudboxCommands"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            {...field}
            checked={addonsPermissions.showCloudboxCommands}
            onChange={(e, checked) => {
             setPermission("showCloudboxCommands", checked);
            }}
           />
          }
          label={intl.formatMessage({ id: "teams.usersettings.showCloudboxCommands" })}
         />
        )}
       />
       <Controller
        name="showCloudboxDetails"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            {...field}
            checked={addonsPermissions.showCloudboxDetails}
            onChange={(e, checked) => {
             setPermission("showCloudboxDetails", checked);
             if (!checked) setPermission("editCloudboxDetails", false);
            }}
           />
          }
          label={intl.formatMessage({ id: "teams.usersettings.showCloudboxDetails" })}
         />
        )}
       />
       <Controller
        name="showCloudboxSettings"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            {...field}
            checked={addonsPermissions.showCloudboxSettings}
            onChange={(e, checked) => {
             setPermission("showCloudboxSettings", checked);
             if (!checked) setPermission("editCloudboxSettings", false);
            }}
           />
          }
          label={intl.formatMessage({ id: "teams.usersettings.showCloudboxSettings" })}
         />
        )}
       />
       <Controller
        name="showCloudboxServer"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            {...field}
            checked={addonsPermissions.showCloudboxServer}
            onChange={(e, checked) => {
             setPermission("showCloudboxServer", checked);
             if (!checked) setPermission("editCloudboxServer", false);
            }}
           />
          }
          label={intl.formatMessage({ id: "teams.usersettings.showCloudboxServer" })}
         />
        )}
       />
       <Controller
        name="showCloudboxSnapshot"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            {...field}
            checked={addonsPermissions.showCloudboxSnapshot}
            onChange={(e, checked) => {
             setPermission("showCloudboxSnapshot", checked);
             if (!checked) setPermission("editCloudboxSnapshot", false);
            }}
           />
          }
          label={intl.formatMessage({ id: "teams.usersettings.showCloudboxSnapshot" })}
         />
        )}
       />
      </Stack>
      <Stack direction={"row"}>
       {addonsPermissions.showCloudboxDetails && (
        <Controller
         name="editCloudboxDetails"
         control={control}
         render={({ field }) => (
          <FormControlLabel
           control={
            <Checkbox
             {...field}
             checked={addonsPermissions.editCloudboxDetails}
             onChange={(e, checked) => {
              setPermission("editCloudboxDetails", checked);
             }}
            />
           }
           label={intl.formatMessage({ id: "teams.usersettings.editCloudboxDetails" })}
          />
         )}
        />
       )}
       {addonsPermissions.showCloudboxSettings && (
        <Controller
         name="editCloudboxSettings"
         control={control}
         render={({ field }) => (
          <FormControlLabel
           control={
            <Checkbox
             {...field}
             checked={addonsPermissions.editCloudboxSettings}
             onChange={(e, checked) => {
              setPermission("editCloudboxSettings", checked);
             }}
            />
           }
           label={intl.formatMessage({ id: "teams.usersettings.editCloudboxSettings" })}
          />
         )}
        />
       )}
       {addonsPermissions.showCloudboxServer && (
        <Controller
         name="editCloudboxServer"
         control={control}
         render={({ field }) => (
          <FormControlLabel
           control={
            <Checkbox
             {...field}
             checked={addonsPermissions.editCloudboxServer}
             onChange={(e, checked) => {
              setPermission("editCloudboxServer", checked);
             }}
            />
           }
           label={intl.formatMessage({ id: "teams.usersettings.editCloudboxServer" })}
          />
         )}
        />
       )}
       {addonsPermissions.showCloudboxSnapshot && (
        <Controller
         name="editCloudboxSnapshot"
         control={control}
         render={({ field }) => (
          <FormControlLabel
           control={
            <Checkbox
             {...field}
             checked={addonsPermissions.editCloudboxSnapshot}
             onChange={(e, checked) => {
              setPermission("editCloudboxSnapshot", checked);
             }}
            />
           }
           label={intl.formatMessage({ id: "teams.usersettings.editCloudboxSnapshot" })}
          />
         )}
        />
       )}
      </Stack>
     </Stack>
    );
   case 1:
    return (
     <Stack spacing={2}>
      <Controller
       name="volumes"
       control={control}
       render={({ field }) => (
        <Autocomplete
         {...field}
         multiple
         id="tags-outlined"
         options={
          addons?.volumes
           ? addons?.volumes?.map((element: IAllAddonsDataset) => {
              return {
               id: element.id,
               label: element.name
              };
             })
           : []
         }
         filterSelectedOptions
         value={addons?.volumes
          .filter((element) => selectedAddons.volumes.includes(element.id))
          .map((element) => {
           return {
            id: element.id,
            label: element.name
           };
          })}
         getOptionLabel={(option) => option.label}
         onChange={(e, value) => {
          isNil(value) ? setAddons("volumes", selectedAddons.volumes) : addAddons("volumes", value);
         }}
         renderInput={(params) => (
          <TextField
           {...params}
           label={intl.formatMessage({ id: "teams.usersettings.selectVolumes" })}
           placeholder="Volume..."
          />
         )}
        />
       )}
      />
      <Stack direction={"row"}>
       <Controller
        name="showVolume"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            {...field}
            checked={addonsPermissions.showVolume}
            onChange={(e, checked) => {
             setPermission("showVolume", checked);
            }}
           />
          }
          label={intl.formatMessage({ id: "teams.usersettings.showVolume" })}
         />
        )}
       />
       <Controller
        name="editVolume"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            {...field}
            checked={addonsPermissions.editVolume}
            onChange={(e, checked) => {
             setPermission("editVolume", checked);
            }}
           />
          }
          label={intl.formatMessage({ id: "teams.usersettings.editVolume" })}
         />
        )}
       />
       <Controller
        name="editVolumeServer"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            {...field}
            checked={addonsPermissions.editVolumeServer}
            onChange={(e, checked) => {
             setPermission("editVolumeServer", checked);
            }}
           />
          }
          label={intl.formatMessage({ id: "teams.usersettings.editVolumeServer" })}
         />
        )}
       />
       <Controller
        name="editVolumeMount"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            {...field}
            checked={addonsPermissions.editVolumeMount}
            onChange={(e, checked) => {
             setPermission("editVolumeMount", checked);
            }}
           />
          }
          label={intl.formatMessage({ id: "teams.usersettings.editVolumeMount" })}
         />
        )}
       />
      </Stack>
     </Stack>
    );
   case 2:
    return (
     <Stack spacing={2}>
      <Controller
       name="floating_ips"
       control={control}
       render={({ field }) => (
        <Autocomplete
         {...field}
         multiple
         id="tags-outlined"
         options={
          addons?.floating_ips
           ? addons?.floating_ips?.map((element: IAllAddonsDataset) => {
              return {
               id: element.id,
               label: element.name
              };
             })
           : []
         }
         filterSelectedOptions
         value={addons?.floating_ips
          .filter((element) => selectedAddons.floating_ips.includes(element.id))
          .map((element) => {
           return {
            id: element.id,
            label: element.name
           };
          })}
         getOptionLabel={(option) => option.label}
         onChange={(e, value) => {
          isNil(value)
           ? setAddons("floating_ips", selectedAddons.floating_ips)
           : addAddons("floating_ips", value);
         }}
         renderInput={(params) => (
          <TextField
           {...params}
           label={intl.formatMessage({ id: "teams.usersettings.selectFloatingIps" })}
           placeholder="Floating IP..."
          />
         )}
        />
       )}
      />
      <Stack direction={"row"}>
       <Controller
        name="showFloatingIp"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            {...field}
            checked={addonsPermissions.showFloatingIp}
            onChange={(e, checked) => {
             setPermission("showFloatingIp", checked);
            }}
           />
          }
          label={intl.formatMessage({ id: "teams.usersettings.showFloatingIp" })}
         />
        )}
       />
       <Controller
        name="editFloatingIp"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            {...field}
            checked={addonsPermissions.editFloatingIp}
            onChange={(e, checked) => {
             setPermission("editFloatingIp", checked);
            }}
           />
          }
          label={intl.formatMessage({ id: "teams.usersettings.editFloatingIp" })}
         />
        )}
       />
       <Controller
        name="editFloatingIpServer"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            {...field}
            checked={addonsPermissions.editFloatingIpServer}
            onChange={(e, checked) => {
             setPermission("editFloatingIpServer", checked);
            }}
           />
          }
          label={intl.formatMessage({ id: "teams.usersettings.editFloatingIpServer" })}
         />
        )}
       />
       <Controller
        name="editFloatingIpRdns"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            {...field}
            checked={addonsPermissions.editFloatingIpRdns}
            onChange={(e, checked) => {
             setPermission("editFloatingIpRdns", checked);
            }}
           />
          }
          label={intl.formatMessage({ id: "teams.usersettings.editFloatingIpRdns" })}
         />
        )}
       />
      </Stack>
     </Stack>
    );
   case 3:
    return (
     <Stack spacing={2}>
      <Controller
       name="networks"
       control={control}
       render={({ field }) => (
        <Autocomplete
         {...field}
         multiple
         id="tags-outlined"
         options={
          addons?.networks
           ? addons?.networks?.map((element: IAllAddonsDataset) => {
              return {
               id: element.id,
               label: element.name
              };
             })
           : []
         }
         filterSelectedOptions
         value={addons?.networks
          .filter((element) => selectedAddons.networks.includes(element.id))
          .map((element) => {
           return {
            id: element.id,
            label: element.name
           };
          })}
         getOptionLabel={(option) => option.label}
         onChange={(e, value) => {
          isNil(value)
           ? setAddons("networks", selectedAddons.networks)
           : addAddons("networks", value);
         }}
         renderInput={(params) => (
          <TextField
           {...params}
           label={intl.formatMessage({ id: "teams.usersettings.selectNetworks" })}
           placeholder="Network..."
          />
         )}
        />
       )}
      />
      <Stack direction={"row"}>
       <Controller
        name="showNetwork"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            {...field}
            checked={addonsPermissions.showNetwork}
            onChange={(e, checked) => {
             setPermission("showNetwork", checked);
            }}
           />
          }
          label={intl.formatMessage({ id: "teams.usersettings.showNetwork" })}
         />
        )}
       />
       <Controller
        name="showNetworkDetails"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            {...field}
            checked={addonsPermissions.showNetworkDetails}
            onChange={(e, checked) => {
             setPermission("showNetworkDetails", checked);
             if (!checked) setPermission("editNetworkDetails", false);
            }}
           />
          }
          label={intl.formatMessage({ id: "teams.usersettings.showNetworkDetails" })}
         />
        )}
       />
       <Controller
        name="showNetworkSubnet"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            {...field}
            checked={addonsPermissions.showNetworkSubnet}
            onChange={(e, checked) => {
             setPermission("showNetworkSubnet", checked);
             if (!checked) setPermission("editNetworkSubnet", false);
            }}
           />
          }
          label={intl.formatMessage({ id: "teams.usersettings.showNetworkSubnet" })}
         />
        )}
       />
       <Controller
        name="showNetworkRoute"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            {...field}
            checked={addonsPermissions.showNetworkRoute}
            onChange={(e, checked) => {
             setPermission("showNetworkRoute", checked);
             if (!checked) setPermission("editNetworkRoute", false);
            }}
           />
          }
          label={intl.formatMessage({ id: "teams.usersettings.showNetworkRoute" })}
         />
        )}
       />
      </Stack>
      <Stack direction={"row"}>
       {addonsPermissions.showNetworkDetails && (
        <Controller
         name="editNetworkDetails"
         control={control}
         render={({ field }) => (
          <FormControlLabel
           control={
            <Checkbox
             {...field}
             checked={addonsPermissions.editNetworkDetails}
             onChange={(e, checked) => {
              setPermission("editNetworkDetails", checked);
             }}
            />
           }
           label={intl.formatMessage({ id: "teams.usersettings.editNetworkDetails" })}
          />
         )}
        />
       )}
       {addonsPermissions.showNetworkSubnet && (
        <Controller
         name="editNetworkSubnet"
         control={control}
         render={({ field }) => (
          <FormControlLabel
           control={
            <Checkbox
             {...field}
             checked={addonsPermissions.editNetworkSubnet}
             onChange={(e, checked) => {
              setPermission("editNetworkSubnet", checked);
             }}
            />
           }
           label={intl.formatMessage({ id: "teams.usersettings.editNetworkSubnet" })}
          />
         )}
        />
       )}
       {addonsPermissions.showNetworkRoute && (
        <Controller
         name="editNetworkRoute"
         control={control}
         render={({ field }) => (
          <FormControlLabel
           control={
            <Checkbox
             {...field}
             checked={addonsPermissions.editNetworkRoute}
             onChange={(e, checked) => {
              setPermission("editNetworkRoute", checked);
             }}
            />
           }
           label={intl.formatMessage({ id: "teams.usersettings.editNetworkRoute" })}
          />
         )}
        />
       )}
      </Stack>
     </Stack>
    );
   case 4:
    return (
     <Stack spacing={2}>
      <Controller
       name="snapshots"
       control={control}
       render={({ field }) => (
        <Autocomplete
         {...field}
         multiple
         id="tags-outlined"
         options={
          addons?.snapshots
           ? addons?.snapshots?.map((element: IAllAddonsDataset) => {
              return {
               id: element.id,
               label: element.name
              };
             })
           : []
         }
         filterSelectedOptions
         value={addons?.snapshots
          .filter((element) => selectedAddons.snapshots.includes(element.id))
          .map((element) => {
           return {
            id: element.id,
            label: element.name
           };
          })}
         getOptionLabel={(option) => option.label}
         onChange={(e, value) => {
          isNil(value)
           ? setAddons("snapshots", selectedAddons.snapshots)
           : addAddons("snapshots", value);
         }}
         renderInput={(params) => (
          <TextField
           {...params}
           label={intl.formatMessage({ id: "teams.usersettings.selectSnapshots" })}
           placeholder="Snapshot..."
          />
         )}
        />
       )}
      />
      <Stack direction={"row"}>
       <Controller
        name="showSnapshot"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            {...field}
            checked={addonsPermissions.showSnapshot}
            onChange={(e, checked) => {
             setPermission("showSnapshot", checked);
            }}
           />
          }
          label={intl.formatMessage({ id: "teams.usersettings.showSnapshot" })}
         />
        )}
       />
       <Controller
        name="editSnapshot"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            {...field}
            checked={addonsPermissions.editSnapshot}
            onChange={(e, checked) => {
             setPermission("editSnapshot", checked);
            }}
           />
          }
          label={intl.formatMessage({ id: "teams.usersettings.editSnapshot" })}
         />
        )}
       />
      </Stack>
     </Stack>
    );
   case 5:
    return (
     <Stack spacing={2}>
      <Controller
       name="firewalls"
       control={control}
       render={({ field }) => (
        <Autocomplete
         {...field}
         multiple
         id="tags-outlined"
         options={
          addons?.firewalls
           ? addons?.firewalls?.map((element: IAllAddonsDataset) => {
              return {
               id: element.id,
               label: element.name
              };
             })
           : []
         }
         filterSelectedOptions
         value={addons?.firewalls
          .filter((element) => selectedAddons.firewalls.includes(element.id))
          .map((element) => {
           return {
            id: element.id,
            label: element.name
           };
          })}
         getOptionLabel={(option) => option.label}
         onChange={(e, value) => {
          isNil(value)
           ? setAddons("firewalls", selectedAddons.firewalls)
           : addAddons("firewalls", value);
         }}
         renderInput={(params) => (
          <TextField
           {...params}
           label={intl.formatMessage({ id: "teams.usersettings.selectFirewalls" })}
           placeholder="Site..."
          />
         )}
        />
       )}
      />
      <Stack direction={"row"}>
       <Controller
        name="showFirewall"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            {...field}
            checked={addonsPermissions.showFirewall}
            onChange={(e, checked) => {
             setPermission("showFirewall", checked);
            }}
           />
          }
          label={intl.formatMessage({ id: "teams.usersettings.showFirewall" })}
         />
        )}
       />
       <Controller
        name="showFirewallDetails"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            {...field}
            checked={addonsPermissions.showFirewallDetails}
            onChange={(e, checked) => {
             setPermission("showFirewallDetails", checked);
             if (!checked) setPermission("editFirewallDetails", false);
            }}
           />
          }
          label={intl.formatMessage({ id: "teams.usersettings.showFirewallDetails" })}
         />
        )}
       />
       <Controller
        name="showFirewallServer"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            {...field}
            checked={addonsPermissions.showFirewallServer}
            onChange={(e, checked) => {
             setPermission("showFirewallServer", checked);
             if (!checked) setPermission("editFirewallServer", false);
            }}
           />
          }
          label={intl.formatMessage({ id: "teams.usersettings.showFirewallServer" })}
         />
        )}
       />
       <Controller
        name="showFirewallInboundRules"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            {...field}
            checked={addonsPermissions.showFirewallInboundRules}
            onChange={(e, checked) => {
             setPermission("showFirewallInboundRules", checked);
             if (!checked) setPermission("editFirewallInboundRules", false);
            }}
           />
          }
          label={intl.formatMessage({ id: "teams.usersettings.showFirewallInboundRules" })}
         />
        )}
       />
       <Controller
        name="showFirewallOutboundRules"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            {...field}
            checked={addonsPermissions.showFirewallOutboundRules}
            onChange={(e, checked) => {
             setPermission("showFirewallOutboundRules", checked);
             if (!checked) setPermission("editFirewallOutboundRules", false);
            }}
           />
          }
          label={intl.formatMessage({ id: "teams.usersettings.showFirewallOutboundRules" })}
         />
        )}
       />
      </Stack>
      <Stack direction={"row"}>
       {addonsPermissions.showFirewallDetails && (
        <Controller
         name="editFirewallDetails"
         control={control}
         render={({ field }) => (
          <FormControlLabel
           control={
            <Checkbox
             {...field}
             checked={addonsPermissions.editFirewallDetails}
             onChange={(e, checked) => {
              setPermission("editFirewallDetails", checked);
             }}
            />
           }
           label={intl.formatMessage({ id: "teams.usersettings.editFirewallDetails" })}
          />
         )}
        />
       )}
       {addonsPermissions.showFirewallServer && (
        <Controller
         name="editFirewallServer"
         control={control}
         render={({ field }) => (
          <FormControlLabel
           control={
            <Checkbox
             {...field}
             checked={addonsPermissions.editFirewallServer}
             onChange={(e, checked) => {
              setPermission("editFirewallServer", checked);
             }}
            />
           }
           label={intl.formatMessage({ id: "teams.usersettings.editFirewallServer" })}
          />
         )}
        />
       )}
       {addonsPermissions.showFirewallInboundRules && (
        <Controller
         name="editFirewallInboundRules"
         control={control}
         render={({ field }) => (
          <FormControlLabel
           control={
            <Checkbox
             {...field}
             checked={addonsPermissions.editFirewallInboundRules}
             onChange={(e, checked) => {
              setPermission("editFirewallInboundRules", checked);
             }}
            />
           }
           label={intl.formatMessage({ id: "teams.usersettings.editFirewallInboundRules" })}
          />
         )}
        />
       )}
       {addonsPermissions.showFirewallOutboundRules && (
        <Controller
         name="editFirewallOutboundRules"
         control={control}
         render={({ field }) => (
          <FormControlLabel
           control={
            <Checkbox
             {...field}
             checked={addonsPermissions.editFirewallOutboundRules}
             onChange={(e, checked) => {
              setPermission("editFirewallOutboundRules", checked);
             }}
            />
           }
           label={intl.formatMessage({ id: "teams.usersettings.editFirewallOutboundRules" })}
          />
         )}
        />
       )}
      </Stack>
     </Stack>
    );
   case 6:
    return (
     <Stack spacing={2}>
      <Controller
       name="load_balancers"
       control={control}
       render={({ field }) => (
        <Autocomplete
         {...field}
         multiple
         id="tags-outlined"
         options={
          addons?.load_balancers
           ? addons?.load_balancers?.map((element: IAllAddonsDataset) => {
              return {
               id: element.id,
               label: element.name
              };
             })
           : []
         }
         filterSelectedOptions
         value={addons?.load_balancers
          .filter((element) => selectedAddons.load_balancers.includes(element.id))
          .map((element) => {
           return {
            id: element.id,
            label: element.name
           };
          })}
         getOptionLabel={(option) => option.label}
         onChange={(e, value) => {
          isNil(value)
           ? setAddons("load_balancers", selectedAddons.load_balancers)
           : addAddons("load_balancers", value);
         }}
         renderInput={(params) => (
          <TextField
           {...params}
           label={intl.formatMessage({ id: "teams.usersettings.selectLoadBalancers" })}
           placeholder="Load balancer..."
          />
         )}
        />
       )}
      />
      <Stack direction={"row"}>
       <Controller
        name="showLoadBalancer"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            {...field}
            checked={addonsPermissions.showLoadBalancer}
            onChange={(e, checked) => {
             setPermission("showLoadBalancer", checked);
            }}
           />
          }
          label={intl.formatMessage({ id: "teams.usersettings.showLoadBalancer" })}
         />
        )}
       />
       <Controller
        name="showLoadBalancerGraph"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            {...field}
            checked={addonsPermissions.showLoadBalancerGraph}
            onChange={(e, checked) => {
             setPermission("showLoadBalancerGraph", checked);
            }}
           />
          }
          label={intl.formatMessage({ id: "teams.usersettings.showLoadBalancerGraph" })}
         />
        )}
       />
       <Controller
        name="showLoadBalancerTarget"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            {...field}
            checked={addonsPermissions.showLoadBalancerTarget}
            onChange={(e, checked) => {
             setPermission("showLoadBalancerTarget", checked);
            }}
           />
          }
          label={intl.formatMessage({ id: "teams.usersettings.showLoadBalancerTarget" })}
         />
        )}
       />
       <Controller
        name="showLoadBalancerService"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            {...field}
            checked={addonsPermissions.showLoadBalancerService}
            onChange={(e, checked) => {
             setPermission("showLoadBalancerService", checked);
            }}
           />
          }
          label={intl.formatMessage({ id: "teams.usersettings.showLoadBalancerService" })}
         />
        )}
       />
       <Controller
        name="showLoadBalancerDetails"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            {...field}
            checked={addonsPermissions.showLoadBalancerDetails}
            onChange={(e, checked) => {
             setPermission("showLoadBalancerDetails", checked);
             if (!checked) setPermission("editLoadBalancerDetails", false);
            }}
           />
          }
          label={intl.formatMessage({ id: "teams.usersettings.showLoadBalancerDetails" })}
         />
        )}
       />
       <Controller
        name="showLoadBalancerCertificate"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            {...field}
            checked={addonsPermissions.showLoadBalancerCertificate}
            onChange={(e, checked) => {
             setPermission("showLoadBalancerCertificate", checked);
             if (!checked) setPermission("editLoadBalancerCertificate", false);
            }}
           />
          }
          label={intl.formatMessage({ id: "teams.usersettings.showLoadBalancerCertificate" })}
         />
        )}
       />
       <Controller
        name="showLoadBalancerNetwork"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            {...field}
            checked={addonsPermissions.showLoadBalancerNetwork}
            onChange={(e, checked) => {
             setPermission("showLoadBalancerNetwork", checked);
             if (!checked) setPermission("editLoadBalancerNetwork", false);
            }}
           />
          }
          label={intl.formatMessage({ id: "teams.usersettings.showLoadBalancerNetwork" })}
         />
        )}
       />
      </Stack>
      <Stack direction={"row"}>
       {addonsPermissions.showLoadBalancerDetails && (
        <Controller
         name="editLoadBalancerDetails"
         control={control}
         render={({ field }) => (
          <FormControlLabel
           control={
            <Checkbox
             {...field}
             checked={addonsPermissions.editLoadBalancerDetails}
             onChange={(e, checked) => {
              setPermission("editLoadBalancerDetails", checked);
             }}
            />
           }
           label={intl.formatMessage({ id: "teams.usersettings.editLoadBalancerDetails" })}
          />
         )}
        />
       )}
       {addonsPermissions.showLoadBalancerCertificate && (
        <Controller
         name="editLoadBalancerCertificate"
         control={control}
         render={({ field }) => (
          <FormControlLabel
           control={
            <Checkbox
             {...field}
             checked={addonsPermissions.editLoadBalancerCertificate}
             onChange={(e, checked) => {
              setPermission("editLoadBalancerCertificate", checked);
             }}
            />
           }
           label={intl.formatMessage({ id: "teams.usersettings.editLoadBalancerCertificate" })}
          />
         )}
        />
       )}
       {addonsPermissions.showLoadBalancerNetwork && (
        <Controller
         name="editLoadBalancerNetwork"
         control={control}
         render={({ field }) => (
          <FormControlLabel
           control={
            <Checkbox
             {...field}
             checked={addonsPermissions.editLoadBalancerNetwork}
             onChange={(e, checked) => {
              setPermission("editLoadBalancerNetwork", checked);
             }}
            />
           }
           label={intl.formatMessage({ id: "teams.usersettings.editLoadBalancerNetwork" })}
          />
         )}
        />
       )}
      </Stack>
     </Stack>
    );
   default:
    return (
     <Stack spacing={2}>
      <Controller
       name="cloudboxes"
       control={control}
       render={({ field }) => (
        <Autocomplete
         {...field}
         multiple
         id="tags-outlined"
         options={
          addons?.cloudboxes
           ? addons?.cloudboxes?.map((element: IAllAddonsDataset) => {
              return {
               id: element.id,
               label: element.name
              };
             })
           : []
         }
         filterSelectedOptions
         value={addons?.cloudboxes
          .filter((element) => selectedAddons.cloudboxes.includes(element.id))
          .map((element) => {
           return {
            id: element.id,
            label: element.name
           };
          })}
         getOptionLabel={(option) => option.label}
         onChange={(e, value) => {
          isNil(value)
           ? setAddons("cloudboxes", selectedAddons.cloudboxes)
           : addAddons("cloudboxes", value);
         }}
         renderInput={(params) => (
          <TextField
           {...params}
           label={intl.formatMessage({ id: "teams.usersettings.selectCloudboxes" })}
           placeholder="Site..."
          />
         )}
        />
       )}
      />
     </Stack>
    );
  }
 };

 return (
  <Accordion>
   <AccordionSummary expandIcon={<ExpandMoreIcon />}>Addons</AccordionSummary>
   <AccordionDetails>
    <Tabs
     value={section}
     onChange={handleChangeTab}
     variant={desktopViewPort ? "standard" : "scrollable"}
     scrollButtons={true}
     allowScrollButtonsMobile
     sx={{ mb: 2 }}>
     <Tab label={intl.formatMessage({ id: "app.cloudbox" })} />
     <Tab label={intl.formatMessage({ id: "app.volumes" })} />
     <Tab label={intl.formatMessage({ id: "app.floatingIp" })} />
     <Tab label={intl.formatMessage({ id: "app.networks" })} />
     <Tab label={intl.formatMessage({ id: "app.snapshots" })} />
     <Tab label={intl.formatMessage({ id: "app.firewall" })} />
     <Tab label={intl.formatMessage({ id: "app.loadBalancer" })} />
    </Tabs>
    <RenderTabContent />
   </AccordionDetails>
  </Accordion>
 );
};

export default AddonsSection;
