import React, { ReactElement, useContext, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import useDebounce from "react-use/lib/useDebounce";
import { AppContext } from "AppContext";

import { isNil } from "ramda";

import SearchIcon from "@mui/icons-material/Search";

import Alert from "@mui/material/Alert";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TablePagination from "@mui/material/TablePagination";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { zonesTable } from "constants/tables";

import NoServerFound from "components/shared/emptyList/NoServerFound";
import SortingTable from "components/shared/tables/SortingTable";

import { getAllZone } from "redux/handlers/zoneHandler";

import { getIspList } from "redux/selectors/ispSelector";
import { getZoneList, getZoneNumber } from "redux/selectors/zoneSelector";

import { useAppDispatch } from "hooks/reduxHook";

const ZonesList = (): ReactElement => {
 const dispatch = useAppDispatch();
 const zoneList = useSelector(getZoneList);
 const zoneNumber = useSelector(getZoneNumber);
 const { socket } = useContext(AppContext);
 const ispList = useSelector(getIspList);

 const searchRef = useRef<HTMLInputElement | null>(null);

 const [currentPage, setCurrentPage] = useState<number>(0);
 const [rowPerPage, setRowPerPage] = useState<number>(10);
 const [searchValue, setSearchValue] = useState<string>("");
 const [isLoading, setIsLoading] = useState<boolean>(true);

 useEffect(() => {
  (async () => {
   setIsLoading(true);
   await dispatch(getAllZone(currentPage, rowPerPage, searchRef?.current?.value));
   setIsLoading(false);
  })();
 }, [currentPage, rowPerPage]);

 useEffect(() => {
  !isNil(socket) &&
   socket.on("app_data", (value): void => {
    if (value?.completed && value?.section === "zone") {
     dispatch(getAllZone(currentPage, rowPerPage, searchRef?.current?.value));
    }
   });
  return () => {
   !isNil(socket) && socket.off("app_data");
  };
 }, [socket]);

 useDebounce(
  () => {
   if (!isLoading) {
    setCurrentPage(0);
    dispatch(getAllZone(currentPage, rowPerPage, searchRef?.current?.value));
   }
  },
  1000,
  [searchValue]
 );
 const handleOnRowsPerPageChange = (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
 ) => {
  const newRowsPerPage = parseInt(event.target.value, 10);
  setRowPerPage(newRowsPerPage);
  setCurrentPage(0);
  dispatch(getAllZone(0, newRowsPerPage, searchRef?.current?.value));
 };

 const handleOnPageChange = async (
  event: React.MouseEvent<HTMLButtonElement> | null,
  newPage: number
 ) => {
  const startIndex = newPage;
  setCurrentPage(newPage);
  dispatch(getAllZone(startIndex, rowPerPage, searchRef?.current?.value));
 };

 return (
  <Container maxWidth="xl">
   <Stack mt={5}>
    <Stack justifyContent="space-between" alignItems="center" direction="row" pb={1}>
     <Typography fontWeight="bold" fontSize={16} textTransform="uppercase">
      <FormattedMessage id="app.zone" />
      {` (${zoneNumber})`}
     </Typography>
    </Stack>
    {ispList.length > 0 ? (
     <Paper elevation={0} sx={{ borderRadius: "10px", boxShadow: 0 }}>
      <Stack justifyContent="space-between" alignItems="center" p={1} direction="row">
       <TextField
        size="small"
        autoComplete="off"
        inputRef={searchRef}
        label={<FormattedMessage id="search.zone" />}
        InputLabelProps={{ shrink: true }}
        InputProps={{ startAdornment: <SearchIcon color="disabled" /> }}
        onChange={({ currentTarget }) => setSearchValue(currentTarget.value)}
       />
      </Stack>
      <Stack>
       <Alert severity="info">
        <FormattedMessage id="zone.zoneMessage" />
       </Alert>
      </Stack>
      <Divider sx={{ borderColor: "#f86e06" }} />
      <Stack>
       {zoneList.length === 0 ? (
        <Typography variant="subtitle2" align="center">
         <FormattedMessage id="zone.notFound" />
        </Typography>
       ) : (
        <SortingTable tableData={zonesTable} maxWidth={650} caller="zones" listToShow={zoneList} />
       )}
      </Stack>
      <TablePagination
       component="div"
       count={zoneNumber}
       page={currentPage}
       rowsPerPage={rowPerPage}
       onPageChange={handleOnPageChange}
       onRowsPerPageChange={handleOnRowsPerPageChange}
      />
     </Paper>
    ) : (
     <NoServerFound type="nameserver" />
    )}
   </Stack>
  </Container>
 );
};

export default ZonesList;
