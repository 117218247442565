import React, { ReactElement, useEffect, useState } from "react";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { filterAddonsInfo, filterProvider } from "helpers/addons";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import useMediaQuery from "@mui/material/useMediaQuery";

import { addonsList } from "constants/addons";

import IconSelector from "components/shared/images/IconSelector";

import { postObtainAddonsRecap } from "redux/handlers/userHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { IAddonsInfo } from "types/api/addonsApiInterface";

import AddSnapshot from "./snapshots/actions/AddSnapshot";

const AddonsDashboard = (): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();
 const navigate = useNavigate();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));

 const [addonsInfo, setAddonsInfo] = useState<IAddonsInfo | null>(null);

 useEffect(() => {
  (async () => {
   setAddonsInfo(await dispatch(postObtainAddonsRecap()));
  })();
 }, []);

 const handleNavigate = (link: string) => {
  navigate(link);
 };

 const RenderManageSection = ({ section }: { section: string }) => {
  switch (section) {
   case "cloudbox":
    return (
     <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Typography variant="caption">
       <FormattedMessage id="addons.manageSection" />
      </Typography>
      <Typography variant="caption">3</Typography>
     </Stack>
    );
   case "network":
    return (
     <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Typography variant="caption">
       <FormattedMessage id="addons.manageSection" />
      </Typography>
      <Typography variant="caption">3</Typography>
     </Stack>
    );
   case "firewall":
    return (
     <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Typography variant="caption">
       <FormattedMessage id="addons.manageSection" />
      </Typography>
      <Typography variant="caption">3</Typography>
     </Stack>
    );
   case "loadBalancer":
    return (
     <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Typography variant="caption">
       <FormattedMessage id="addons.manageSection" />
      </Typography>
      <Typography variant="caption">5</Typography>
     </Stack>
    );
   default:
    return <></>;
  }
 };

 const RenderDisableElement = ({ section }: { section: string }): ReactElement => {
  switch (section) {
   case "cloudbox":
    return (
     <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Typography variant="caption">
       <FormattedMessage id="addons.disabledCloudbox" />
      </Typography>
      <Typography
       variant="caption"
       color={addonsInfo?.cloudbox.disabledElement === 0 ? "green" : "default"}>
       {addonsInfo?.cloudbox.disabledElement === 0 ? (
        <FormattedMessage id="addons.noCloudboxDisabled" />
       ) : (
        addonsInfo?.cloudbox.disabledElement
       )}
      </Typography>
     </Stack>
    );
   case "volumes":
    return (
     <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Typography variant="caption">
       <FormattedMessage id="addons.disabledVolume" />
      </Typography>
      <Typography
       variant="caption"
       color={addonsInfo?.volume.disabledElement === 0 ? "green" : "default"}>
       {addonsInfo?.volume.disabledElement === 0 ? (
        <FormattedMessage id="addons.noVolumeDisabled" />
       ) : (
        addonsInfo?.volume.disabledElement
       )}
      </Typography>
     </Stack>
    );
   default:
    return <></>;
  }
 };

 return (
  <Stack px={desktopViewPort ? 20 : 5} pt={10}>
   <Grid container spacing={2}>
    {addonsList.map((element, index) => {
     return (
      <Grid key={`addons-dash-${index}`} xs={desktopViewPort ? "auto" : 12}>
       <Card sx={{ minWidth: desktopViewPort ? 500 : "auto", minHeight: 300 }}>
        <CardHeader
         avatar={
          <IconSelector icon={element.icon} props={{ sx: { fontSize: 40 }, color: "kxOrange" }} />
         }
         action={
          <Stack direction="row" spacing={1}>
           {element.section === "snapshot" ? (
            <AddSnapshot fromDashboard />
           ) : (
            <Tooltip title={<FormattedMessage id={element.addTitle} />}>
             <IconButton aria-label="settings" onClick={() => handleNavigate(element.add)}>
              <IconSelector icon="AddIcon" props={{ color: "kxOrange" }} />
             </IconButton>
            </Tooltip>
           )}
           <Tooltip title={<FormattedMessage id="addons.goToSection" />}>
            <IconButton aria-label="settings" onClick={() => handleNavigate(element.link)}>
             <IconSelector icon="ArrowOutwardIcon" props={{ color: "kxOrange" }} />
            </IconButton>
           </Tooltip>
          </Stack>
         }
         title={
          <Typography textTransform="uppercase" fontWeight="bold">
           <FormattedMessage id={element.name} />
          </Typography>
         }
         subheader={`${
          addonsInfo ? filterAddonsInfo(addonsInfo, element.section)?.totalCount : "0"
         } ${intl.formatMessage({ id: element.internElement })}`}
         sx={{ textAlign: "left" }}
        />
        <Divider sx={{ borderColor: "#f86e06", mx: 1 }} />
        <Stack
         px={2}
         py={1}
         maxWidth={desktopViewPort ? 470 : "auto"}
         textAlign="left"
         sx={{ color: "rgba(0, 0, 0, 0.6)" }}>
         <Typography variant="caption">
          <FormattedMessage id={element.sectionMessage} />
         </Typography>
         <Divider />
         {addonsInfo && filterAddonsInfo(addonsInfo, element.section)?.totalCount === 0 ? (
          <Stack justifyContent="center" alignItems="center" mt={4}>
           <IconSelector icon="WarningIcon" props={{ color: "warning" }} />
           <Typography textAlign="center" variant="caption" fontStyle="italic">
            <FormattedMessage id="addons.elementNotFound" />
           </Typography>
          </Stack>
         ) : (
          <Stack pt={1} color="rgba(0, 0, 0, 0.6)">
           <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Stack direction="row" alignItems="flex-start">
             <IconSelector icon="WarningIcon" props={{ color: "error", sx: { fontSize: 15 } }} />
             <Typography variant="caption">
              <FormattedMessage id="addons.expired" />
             </Typography>
            </Stack>
            <Typography
             variant="caption"
             color={
              addonsInfo && filterAddonsInfo(addonsInfo, element.section)?.expired === 0
               ? "green"
               : "default"
             }>
             {addonsInfo ? (
              filterAddonsInfo(addonsInfo, element.section)?.expired === 0 ? (
               <FormattedMessage id="addons.noExpired" />
              ) : (
               filterAddonsInfo(addonsInfo, element.section)?.expired
              )
             ) : (
              "0"
             )}
            </Typography>
           </Stack>
           <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Stack direction="row" alignItems="flex-start">
             <IconSelector icon="WarningIcon" props={{ color: "warning", sx: { fontSize: 15 } }} />
             <Typography variant="caption">
              <FormattedMessage id="addons.expiring" />
             </Typography>
            </Stack>
            <Typography
             variant="caption"
             color={
              addonsInfo && filterAddonsInfo(addonsInfo, element.section)?.expiring === 0
               ? "green"
               : "default"
             }>
             {addonsInfo ? (
              filterAddonsInfo(addonsInfo, element.section)?.expiring === 0 ? (
               <FormattedMessage id="addons.noExpiring" />
              ) : (
               filterAddonsInfo(addonsInfo, element.section)?.expiring
              )
             ) : (
              "0"
             )}
            </Typography>
           </Stack>
           <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="caption">
             <FormattedMessage id="addons.nextExpire" />
            </Typography>
            <Typography variant="caption">
             {addonsInfo ? (
              <FormattedDate
               value={filterAddonsInfo(addonsInfo, element.section)?.nextExpire || ""}
              />
             ) : (
              "--"
             )}
            </Typography>
           </Stack>
           <RenderManageSection section={element.section} />
           <RenderDisableElement section={element.section} />
           {element.section === "firewall" && (
            <Stack direction="row" alignItems="center" justifyContent="space-between">
             <Typography variant="caption">
              <FormattedMessage id="addons.totalRules" />
             </Typography>
             <Typography variant="caption">{addonsInfo?.firewall.totalRules || "0"}</Typography>
            </Stack>
           )}
           <Divider />
           <Stack direction="row" alignItems="center" justifyContent="space-between" mt={1}>
            <Typography variant="caption">
             <FormattedMessage id="addons.providers" />
            </Typography>
            <Stack direction="row" spacing={1}>
             {addonsInfo
              ? filterAddonsInfo(addonsInfo, element.section)?.provider.map((element, index) => {
                 return (
                  <img
                   key={`provider-key-${index}`}
                   width={15}
                   height={15}
                   src={filterProvider(element)}
                   alt="provider"
                  />
                 );
                })
              : "--"}
            </Stack>
           </Stack>
          </Stack>
         )}
        </Stack>
       </Card>
      </Grid>
     );
    })}
   </Grid>
  </Stack>
 );
};

export default AddonsDashboard;
