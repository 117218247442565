import React, { ReactElement, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import { isNil } from "ramda";

import Autocomplete from "@mui/material/Autocomplete";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import AppModal from "components/shared/modal/AppModal";

import { postLinkServerToCloudbucket } from "redux/handlers/addonsHandle";

import { useAppDispatch } from "hooks/reduxHook";

import { IAllServers } from "types/api/serversApiInterface";

const LinkServer = ({
 servers,
 id,
 closeMenu
}: {
 servers: Array<IAllServers>;
 id: number;
 closeMenu: () => void;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => {
  reset();
  setOpen(true);
 };
 const handleClose = () => setOpen(false);

 const { control, reset, watch, handleSubmit, setValue, formState } = useForm({
  defaultValues: {
   server: 0
  }
 });

 const onSubmit: SubmitHandler<{
  server: number;
 }> = async (data: { server: number }) => {
  setLoading(true);
  await dispatch(postLinkServerToCloudbucket(data.server, id));
  setLoading(false);
  handleClose();
  closeMenu();
 };

 const checkDisabled = (): boolean => {
  return watch("server") === 0;
 };

 return (
  <>
   <MenuItem onClick={() => handleOpen()}>
    <FormattedMessage id="addons.cloudbucket.linkServer" />
   </MenuItem>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "addons.cloudbucket.linkServer" })}
    handleClose={handleClose}
    handleConfirm={handleSubmit(onSubmit)}
    disabled={loading || checkDisabled()}>
    <Stack spacing={2}>
     <Controller
      name="server"
      control={control}
      rules={{ required: true }}
      render={({ field }) => (
       <Autocomplete
        fullWidth={true}
        autoHighlight
        sx={{ my: 2 }}
        onChange={(e, value) =>
         isNil(value) ? setValue("server", 0) : setValue("server", value.server_id)
        }
        options={servers.map((element) => {
         return {
          server_id: element.id,
          label: element.server_name
         };
        })}
        renderInput={(params) => (
         <TextField
          {...params}
          {...field}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
           e.stopPropagation();
          }}
          label={<FormattedMessage id="volumes.selectServer" />}
          error={formState.isDirty && !!formState?.errors?.server}
          InputLabelProps={{ shrink: true }}
         />
        )}
       />
      )}
     />
    </Stack>
   </AppModal>
  </>
 );
};

export default LinkServer;
