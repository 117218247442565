import React, { ReactElement } from "react";
import { useLocation, useParams } from "react-router-dom";

import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Unstable_Grid2";

import FloatingIpPage from "pages/addons/FloatingIpPage";

import SectionsBreadCrumbs from "components/shared/breadCrumbs/SectionsBreadCrumbs";

import CloudboxPage from "./CloudboxPage";
import CloudbucketPage from "./CloudbucketPage";
import FirewallPage from "./FirewallPage";
import LoadBalancerPage from "./LoadBalancerPage";
import NetworkPage from "./NetworkPage";
import SnapshotPage from "./SnapshotPage";
import VolumePage from "./VolumePage";

const AddonsPage = (): ReactElement => {
 const location = useLocation();
 const params = useParams<{ section: string }>();

 const RenderAddon = (): ReactElement => {
  switch (params.section) {
   case "floatingip":
    return <FloatingIpPage />;
   case "volumes":
    return <VolumePage />;
   case "snapshots":
    return <SnapshotPage />;
   case "firewall":
    return <FirewallPage />;
   case "network":
    return <NetworkPage />;
   case "loadBalancer":
    return <LoadBalancerPage />;
   case "cloudbucket":
    return <CloudbucketPage />;
   default:
    return <CloudboxPage />;
  }
 };

 return (
  <Container maxWidth="xl">
   <Stack mt={2}>
    <SectionsBreadCrumbs
     links={[
      { name: "home", url: "/" },
      { name: "addons", url: "/addons" },
      { name: params?.section || "", url: location.pathname }
     ]}
    />
   </Stack>
   <Grid container>
    <Grid xs={12}>
     <RenderAddon />
    </Grid>
   </Grid>
  </Container>
 );
};

export default AddonsPage;
