import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import AddIcon from "@mui/icons-material/Add";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { getLoadBalancerDetails } from "redux/selectors/loadBalancerSelector";

import { IUserNetwork } from "types/api/addonsApiInterface";

import AttachNetwork from "./actions/AttachNetwork";
import DetachNetwork from "./actions/DetachNetwork";

const NetworkingPrivateNetwork = ({
 networkList
}: {
 networkList: Array<IUserNetwork>;
}): ReactElement => {
 const navigate = useNavigate();

 const balancerData = useSelector(getLoadBalancerDetails);

 return balancerData?.private_net.length === 1 ? (
  <Stack p={2} spacing={2}>
   <Alert severity="info">
    <FormattedMessage id="addons.loadBalancer.privateNetworkConnected" />
   </Alert>
   <Stack
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    sx={{ mt: 2, p: 1, border: "2px solid #ededed", borderRadius: 1 }}>
    <Stack textAlign="left">
     <Typography variant="extraSmall" textTransform="uppercase" color="#f86e06">
      <FormattedMessage id="addons.network" />
     </Typography>
     <Typography fontWeight="bold">
      {networkList.find(
       (element) =>
        element.network_id ===
        balancerData?.private_net.find((nets) => nets.network === element.network_id)?.network
      )?.name || "Name not available"}
     </Typography>
    </Stack>
    <Stack textAlign="left">
     <Typography variant="extraSmall" textTransform="uppercase" color="#f86e06">
      <FormattedMessage id="addons.loadBalancer.private_ip" />
     </Typography>
     {balancerData?.private_net &&
      balancerData.private_net.map((element, index) => {
       return <Typography key={`private-ip-${index}`}>{element.ip}</Typography>;
      })}
    </Stack>
    <DetachNetwork id={balancerData?.id || 0} />
   </Stack>
  </Stack>
 ) : (
  <Stack p={2} spacing={1}>
   <Alert severity="info">
    <FormattedMessage id="addons.loadBalancer.noPrivateNetwork" />
   </Alert>
   <Stack direction="row" spacing={1}>
    <Button
     variant="kxActionButton"
     endIcon={<AddIcon />}
     onClick={() => navigate("/addons/network/add")}>
     <FormattedMessage id="addons.network.addNetwork" />
    </Button>
    <AttachNetwork id={balancerData?.id || 0} networkList={networkList} />
   </Stack>
  </Stack>
 );
};

export default NetworkingPrivateNetwork;
