import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";

import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";

import { IServerTypes } from "types/api/serversApiInterface";

const ServerSizeBox = ({
 selectedSize,
 sizesList,
 selectSize
}: {
 selectedSize: number;
 sizesList: Array<IServerTypes>;
 selectSize: (size: number) => void;
}): ReactElement => {
 return (
  <Stack>
   {sizesList.map((element, index) => {
    return selectedSize === element.id ? (
     <Paper
      sx={{
       backgroundColor: "#c8e6c9",
       py: 2,
       m: 1
      }}
      onClick={() => selectSize(element.id)}>
      <Grid container>
       <Grid xs={3}>
        <Typography>{element.name}</Typography>
       </Grid>
       <Grid xs={3}>
        <Typography>{`${element.cores} cores`}</Typography>
       </Grid>
       <Grid xs={3}>
        <Typography>{`${element.memory} GB RAM`}</Typography>
       </Grid>
       <Grid xs={3}>
        {" "}
        <Typography>{`${element.disk} GB ${element.disk_type.toUpperCase()}`}</Typography>
       </Grid>
      </Grid>
     </Paper>
    ) : (
     <Tooltip
      key={`type-paper-${index}`}
      title={<FormattedMessage id="server.add.selectSizeTitle" />}>
      <Paper
       sx={{
        cursor: "pointer",
        py: 2,
        m: 1
       }}
       onClick={() => selectSize(element.id)}>
       <Grid container>
        <Grid xs={3}>
         <Typography>{element.name}</Typography>
        </Grid>
        <Grid xs={3}>
         <Typography>{`${element.cores} cores`}</Typography>
        </Grid>
        <Grid xs={3}>
         <Typography>{`${element.memory} GB RAM`}</Typography>
        </Grid>
        <Grid xs={3}>
         {" "}
         <Typography>{`${element.disk} GB ${element.disk_type.toUpperCase()}`}</Typography>
        </Grid>
       </Grid>
      </Paper>
     </Tooltip>
    );
   })}
  </Stack>
 );
};

export default ServerSizeBox;
