import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";

import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { IRegisterData } from "types/api/domainsApiInterface";

const DomainProviderBox = ({
 providers,
 selectProvider,
 selectedProvider
}: {
 providers: Array<IRegisterData>;
 selectProvider: (name: string) => void;
 selectedProvider: string;
}): ReactElement => {
 const searchProvider = (prov: string): string => {
  switch (prov) {
   case "internetbs":
    return `${process.env.PUBLIC_URL}/svg/providers/ibs.svg`;
   case "ascio":
    return `${process.env.PUBLIC_URL}/svg/providers/ascio.svg`;
   default:
    return "";
  }
 };

 return (
  <Stack direction="row" spacing={2}>
   {providers.map((element, index) => {
    return selectedProvider === element.name ? (
     <Paper
      sx={{
       backgroundColor: "#c8e6c9",
       px: 4,
       py: 2
      }}
      onClick={() => selectProvider(element.name)}>
      <img src={searchProvider(element.name)} alt="provider-logo" height={40} />
      <Stack direction="row" justifyContent="center" alignItems="center" mt={1}>
       <Typography fontWeight="bold" textTransform="uppercase">
        {element.name}
       </Typography>
      </Stack>
     </Paper>
    ) : (
     <Tooltip
      key={`provider-paper-${index}`}
      title={<FormattedMessage id="server.add.selectProviderTitle" />}>
      <Paper
       sx={{
        px: 4,
        py: 2,
        cursor: "pointer"
       }}
       onClick={() => selectProvider(element.name)}>
       <img src={searchProvider(element.name)} alt="provider-logo" height={40} />
       <Stack direction="row" justifyContent="center" alignItems="center" mt={1}>
        <Typography fontWeight="bold" textTransform="uppercase">
         {element.name}
        </Typography>
       </Stack>
      </Paper>
     </Tooltip>
    );
   })}
  </Stack>
 );
};

export default DomainProviderBox;
