import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { isNil } from "ramda";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import useMediaQuery from "@mui/material/useMediaQuery";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";
import RebuildBox from "components/shared/rebuildBox/RebuildBox";

import { getRebuildOs, getServerProvider, postRebuildServer } from "redux/handlers/serverHandler";

import { getServerDetails } from "redux/selectors/serversSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { IOsDetails } from "types/api/serversApiInterface";

const ServerRebuild = (): ReactElement => {
 const intl = useIntl();
 const theme = useTheme();
 const dispatch = useAppDispatch();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));

 const { id } = useSelector(getServerDetails);
 const { locked } = useSelector(getServerDetails);
 const { provider } = useSelector(getServerDetails);

 const [osList, setOsList] = useState<Array<IOsDetails>>([]);
 const [osSelected, setOsSelected] = useState<number>(0);
 const [openModal, setOpenModal] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postRebuildServer(osSelected, id || 0));
  setLoading(false);
  setOpenModal(false);
 };

 useEffect(() => {
  (async () => {
   const providers = (await dispatch(getServerProvider())).find(
    (element) => element.name === provider
   );
   const data = await dispatch(getRebuildOs(providers?.id || 1));
   setOsList(isNil(data) ? [] : data);
  })();
 }, []);

 return (
  <>
   <Paper elevation={0} sx={{ mt: 2, borderRadius: "10px", boxShadow: 0 }}>
    <Stack p={2} spacing={2}>
     <Stack direction="row" spacing={1}>
      <IconSelector icon="BuildIcon" props={{ fontSize: "medium", color: "secondary" }} />
      <Typography fontWeight="bold" fontSize={16} textTransform="uppercase">
       <FormattedMessage id="server.rebuild" />
      </Typography>
     </Stack>
     <Alert severity="warning" variant="outlined">
      <FormattedMessage
       id={locked ? "server.rebuild.rebuildNotAvailable" : "server.rebuild.warningMessage"}
      />
     </Alert>
     {!locked && (
      <Stack spacing={2}>
       <Typography textAlign="left" sx={{ pl: 1 }} fontWeight="bold">
        <FormattedMessage id="server.rebuild.selectOsForRebuild" />
       </Typography>
       <Grid container spacing={2}>
        {osList.map((element, index) => {
         return (
          <Grid xs={desktopViewPort ? "auto" : 12} key={`os-rebuild-${index}`}>
           <RebuildBox
            os={element}
            selectOs={(os) => setOsSelected(os.id)}
            selected={osSelected === element.id}
           />
          </Grid>
         );
        })}
       </Grid>
       <Button variant="kxActionButton" disabled={osSelected === 0} onClick={handleOpen}>
        <FormattedMessage id="server.rebuild.confirmRebuild" />
       </Button>
       <AppModal
        open={openModal}
        close={handleClose}
        title={intl.formatMessage({ id: "server.rebuild.modalTitle" })}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        disabled={loading || osSelected === 0 || locked}>
        <Stack>
         {locked ? (
          <Alert severity="error">
           <FormattedMessage id="server.rebuild.protectionActiveError" />
          </Alert>
         ) : (
          <Typography>
           <FormattedMessage id="server.rebuild.modalMessage" />
          </Typography>
         )}
        </Stack>
       </AppModal>
      </Stack>
     )}
    </Stack>
   </Paper>
  </>
 );
};

export default ServerRebuild;
