import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";

import { getServerDetails } from "redux/selectors/serversSelector";

import ResetLitespeedPassword from "../../actions/ResetLitespeedPassword";

const LitespeedBox = ({
 id,
 password,
 port
}: {
 id: number;
 password: string | null;
 port: number;
}): ReactElement => {
 const server = useSelector(getServerDetails);

 return (
  <Stack spacing={2}>
   <img
    width={300}
    height={75}
    src={`${process.env.PUBLIC_URL}/svg/providers/litespeed_full_logo.svg`}
    alt="litespeed"
   />
   <Stack>
    <Typography>
     <FormattedMessage id="docker.website.databaseUser" />
     {": admin"}
    </Typography>
    <Typography>
     <FormattedMessage id="docker.website.databasePassword" />
     {!password ? ": Not available" : `: ${password}`}
    </Typography>
   </Stack>
   <Stack>
    <Typography fontWeight="bold">
     <FormattedMessage id="docker.website.litespeedActions" />
     {":"}
    </Typography>
    <Stack direction="row" spacing={1}>
     <ResetLitespeedPassword id={id} />
     <Tooltip title={<FormattedMessage id="docker.website.openLitespeed" />}>
      <IconButton
       onClick={() =>
        window.open(`http://${server.ipv4}:${port}`, "Litespeed server", "width=1000, height=800")
       }>
       <IconSelector icon="OpenInNewIcon" />
      </IconButton>
     </Tooltip>
    </Stack>
   </Stack>
  </Stack>
 );
};

export default LitespeedBox;
