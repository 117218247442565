import React, { ReactElement, useContext, useEffect, useState } from "react";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { formatSizePercent } from "helpers/app";

import { isNil } from "ramda";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import useMediaQuery from "@mui/material/useMediaQuery";

import IconSelector from "components/shared/images/IconSelector";

import { editSiteDetails, updateSiteStatus } from "redux/handlers/websitesHandler";

import { getWebsitesDetails } from "redux/selectors/websitesSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { AppContext } from "../../../AppContext";
import UpdateCertificate from "../actions/UpdateCertificate";

const WebsitesMonitoring = (): ReactElement => {
 const { socket } = useContext(AppContext);
 const dispatch = useAppDispatch();
 const intl = useIntl();
 const theme = useTheme();
 const navigate = useNavigate();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));
 const websitesDetails = useSelector(getWebsitesDetails);

 const [trafficLogs, setTrafficLogs] = useState<number>(0);
 const [logSize, setLogSize] = useState<number>(0);
 const [size, setSize] = useState<number>(0);

 useEffect(() => {
  !isNil(socket) &&
   socket.on("site_logs", async (value): Promise<void> => {
    for (let element of value) {
     if (element.site === websitesDetails.site) {
      setTrafficLogs(parseFloat(element.website_traffic));
      setSize(parseSizing(parseInt(element.website_size), element.website_size));
      setLogSize(parseInt(element.website_log_size));
     }
    }
   });
  return () => {
   !isNil(socket) && socket.off("site_logs");
  };
 }, [socket, websitesDetails]);

 const parseSizing = (value: number, type: string): number => {
  if (type && type.includes("M")) {
   return value;
  } else if (type && type.includes("G")) {
   return value * 1024;
  } else {
   return value / 1024;
  }
 };

 const CircularMonitor = ({ value, label }: { value: number; label: string }): ReactElement => {
  const circularColor = (): string => {
   if (value <= 50) {
    return "green";
   } else if (value > 50 && value <= 80) {
    return "orange";
   } else {
    return "red";
   }
  };

  return (
   <Box
    display="flex"
    minWidth={80}
    justifyContent="center"
    alignItems="center"
    sx={{ position: "relative", textAlign: "center", height: 80 }}>
    <CircularProgress
     value={100}
     size="5rem"
     sx={{ color: "#dcdada", position: "absolute" }}
     variant="determinate"
    />
    <CircularProgress
     variant="determinate"
     size="5rem"
     sx={{
      color: circularColor(),
      animationDuration: "550ms",
      position: "absolute"
     }}
     value={value}
    />
    <Stack position="absolute" alignItems="center">
     <IconSelector icon="DiscIcon" props={{ fontSize: "small" }} />
     <Typography variant="extraSmall" fontWeight="bold" textTransform="uppercase" fontSize={10}>
      {label}:{value}%
     </Typography>
    </Stack>
   </Box>
  );
 };

 const handleSwitchHttpOrLts = async (action: string, ispId: number, domain_id: number) => {
  await dispatch(editSiteDetails(action, ispId, domain_id));
 };

 const handleUpdateSiteStatus = async (domainId: number, ispId: number) => {
  await dispatch(updateSiteStatus(domainId, ispId));
  navigate("/app/sites");
 };

 return (
  <>
   <Stack>
    <Paper elevation={0} sx={{ pt: 1, borderRadius: "10px", boxShadow: 0 }}>
     <Grid container>
      <Grid xs={6}>
       <Stack mt={2} spacing={1} pl={1}>
        <Typography textAlign="start" fontWeight="bold" textTransform="uppercase" sx={{ pl: 1 }}>
         <FormattedMessage id="app.actions" />
        </Typography>
        <Stack direction="row">
         <UpdateCertificate />
         <Tooltip
          title={
           <FormattedMessage
            id={
             websitesDetails.ssl === "y" && websitesDetails.ssl_letsencrypt === "y"
              ? "sites.deactivateSsl"
              : "sites.activateSsl"
            }
           />
          }>
          <IconButton
           onClick={() =>
            handleSwitchHttpOrLts(
             "encrypt",
             websitesDetails.isp_id || 0,
             websitesDetails.domain_id || 0
            )
           }>
           <IconSelector
            icon="LetsEncryptIcon"
            props={{
             color:
              websitesDetails.ssl === "y" && websitesDetails.ssl_letsencrypt === "y"
               ? "success"
               : "error"
            }}
           />
          </IconButton>
         </Tooltip>
         <Tooltip
          title={
           <FormattedMessage
            id={
             websitesDetails.rewrite_to_https === "y"
              ? "sites.deactivateRedirect"
              : "sites.activateRedirect"
            }
           />
          }>
          <IconButton
           onClick={() =>
            handleSwitchHttpOrLts(
             "httpstoggle",
             websitesDetails.isp_id || 0,
             websitesDetails.domain_id || 0
            )
           }>
           {websitesDetails.rewrite_to_https === "y" ? (
            <IconSelector icon="LockedIcon" props={{ color: "success" }} />
           ) : (
            <IconSelector icon="UnlockedIcon" props={{ color: "error" }} />
           )}
          </IconButton>
         </Tooltip>
         <IconButton
          onClick={() =>
           handleUpdateSiteStatus(websitesDetails.domain_id || 0, websitesDetails.isp_id || 0)
          }>
          <IconSelector icon="PowerIcon" props={{ color: "primary" }} />
         </IconButton>
        </Stack>
        <Stack direction="row" pl={1} pb={2}>
         <Typography
          variant="caption"
          alignItems="flex-end"
          display="flex"
          textTransform="uppercase"
          fontWeight="bold"
          mr={1}>
          <FormattedMessage id="server.manage.outgoingTraffic" />
         </Typography>
         <Typography fontSize="small" lineHeight={1.43}>
          <FormattedNumber value={trafficLogs} unit="megabyte" />
         </Typography>
         <Typography
          variant="caption"
          alignItems="flex-end"
          display="flex"
          textTransform="uppercase">
          <FormattedMessage id="server.manage.mb" />
         </Typography>
        </Stack>
       </Stack>
      </Grid>
      <Grid xs={6}>
       <Stack direction="row" spacing={2} justifyContent="flex-end" pr={2} height="100%">
        <Stack direction="row" alignItems="center" justifyContent="center">
         {websitesDetails.hd_quota !== -1 ? (
          <Stack>
           <CircularMonitor
            value={formatSizePercent(size, websitesDetails.hd_quota)}
            label={intl.formatMessage({ id: "sites.ssd" })}
           />
           {desktopViewPort && (
            <Stack justifyContent="center" alignItems="center">
             <Typography variant="extraSmall" textTransform="uppercase">
              <FormattedMessage id="server.disk" />
              <FormattedMessage id="server.gigabytes.short" />
             </Typography>
            </Stack>
           )}
          </Stack>
         ) : (
          <Typography
           sx={{ ml: 2 }}
           variant="extraSmall"
           fontWeight="bold"
           textTransform="uppercase"
           fontSize={13}>
           <FormattedMessage id="sites.ssd" />:{` ${size} KB`}
          </Typography>
         )}
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="center">
         {websitesDetails.traffic_quota !== -1 ? (
          <Stack>
           <CircularMonitor
            value={formatSizePercent(logSize, websitesDetails.traffic_quota * 1024)}
            label={intl.formatMessage({ id: "sites.logs" })}
           />
           {desktopViewPort && (
            <Stack justifyContent="center" alignItems="center">
             <Typography variant="extraSmall" textTransform="uppercase">
              <FormattedMessage id="sites.logs" />
             </Typography>
            </Stack>
           )}
          </Stack>
         ) : (
          <Typography
           sx={{ ml: 2 }}
           variant="extraSmall"
           fontWeight="bold"
           textTransform="uppercase"
           fontSize={13}>
           <FormattedMessage id="sites.logs" />:{` ${logSize} KB`}
          </Typography>
         )}
        </Stack>
       </Stack>
      </Grid>
     </Grid>
    </Paper>
   </Stack>
  </>
 );
};

export default WebsitesMonitoring;
