/* eslint-disable react/style-prop-object */
import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { isNil } from "ramda";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import useMediaQuery from "@mui/material/useMediaQuery";

import AppModal from "components/shared/modal/AppModal";
import UpgradeBox from "components/shared/upgradeBox/UpgradeBox";

import { postServerContaboUpgrades, postUpgradeCTBServer } from "redux/handlers/serverHandler";

import { getServerDetails } from "redux/selectors/serversSelector";
import { getUserBalance } from "redux/selectors/userSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { UpgradeTypesAPI } from "types/api/serversApiInterface";

const ContaboUpgrade = (): ReactElement => {
 const dispatch = useAppDispatch();
 const navigate = useNavigate();
 const intl = useIntl();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));

 const userBalance = useSelector(getUserBalance);
 const server = useSelector(getServerDetails);

 const [possibleUpgrades, setPossibleUpgrades] = useState<Array<UpgradeTypesAPI>>([]);
 const [selectedUpgrade, setSelectedUpgrade] = useState<UpgradeTypesAPI>();
 const [tabValue, setTabValue] = useState<number>(0);
 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleChange = (event: React.SyntheticEvent, newValue: number) => {
  setTabValue(newValue);
 };

 const handleOpen = () => setOpen(true);
 const handleClose = () => setOpen(false);

 useEffect(() => {
  handleGetUpgrades();
 }, [server]);

 const handleGetUpgrades = async () => {
  if (server.id) setPossibleUpgrades(await dispatch(postServerContaboUpgrades(server.id || 0)));
 };

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(
   postUpgradeCTBServer(
    server?.id || 0,
    selectedUpgrade?.id || 0,
    (selectedUpgrade?.price || 0) - (server.price || 0)
   )
  );
  setLoading(false);
  handleClose();
  navigate(`/servers/manage/${server?.id}/specification`);
 };

 return (
  <>
   <Stack direction={"column"} spacing={2} mb={2}>
    <Typography paragraph align="left" variant="subtitle2">
     <FormattedMessage id="server.upgrade.introduction" values={{ br: <br /> }} />
    </Typography>
    <Alert severity="warning">
     <Typography variant="subtitle2" textAlign="left">
      <FormattedMessage id="server.upgrade.contaboWarning" />
     </Typography>
    </Alert>
   </Stack>
   {possibleUpgrades.length > 0 ? (
    <Grid container spacing={4}>
     <Grid xs={desktopViewPort ? 8 : 12}>
      <Stack>
       <Box sx={{ width: "100%", typography: "body1" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
         <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Standard" />
          <Tab label="Dedicated" />
          <Tab label="Storage" />
         </Tabs>
        </Box>
        {tabValue === 0 ? (
         <Stack spacing={1}>
          {possibleUpgrades
           .filter((element) => element.name.includes("FP"))
           .map((element, index) => {
            return (
             <UpgradeBox
              key={`upgrade-element-${index}`}
              showTag={index === 0}
              upgradeElement={element}
              showTag2={
               index ===
               possibleUpgrades.filter((element) => element.name.includes("FP")).length - 1
              }
              selected={element.id === selectedUpgrade?.id}
              serverPrice={server.price || 0}
              selectUpgrade={(value) => setSelectedUpgrade(value)}
             />
            );
           })}
         </Stack>
        ) : tabValue === 1 ? (
         <Stack spacing={1}>
          {possibleUpgrades
           .filter((element) => element.name.includes("FD"))
           .map((element, index) => {
            return (
             <UpgradeBox
              key={`upgrade-element-${index}`}
              showTag={index === 0}
              upgradeElement={element}
              showTag2={
               index ===
               possibleUpgrades.filter((element) => element.name.includes("FD")).length - 1
              }
              selected={element.id === selectedUpgrade?.id}
              serverPrice={server.price || 0}
              selectUpgrade={(value) => setSelectedUpgrade(value)}
             />
            );
           })}
         </Stack>
        ) : (
         <Stack spacing={1}>
          {possibleUpgrades
           .filter((element) => element.name.includes("FS"))
           .map((element, index) => {
            return (
             <UpgradeBox
              key={`upgrade-element-${index}`}
              showTag={index === 0}
              upgradeElement={element}
              showTag2={
               index ===
               possibleUpgrades.filter((element) => element.name.includes("FS")).length - 1
              }
              selected={element.id === selectedUpgrade?.id}
              serverPrice={server.price || 0}
              selectUpgrade={(value) => setSelectedUpgrade(value)}
             />
            );
           })}
         </Stack>
        )}
       </Box>
      </Stack>
     </Grid>
     <Grid xs={desktopViewPort ? 4 : 12}>
      <Stack spacing={2}>
       <Stack>
        <Typography fontWeight="bold" fontSize={16} ml={1} textTransform="uppercase">
         <FormattedMessage id="server.upgrade.machineDetails" />
        </Typography>
        <Stack direction={"row"}>
         <Typography fontWeight="bold">
          <FormattedMessage id="server.upgrade.machineCores" />:
         </Typography>
         <Typography ml={1}>{server.cpuCore}</Typography>
        </Stack>
        <Stack direction={"row"}>
         <Typography fontWeight="bold">
          <FormattedMessage id="server.upgrade.machineRam" />:
         </Typography>
         <Typography ml={1}>{server.ramSize}</Typography>
         <Typography
          variant="caption"
          alignItems="flex-end"
          display="flex"
          textTransform="uppercase">
          GB
         </Typography>
        </Stack>
        <Stack direction={"row"}>
         <Typography fontWeight="bold">
          <FormattedMessage id="server.upgrade.machineDisk" />:
         </Typography>
         <Typography ml={1}>{server.diskSize}</Typography>
         <Typography
          variant="caption"
          alignItems="flex-end"
          display="flex"
          textTransform="uppercase">
          GB
         </Typography>
        </Stack>
        <Stack direction={"row"}>
         <Typography fontWeight="bold">
          <FormattedMessage id="server.upgrade.machineType" />:
         </Typography>
         <Typography textTransform="uppercase" ml={1}>
          {server.server_type}
         </Typography>
        </Stack>
        <Stack direction={"row"}>
         <Typography fontWeight="bold">
          <FormattedMessage id="server.upgrade.machinePrice" />:
         </Typography>
         <Typography ml={1}>
          <FormattedNumber
           value={isNil(server.price) ? 0 : server.price}
           style="currency"
           currency="EUR"
          />
         </Typography>
        </Stack>
       </Stack>
       {!isNil(selectedUpgrade) && (
        <Stack>
         <Typography fontWeight="bold" fontSize={16} ml={1} textTransform="uppercase">
          <FormattedMessage id="server.upgrade.upgradeDetails" />
         </Typography>
         <Stack direction={"row"}>
          <Typography fontWeight="bold">
           <FormattedMessage id="server.upgrade.machineCores" />:
          </Typography>
          <Typography ml={1}>{selectedUpgrade?.cores}</Typography>
         </Stack>
         <Stack direction={"row"}>
          <Typography fontWeight="bold">
           <FormattedMessage id="server.upgrade.machineRam" />:
          </Typography>
          <Typography ml={1}>{selectedUpgrade?.memory}</Typography>
          <Typography
           variant="caption"
           alignItems="flex-end"
           display="flex"
           textTransform="uppercase">
           GB
          </Typography>
         </Stack>
         <Stack direction={"row"}>
          <Typography fontWeight="bold">
           <FormattedMessage id="server.upgrade.machineDisk" />:
          </Typography>
          <Typography ml={1}>{selectedUpgrade?.disk}</Typography>
          <Typography
           variant="caption"
           alignItems="flex-end"
           display="flex"
           textTransform="uppercase">
           GB
          </Typography>
         </Stack>
         <Stack direction={"row"}>
          <Typography fontWeight="bold">
           <FormattedMessage id="server.upgrade.machineType" />:
          </Typography>
          <Typography ml={1}>{selectedUpgrade?.name}</Typography>
         </Stack>
         <Stack direction={"row"}>
          <Typography fontWeight="bold">
           <FormattedMessage id="server.upgrade.machinePrice" />:
          </Typography>
          <Typography ml={1}>
           <FormattedNumber value={selectedUpgrade?.price} style="currency" currency="EUR" />
          </Typography>
         </Stack>
        </Stack>
       )}
       {!isNil(selectedUpgrade) && (
        <Button variant="kxActionButton" onClick={handleOpen}>
         <FormattedMessage id="server.upgrade.confirmUpgrade" />
        </Button>
       )}
       {!isNil(selectedUpgrade) && (
        <AppModal
         open={open}
         close={handleClose}
         title={intl.formatMessage({ id: "server.upgrade.upgradeModal" })}
         handleClose={handleClose}
         disabled={userBalance < selectedUpgrade.price - (server.price || 0) || loading}
         handleConfirm={handleConfirm}>
         <Stack>
          <Typography>
           <FormattedMessage id="server.upgrade.confirmMessage" />
           <FormattedNumber value={selectedUpgrade?.price} style="currency" currency="EUR" />
           <FormattedMessage id="server.upgrade.confirmMessage2" />
          </Typography>
          <Stack direction="row" spacing={2}>
           <Typography fontWeight="bold">
            <FormattedMessage id="server.upgrade.operationPrice" />
           </Typography>
           <Typography fontStyle="italic" fontWeight="bold" color={"green"}>
            <FormattedNumber
             value={selectedUpgrade?.price - (server.price || 0)}
             style="currency"
             currency="EUR"
            />
           </Typography>
          </Stack>
          <Typography variant="caption" fontStyle="italic">
           <FormattedMessage id="server.upgrade.operationPriceMessage" />
          </Typography>
         </Stack>
        </AppModal>
       )}
      </Stack>
     </Grid>
    </Grid>
   ) : (
    <Stack>
     <Skeleton variant="rectangular" width={"100%"} height={60} animation="wave" />
    </Stack>
   )}
  </>
 );
};

export default ContaboUpgrade;
