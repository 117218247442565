import React, { ReactElement, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { IconButton } from "@mui/material";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { editSiteDirectives } from "redux/handlers/websitesHandler";

import { getWebsitesDetails } from "redux/selectors/websitesSelector";

import { useAppDispatch } from "hooks/reduxHook";

const EditDirectives = ({ section }: { section: string }): ReactElement => {
 const websiteDetails = useSelector(getWebsitesDetails);
 const params = useParams<{ domain_id: string; section: string }>();
 const dispatch = useAppDispatch();
 const intl = useIntl();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [isLoading, setIsLoading] = useState<boolean>(false);

 const ispId = websiteDetails.isp_id;
 const domainId = websiteDetails.domain_id;

 const handleOpen = () => {
  reset();
  setOpenModal(true);
 };
 const handleClose = () => setOpenModal(false);

 const onSubmit: SubmitHandler<{ directives: string }> = async (data: { directives: string }) => {
  setIsLoading(true);
  switch (section) {
   case "php":
    dispatch(
     editSiteDirectives(
      "custom_php_ini",
      ispId,
      domainId,
      data.directives,
      Number(params.domain_id)
     )
    );
    break;
   case "apache2":
    dispatch(
     editSiteDirectives(
      "apache_directives",
      ispId,
      domainId,
      data.directives,
      Number(params.domain_id)
     )
    );
    break;
   case "nginx":
    dispatch(
     editSiteDirectives(
      "nginx_directives",
      ispId,
      domainId,
      data.directives,
      Number(params.domain_id)
     )
    );
    break;
   default:
    dispatch(
     editSiteDirectives(
      "custom_php_ini",
      ispId,
      domainId,
      data.directives,
      Number(params.domain_id)
     )
    );
    break;
  }
  setIsLoading(false);
  handleClose();
 };

 const getDirectives = (): string => {
  switch (section) {
   case "php":
    return websiteDetails.custom_php_ini;
   case "apache2":
    return websiteDetails.apache_directives;
   case "nginx":
    return websiteDetails.nginx_directives;
   default:
    return "";
  }
 };

 const { control, formState, watch, reset, handleSubmit } = useForm({
  defaultValues: {
   directives: getDirectives()
  }
 });

 const checkDisabled = (): boolean => {
  switch (section) {
   case "php":
    return watch("directives") === websiteDetails.custom_php_ini;
   case "apache2":
    return watch("directives") === websiteDetails.apache_directives;
   case "nginx":
    return watch("directives") === websiteDetails.nginx_directives;
   default:
    return true;
  }
 };

 const RenderMessage = (): ReactElement => {
  switch (section) {
   case "php":
    return <FormattedMessage id={"sites.phpDirectives"} />;
   case "apache2":
    return <FormattedMessage id={"sites.apacheDirectives"} />;
   case "nginx":
    return <FormattedMessage id={"sites.nginxDirectives"} />;
   default:
    return <FormattedMessage id={"sites.phpDirectives"} />;
  }
 };

 const RenderModalMessage = (): ReactElement => {
  switch (section) {
   case "php":
    return (
     <Alert severity="info">
      <FormattedMessage id="sites.editPhpDirectivesMessage" />
     </Alert>
    );
   default:
    return <></>;
  }
 };

 return (
  <Stack
   direction="row"
   alignItems="center"
   justifyContent="space-between"
   spacing={2}
   py={0.5}
   pr={2}
   textAlign="start">
   <Typography fontWeight="bold" textAlign="start" variant="subtitle1" px={2} width={140}>
    <RenderMessage />
   </Typography>
   {desktopViewPort ? (
    <Button variant="kxActionButton" onClick={handleOpen}>
     <FormattedMessage id="sites.updateDirectives" />
    </Button>
   ) : (
    <IconButton onClick={handleOpen} color="kxBlue">
     <IconSelector icon="EditIcon" />
    </IconButton>
   )}
   <AppModal
    open={openModal}
    close={handleClose}
    loading={isLoading}
    title={intl.formatMessage({ id: "sites.updateDirectives" })}
    handleClose={handleClose}
    handleConfirm={handleSubmit(onSubmit)}
    disabled={checkDisabled()}>
    <Stack justifyContent="center" alignContent="center" direction="column" spacing={2}>
     <RenderModalMessage />
     <Controller
      name={"directives"}
      control={control}
      render={({ field }) => (
       <TextField
        {...field}
        autoFocus
        label={<RenderMessage />}
        error={formState.isDirty && !!formState?.errors?.directives}
        autoComplete="off"
        multiline
       />
      )}
     />
    </Stack>
   </AppModal>
  </Stack>
 );
};

export default EditDirectives;
