export const appsList = [
 {
  name: "apps.websites",
  link: "/app/sites",
  icon: "WebIcon",
  add: "/app/sites/add",
  internElement: "apps.website.totalWebsites",
  sectionMessage: "apps.website.websiteInfo",
  section: "website",
  addTitle: "apps.add.website"
 },
 {
  name: "apps.maildomains",
  link: "/app/mail",
  icon: "MailIcon",
  add: "/app/mail/add",
  internElement: "apps.maildomain.totalMails",
  sectionMessage: "apps.maildomain.mailInfo",
  section: "maildomain",
  addTitle: "apps.add.maildomain"
 },
 {
  name: "apps.zones",
  link: "/app/zone",
  icon: "ZoneIcon",
  add: "/app/sites/add",
  internElement: "apps.zone.totalZones",
  sectionMessage: "apps.zone.zoneInfo",
  section: "zone",
  addTitle: "apps.add.zone"
 }
];
