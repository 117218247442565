import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { sortTable } from "helpers/table";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";

import { getAllServersNotPaged } from "redux/handlers/serverHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { ISpamSelector } from "types/api/mailApiInterface";
import { IAllServers } from "types/api/serversApiInterface";
import {
 balancerTableData,
 baremetalTableData,
 cloudboxTableData,
 cloudbucketTableData,
 cronjobTableData,
 customerTableData,
 domainOrdersTableData,
 domainTableData,
 firewallsTableData,
 floatingIpTableData,
 hostTableData,
 jobqueueTableData,
 mailsTableData,
 networksTableData,
 profileOrdersTableData,
 serverJobQueueTableData,
 serverOrdersTableData,
 serverTableData,
 serviceTableData,
 snapshotTableData,
 TableContent,
 TableData,
 TableKeys,
 volumeTableData,
 websitesBackupTableData,
 websitesTableData,
 zonesTableData
} from "types/global/table";

import BaremetalTable from "./tablesContent/BaremetalTable";
import CloudboxTable from "./tablesContent/CloudboxTable";
import CloudbucketTable from "./tablesContent/CloudbucketTable";
import CronjobTable from "./tablesContent/CronjobTable";
import CustomersTable from "./tablesContent/CustomersTable";
import DomainOrdersTable from "./tablesContent/DomainOrdersTable";
import DomainsTable from "./tablesContent/DomainsTable";
import FirewallTable from "./tablesContent/FirewallTable";
import FloatingIpTable from "./tablesContent/FloatingIpTable";
import HostTable from "./tablesContent/HostTable";
import JobqueueTable from "./tablesContent/JobqueueTable";
import LoadBalancerTable from "./tablesContent/LoadBalancerTable";
import MailsTable from "./tablesContent/MailsTable";
import NetworksTable from "./tablesContent/NetworksTable";
import ProfileOrdersTable from "./tablesContent/ProfileOrdersTable";
import ServerJobQueueTable from "./tablesContent/ServerJobQueueTable";
import ServerOrdersTable from "./tablesContent/ServerOrdersTable";
import ServerTable from "./tablesContent/ServerTable";
import ServicesTable from "./tablesContent/ServicesTable";
import SnapshotsTable from "./tablesContent/SnapshotsTable";
import VolumesTable from "./tablesContent/VolumesTable";
import WebsitesBackupTable from "./tablesContent/WebsitesBackupTable";
import WebsitesTable from "./tablesContent/WebsitesTable";
import ZonesTable from "./tablesContent/ZonesTable";

const SortingTable = ({
 tableData,
 maxWidth,
 caller,
 listToShow,
 reloadData,
 performance,
 spamfilter,
 mailPerformance,
 sitePerformance
}: {
 tableData: TableData;
 maxWidth: number;
 caller: string;
 listToShow: Array<TableContent>;
 reloadData?: () => void;
 performance?: Array<{ token: string; cpu: number; ram: number; disk: number }>;
 spamfilter?: Array<ISpamSelector>;
 mailPerformance?: Array<{ domain: string; disk: string }>;
 sitePerformance?: Array<{ site: string; size: string; logs: string }>;
}): ReactElement => {
 const dispatch = useAppDispatch();

 const [sortBy, setSortBy] = useState<TableKeys>("");
 const [sortDirection, setSortDirection] = useState<"desc" | "asc">("asc");
 const [servers, setServers] = useState<Array<IAllServers>>([]);

 useEffect(() => {
  (async () => {
   setServers(await dispatch(getAllServersNotPaged()));
  })();
 }, []);

 const handleSort = (key: TableKeys) => {
  if (key === sortBy) {
   setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  } else {
   setSortBy(key);
   setSortDirection("asc");
  }
 };

 const sortedData = [...listToShow].sort((a, b) => {
  if (sortBy && Object.keys(a).includes(sortBy) && Object.keys(b).includes(sortBy)) {
   return sortTable(caller, a, b, sortBy, sortDirection);
  } else return 0;
 });

 const formatTable = (element: TableContent, index: number) => {
  switch (caller) {
   case "server":
    return (
     <ServerTable
      key={`table-content-sorted-${index}`}
      rowData={element as serverTableData}
      index={index}
      performance={performance || [{ token: "", cpu: 0, ram: 0, disk: 0 }]}
     />
    );
   case "baremetal":
    return (
     <BaremetalTable
      key={`table-content-sorted-${index}`}
      rowData={element as baremetalTableData}
      index={index}
     />
    );
   case "domains":
    return (
     <DomainsTable
      key={`table-content-sorted-${index}`}
      rowData={element as domainTableData}
      index={index}
     />
    );
   case "mails":
    return (
     <MailsTable
      key={`table-content-sorted-${index}`}
      rowData={element as mailsTableData}
      index={index}
      spamfilter={spamfilter || []}
      performance={mailPerformance || []}
     />
    );
   case "websites":
    return (
     <WebsitesTable
      key={`table-content-sorted-${index}`}
      rowData={element as websitesTableData}
      index={index}
      performance={sitePerformance || []}
     />
    );
   case "zones":
    return (
     <ZonesTable
      key={`table-content-sorted-${index}`}
      rowData={element as zonesTableData}
      index={index}
     />
    );
   case "jobqueue":
    return (
     <JobqueueTable
      key={`table-content-sorted-${index}`}
      rowData={element as jobqueueTableData}
      index={index}
     />
    );
   case "snapshots":
    return (
     <SnapshotsTable
      key={`table-content-sorted-${index}`}
      rowData={element as snapshotTableData}
      index={index}
      servers={servers}
     />
    );
   case "loadBalancer":
    return (
     <LoadBalancerTable
      key={`table-content-sorted-${index}`}
      rowData={element as balancerTableData}
      index={index}
     />
    );
   case "volumes":
    return (
     <VolumesTable
      key={`table-content-sorted-${index}`}
      rowData={element as volumeTableData}
      index={index}
      servers={servers}
     />
    );
   case "networks":
    return (
     <NetworksTable
      key={`table-content-sorted-${index}`}
      rowData={element as networksTableData}
      index={index}
     />
    );

   case "firewalls":
    return (
     <FirewallTable
      key={`table-content-sorted-${index}`}
      rowData={element as firewallsTableData}
      index={index}
     />
    );
   case "cloudbox":
    return (
     <CloudboxTable
      key={`table-content-sorted-${index}`}
      rowData={element as cloudboxTableData}
      index={index}
     />
    );
   case "cloudbucket":
    return (
     <CloudbucketTable
      key={`table-content-sorted-${index}`}
      rowData={element as cloudbucketTableData}
      index={index}
      servers={servers}
     />
    );
   case "floatingip":
    return (
     <FloatingIpTable
      key={`table-content-sorted-${index}`}
      rowData={element as floatingIpTableData}
      index={index}
      servers={servers}
     />
    );
   case "hosts":
    return (
     <HostTable
      key={`table-content-sorted-${index}`}
      rowData={element as hostTableData}
      index={index}
     />
    );
   case "services":
    return (
     <ServicesTable
      key={`table-content-sorted-${index}`}
      rowData={element as serviceTableData}
      index={index}
     />
    );
   case "customers":
    return (
     <CustomersTable
      key={`table-content-sorted-${index}`}
      rowData={element as customerTableData}
      index={index}
     />
    );
   case "domainOrders":
    return (
     <DomainOrdersTable
      key={`table-content-sorted-${index}`}
      rowData={element as domainOrdersTableData}
      index={index}
     />
    );
   case "serverJobQueue":
    return (
     <ServerJobQueueTable
      key={`table-content-sorted-${index}`}
      rowData={element as serverJobQueueTableData}
      index={index}
     />
    );
   case "serverOrders":
    return (
     <ServerOrdersTable
      key={`table-content-sorted-${index}`}
      rowData={element as serverOrdersTableData}
      index={index}
     />
    );
   case "cronjob":
    return (
     <CronjobTable
      key={`table-content-sorted${index}`}
      rowData={element as cronjobTableData}
      index={index}
     />
    );
   case "webBackup":
    return (
     <WebsitesBackupTable
      key={`table-content-sorted${index}`}
      rowData={element as websitesBackupTableData}
      index={index}
     />
    );
   case "profileOrders":
    return (
     <ProfileOrdersTable
      key={`table-content-sorted${index}`}
      rowData={element as profileOrdersTableData}
      index={index}
      reloadData={reloadData}
     />
    );
   default:
    return <></>;
  }
 };

 return (
  <TableContainer component="div">
   <Table sx={{ minWidth: maxWidth }} aria-label="simple table">
    <TableHead>
     <TableRow sx={{ backgroundColor: "transparent" }}>
      {tableData.map((data, index) => {
       if (data.keys) {
        return (
         <TableCell align={data.headAlign} key={`table-sorted-index-${index}`}>
          <TableSortLabel
           active={sortBy === data.keys}
           direction={sortBy === data.keys ? sortDirection : "asc"}
           onClick={() => handleSort(data.keys)}>
           <Typography variant="kxColoredSmall" fontWeight="bold">
            <FormattedMessage id={data.headText} />
           </Typography>
          </TableSortLabel>
         </TableCell>
        );
       } else {
        return (
         <TableCell align={data.headAlign} key={`table-sorted-index-${index}`}>
          <Typography variant="kxColoredSmall" fontWeight="bold">
           <FormattedMessage id={data.headText} />
          </Typography>
         </TableCell>
        );
       }
      })}
     </TableRow>
    </TableHead>
    <TableBody>
     {sortedData.map((element, index) => {
      return formatTable(element, index);
     })}
    </TableBody>
   </Table>
  </TableContainer>
 );
};

export default SortingTable;
