/* eslint-disable react/style-prop-object */
import React, { ReactElement, useState } from "react";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import AppModal from "components/shared/modal/AppModal";

import { postCreateContaboBucket } from "redux/handlers/addonsHandle";

import { getUserBalance } from "redux/selectors/userSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { contaboBucketDataToSend } from "types/global/bucket";

const BuyContaboCloudbucket = ({
 open,
 dataToSend,
 handleClose,
 handleReset
}: {
 open: boolean;
 dataToSend: contaboBucketDataToSend;
 handleClose: () => void;
 handleReset: () => void;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();
 const navigate = useNavigate();

 const userBalance = useSelector(getUserBalance);

 const [loading, setLoading] = useState<boolean>(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(
   postCreateContaboBucket(
    dataToSend.provider,
    dataToSend.location,
    dataToSend.size / 1000,
    dataToSend.name,
    dataToSend.autoScaling,
    dataToSend.max_space,
    dataToSend.price
   )
  );
  handleClose();
  handleReset();
  setLoading(false);
  navigate("/addons");
 };

 return (
  <AppModal
   open={open}
   close={handleClose}
   title={intl.formatMessage({ id: "addons.cloudbucket.newBucket" })}
   handleClose={handleClose}
   handleConfirm={handleConfirm}
   disabled={userBalance < dataToSend.price || loading}>
   <Stack>
    <FormattedMessage id="addons.cloudbucket.confirmNewBucket" />
    <Stack direction="row" spacing={2}>
     <Typography minWidth={150} textAlign="start" fontWeight="bold">
      <FormattedMessage id="addons.cloudbucket.balance" />
     </Typography>
     <Typography>
      <FormattedNumber value={userBalance} style={"currency"} currency="EUR" />
     </Typography>
    </Stack>
    <Stack direction="row" spacing={2} alignItems="center">
     <Typography minWidth={150} textAlign="start" fontWeight="bold">
      <FormattedMessage id="addons.cloudbucket.totalPrice" />
     </Typography>
     <Typography>
      <FormattedNumber value={dataToSend.price || 0} style={"currency"} currency="EUR" />
     </Typography>
    </Stack>
   </Stack>
  </AppModal>
 );
};

export default BuyContaboCloudbucket;
