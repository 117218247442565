import React, { ReactElement, useContext, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux/es/exports";
import { useNavigate } from "react-router-dom";
import useDebounce from "react-use/lib/useDebounce";
import { AppContext } from "AppContext";
import { checkForRender } from "helpers/generic";

import { isNil } from "ramda";

import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";

import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TablePagination from "@mui/material/TablePagination";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { baremetalTable } from "constants/tables";

import AdminWrapper from "components/shared/admin/AdminWrapper";
import EmptyList from "components/shared/emptyList/EmptyList";
import SearchForPartner from "components/shared/search/SearchForPartner";
import SortingTable from "components/shared/tables/SortingTable";

import { getAllBaremetals } from "redux/handlers/baremetalHandle";
import { getUsersList } from "redux/handlers/userHandler";

import { getBaremetalDataset, getBaremetalNumber } from "redux/selectors/baremetalSelector";
import { getIsAdmin } from "redux/selectors/userSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { IUsersList } from "types/api/userApiInterface";

const Baremetal = (): ReactElement => {
 const dispatch = useAppDispatch();
 const navigate = useNavigate();
 const { socket } = useContext(AppContext);

 const baremetalList = useSelector(getBaremetalDataset);
 const baremetalNumber = useSelector(getBaremetalNumber);
 const isAdmin = useSelector(getIsAdmin);

 const searchRef = useRef<HTMLInputElement>(null);
 const [currentPage, setCurrentPage] = useState<number>(0);
 const [rowPerPage, setRowPerPage] = useState<number>(10);
 const [searchValue, setSearchValue] = useState<string>("");
 const [contactsList, setContactsList] = useState<Array<IUsersList>>([]);
 const [isLoading, setIsLoading] = useState<boolean>(true);
 const [selectedPartner, setSelectedPartner] = useState<string>("");
 const [reloadData, setReloadData] = useState<boolean>(false);
 const [beginningSearch, setBeginningSearch] = useState<boolean>(false);

 useEffect(() => {
  !isNil(socket) &&
   socket.on("barmetal_data", (value): void => {
    if (value?.completed) {
     setReloadData(true);
    }
   });
  return () => {
   !isNil(socket) && socket.off("barmetal_data");
  };
 }, [socket]);

 useEffect(() => {
  (async () => {
   if (reloadData) {
    dispatch(getAllBaremetals(currentPage, rowPerPage, searchRef?.current?.value, selectedPartner));
    setReloadData(false);
    setBeginningSearch(false);
   }
  })();
 }, [reloadData]);

 useEffect(() => {
  (async () => {
   setCurrentPage(0);
   isAdmin && setContactsList(await dispatch(getUsersList()));
   setIsLoading(false);
  })();
 }, []);

 useDebounce(
  () => {
   if (!isLoading) {
    setCurrentPage(0);
    dispatch(getAllBaremetals(currentPage, rowPerPage, searchRef?.current?.value, selectedPartner));
   }
  },
  1000,
  [searchValue]
 );

 const handleOnRowsPerPageChange = (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
 ) => {
  const newRowsPerPage = parseInt(event.target.value, 10);
  setRowPerPage(newRowsPerPage);
  setCurrentPage(0);
  dispatch(getAllBaremetals(0, newRowsPerPage, searchRef?.current?.value, selectedPartner));
 };

 const handleOnPageChange = (
  event: React.MouseEvent<HTMLButtonElement> | null,
  newPage: number
 ) => {
  const startIndex = newPage * rowPerPage;
  setCurrentPage(newPage);
  dispatch(getAllBaremetals(startIndex, rowPerPage, searchRef?.current?.value, selectedPartner));
 };

 const handleSearchPartner = async (partnerIdValue: string) => {
  setCurrentPage(0);
  setSelectedPartner(partnerIdValue);
  await dispatch(
   getAllBaremetals(currentPage, rowPerPage, searchRef.current?.value || "", partnerIdValue)
  );
 };

 return (
  <>
   <Stack mt={5}>
    <Stack justifyContent="space-between" alignItems="flex-end" direction="row" pb={1}>
     <Typography fontWeight="bold" fontSize={16} textTransform="uppercase">
      <FormattedMessage id="app.baremetal" />
      {` (${baremetalNumber})`}
     </Typography>
     <Button
      variant="kxActionButton"
      endIcon={<AddIcon />}
      onClick={() => navigate("/servers/add/baremetal")}>
      <FormattedMessage id="baremetal.action.add" />
     </Button>
    </Stack>
    {checkForRender(baremetalList, searchValue, selectedPartner, beginningSearch) ? (
     <Paper elevation={0} sx={{ borderRadius: "10px", boxShadow: 0 }}>
      <Stack justifyContent="flex-start" alignItems="center" p={2} direction="row">
       <TextField
        size="small"
        autoComplete="off"
        inputRef={searchRef}
        label={<FormattedMessage id="search.baremetal" />}
        InputLabelProps={{ shrink: true }}
        InputProps={{ startAdornment: <SearchIcon color="disabled" /> }}
        onChange={({ currentTarget }) => {
         setSearchValue(currentTarget.value);
         setBeginningSearch(true);
        }}
       />
       <AdminWrapper sx={{ ml: 2 }}>
        <SearchForPartner
         contactList={contactsList}
         handleSearchPartner={(partner) => handleSearchPartner(partner)}
        />
       </AdminWrapper>
      </Stack>
      <Divider sx={{ borderColor: "#f86e06" }} />
      <Stack>
       <SortingTable
        tableData={baremetalTable}
        maxWidth={650}
        caller="baremetal"
        listToShow={baremetalList}
       />
       <TablePagination
        component="div"
        count={baremetalNumber || 0}
        page={currentPage}
        rowsPerPage={rowPerPage}
        onPageChange={handleOnPageChange}
        onRowsPerPageChange={handleOnRowsPerPageChange}
       />
      </Stack>
     </Paper>
    ) : (
     <EmptyList />
    )}
   </Stack>
  </>
 );
};

export default Baremetal;
