import React, { useRef, useState } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { useSelector } from "react-redux";
import { handleChangePageNumber } from "helpers/pagination";

import SearchIcon from "@mui/icons-material/Search";

import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TablePagination from "@mui/material/TablePagination";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { customersTable } from "constants/tables";

import SortingTable from "components/shared/tables/SortingTable";

import { getAllCustomers } from "redux/handlers/customersHandler";

import { getCustomersDataSet, getCustomersNumber } from "redux/selectors/customersSelector";

import { useAppDispatch } from "hooks/reduxHook";

import AddCustomer from "./actions/AddCustomer";

const Customers = () => {
 const searchRef = useRef<HTMLInputElement>(null);
 const dispatch = useAppDispatch();

 const customersList = useSelector(getCustomersDataSet);
 const customersNumber = useSelector(getCustomersNumber);

 const [currentPage, setCurrentPage] = useState<number>(0);
 const [rowPerPage, setRowPerPage] = useState<number>(10);

 const handleOnSearch = () => {
  setCurrentPage(0);
  dispatch(getAllCustomers(currentPage, rowPerPage, searchRef?.current?.value));
 };

 // TODO: da verificare
 const handleOnRowsPerPageChange = (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
 ) => {
  const data = handleChangePageNumber(parseInt(event.target.value, 10), currentPage, rowPerPage);
  setRowPerPage(data.newRow);
  setCurrentPage(Number(data.newPage.toFixed(0)));

  dispatch(
   getAllCustomers(
    Number(data.newPage.toFixed(0)),
    parseInt(event.target.value, 10),
    searchRef?.current?.value
   )
  );
 };

 const handleOnPageChange = (
  event: React.MouseEvent<HTMLButtonElement> | null,
  newPage: number
 ) => {
  setCurrentPage(newPage);
  dispatch(getAllCustomers(newPage, rowPerPage, searchRef?.current?.value));
 };

 return (
  <Stack>
   <Stack justifyContent="space-between" alignItems="flex-end" direction="row" pb={1}>
    <Typography fontWeight="bold" fontSize={16} textTransform="uppercase">
     <FormattedMessage id="app.customers" />
    </Typography>
    <AddCustomer />
   </Stack>
   <Paper elevation={0} sx={{ borderRadius: "10px", boxShadow: 0 }}>
    <Stack justifyContent="flex-start" alignItems="center" p={2} direction="row">
     <Typography variant="subtitle2">
      <FormattedNumber value={customersNumber || 0} /> <FormattedMessage id="app.totalCustomers" />
     </Typography>
     <TextField
      size="small"
      autoComplete="off"
      inputRef={searchRef}
      label={<FormattedMessage id="search.customers" />}
      sx={{ ml: 4 }}
      InputLabelProps={{ shrink: true }}
      InputProps={{ startAdornment: <SearchIcon color="disabled" /> }}
      onChange={handleOnSearch}
     />
    </Stack>
    <Divider sx={{ borderColor: "#f86e06" }} />
    <Stack>
     <SortingTable
      tableData={customersTable}
      maxWidth={650}
      caller="customers"
      listToShow={customersList}
     />
     <TablePagination
      component="div"
      count={customersNumber || 0}
      page={currentPage}
      rowsPerPage={rowPerPage}
      onPageChange={handleOnPageChange}
      onRowsPerPageChange={handleOnRowsPerPageChange}
     />
    </Stack>
   </Paper>
  </Stack>
 );
};

export default Customers;
