import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";

import { getMonthlyExpenses } from "redux/handlers/userHandler";

import { useAppDispatch } from "hooks/reduxHook";

const ExpensesBox = (): ReactElement => {
 const dispatch = useAppDispatch();

 const [expenses, setExpenses] = useState<number>(0);
 const [difference, setDifferece] = useState<number>(0);

 useEffect(() => {
  (async () => {
   const result = await dispatch(getMonthlyExpenses());
   if (result.length > 0) {
    setExpenses(result[result.length - 1]?.totalSpent);
    if (result.length > 1)
     setDifferece(result[result.length - 1]?.totalSpent - result[result.length - 2]?.totalSpent);
   }
  })();
 }, []);

 return (
  <Paper elevation={0} sx={{ mt: 3, p: 2, borderRadius: "10px", boxShadow: 0 }}>
   <Stack spacing={1}>
    <Typography textAlign="left" variant="h5" fontWeight="bold" textTransform="uppercase">
     <FormattedMessage id="app.monthlyExpenses" />
    </Typography>
    <Stack spacing={2}>
     <Stack>
      <Stack direction="row" alignItems="flex-end">
       <Typography variant="caption" fontSize={20} sx={{ lineHeight: 0.75 }}>
        €
       </Typography>
       <Typography variant="body1" fontSize={50} sx={{ lineHeight: 0.75 }}>
        {expenses.toFixed(2)}
       </Typography>
      </Stack>
      <Stack direction="row">
       <Typography variant="caption" fontStyle="italic">
        <FormattedMessage id="app.spentThisMonth" />
       </Typography>
      </Stack>
     </Stack>
     {difference > 0 ? (
      <Stack>
       <Stack direction="row">
        <IconSelector icon="TrendingUpIcon" props={{ color: "success" }} />
        <Typography>{`${difference.toFixed(2)} €`}</Typography>
       </Stack>
       <Stack direction="row">
        <Typography variant="caption" fontStyle="italic">
         <FormattedMessage id="app.moreThanLast" />
        </Typography>
       </Stack>
      </Stack>
     ) : (
      <Stack>
       <Stack direction="row">
        <IconSelector icon="TrendingDownIcon" props={{ color: "error" }} />
        <Typography>{`${(difference * -1).toFixed(2)} €`}</Typography>
       </Stack>
       <Stack direction="row">
        <Typography variant="caption" fontStyle="italic">
         <FormattedMessage id="app.lessThanLast" />
        </Typography>
       </Stack>
      </Stack>
     )}
    </Stack>
   </Stack>
  </Paper>
 );
};

export default ExpensesBox;
