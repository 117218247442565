import React, { ReactElement } from "react";
import { FormattedDate, FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { formatMegaBytes } from "helpers/numberFormatting";

import Alert from "@mui/material/Alert";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";

import { getCloudboxDataset } from "redux/selectors/cloudboxSelector";

import ChangeSnapshotPlan from "../actions/ChangeSnapshotPlan";
import CreateSnapshot from "../actions/CreateSnapshot";

import SnapshotActions from "./SnapshotActions";

const CloudboxSnapshots = (): ReactElement => {
 const params = useParams<{ id: string; section: string }>();
 const cloudboxData = useSelector(getCloudboxDataset).find(
  (element) => element.id === Number(params.id)
 );

 return (
  <Paper elevation={0} sx={{ mt: 1, borderRadius: "10px", boxShadow: 0 }}>
   <Stack direction="row" justifyContent="space-between" mb={1} pt={1} px={2}>
    <Stack alignItems="center" justifyContent="flex-start" direction="row">
     <IconSelector icon="KeyIcon" props={{ fontSize: "medium", color: "secondary" }} />
     <Typography variant="h6" component="h2" ml={1}>
      <FormattedMessage id="addons.cloudbox.snapshot" />
     </Typography>
    </Stack>
    {cloudboxData && (
     <Stack direction="row" spacing={1}>
      <ChangeSnapshotPlan />
      <CreateSnapshot id={cloudboxData.id} />
     </Stack>
    )}
   </Stack>
   <Divider sx={{ borderColor: "#f86e06" }} />

   {cloudboxData?.snapshot && cloudboxData.snapshot.length > 0 ? (
    <TableContainer component="div">
     <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
       <TableRow sx={{ backgroundColor: "transparent" }}>
        <TableCell>
         <Typography variant="kxColoredSmall" fontWeight="bold">
          <FormattedMessage id="addons.cloudbox.name" />
         </Typography>
        </TableCell>
        <TableCell>
         <Typography variant="kxColoredSmall" fontWeight="bold">
          <FormattedMessage id="addons.cloudbox.size" />
         </Typography>
        </TableCell>
        <TableCell>
         <Typography variant="kxColoredSmall" fontWeight="bold">
          <FormattedMessage id="addons.cloudbox.fileSystem" />
         </Typography>
        </TableCell>
        <TableCell>
         <Typography variant="kxColoredSmall" fontWeight="bold">
          <FormattedMessage id="addons.cloudbox.creationDate" />
         </Typography>
        </TableCell>
        <TableCell>
         <Typography variant="kxColoredSmall" fontWeight="bold">
          <FormattedMessage id="addons.cloudbox.actions" />
         </Typography>
        </TableCell>
       </TableRow>
      </TableHead>
      <TableBody>
       {cloudboxData.snapshot.map((row, index) => (
        <TableRow key={`cloudbox-list-${index}`}>
         <TableCell component="th" scope="row">
          <Stack direction="column">
           <Typography variant="inherit" fontWeight="bold">
            {row.name}
           </Typography>
          </Stack>
         </TableCell>
         <TableCell component="th" scope="row">
          <Stack direction="row" alignContent="center" spacing={0.5}>
           <Typography variant="inherit">{formatMegaBytes(row.size, "GB").toFixed(3)}</Typography>
           <Typography variant="caption" lineHeight={1.93}>
            GB
           </Typography>
          </Stack>
         </TableCell>
         <TableCell component="th" scope="row">
          <Stack direction="row" alignContent="center" spacing={0.5}>
           <Typography variant="inherit">
            {formatMegaBytes(row.filesystem_size, "GB").toFixed(0)}
           </Typography>
           <Typography variant="caption" lineHeight={1.93}>
            GB
           </Typography>
          </Stack>
         </TableCell>
         <TableCell>
          <Stack>
           <FormattedDate value={row.createdAt} />
          </Stack>
         </TableCell>
         <TableCell>
          <SnapshotActions rowData={row} />
         </TableCell>
        </TableRow>
       ))}
      </TableBody>
     </Table>
    </TableContainer>
   ) : (
    <Stack sx={{ p: 2 }}>
     <Alert severity="info">
      nessuna snapshot collegata al cloudbox, creane una per continuare
     </Alert>
    </Stack>
   )}
  </Paper>
 );
};

export default CloudboxSnapshots;
