import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { CircularProgress, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { generateSslKey, updateSslCertificate } from "redux/handlers/websitesHandler";

import { getWebsitesDetails } from "redux/selectors/websitesSelector";

import { useAppDispatch } from "hooks/reduxHook";

const UpdateCertificate = (): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const site = useSelector(getWebsitesDetails);

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);
 const [newCert, setNewCert] = useState<string>("");
 const [generatingKey, setGeneratingKey] = useState<boolean>(false);

 useEffect(() => {
  if (generatingKey) {
   setGeneratingKey(false);
  }
 }, [site.ssl_key, site.ssl_request]);

 const handleOpen = () => setOpen(true);
 const handleClose = () => setOpen(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(updateSslCertificate(site.id || 0, newCert));
  setLoading(false);
  handleClose();
 };

 const checkDisabled = () => {
  return site.ssl_key === "" || newCert === "" || site.ssl_request === "" || generatingKey;
 };

 const handleGenerateCertificate = async () => {
  await dispatch(generateSslKey(site.id || 0));
  setGeneratingKey(true);
 };

 return (
  <>
   <Tooltip title={<FormattedMessage id="sites.updateSslCertificate" />}>
    <IconButton onClick={handleOpen}>
     <IconSelector
      icon="SslIcon"
      props={{
       color: "primary"
      }}
     />
    </IconButton>
   </Tooltip>
   <AppModal
    open={open}
    close={handleClose}
    loading={loading}
    title={intl.formatMessage({ id: "sites.updateSslCertificate" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}
    disabled={loading || checkDisabled()}>
    <Stack spacing={2}>
     <Alert severity="warning">
      <FormattedMessage id="sites.sslUpdateMessage" />
     </Alert>
     <Stack spacing={2}>
      <TextField
       multiline
       fullWidth
       label={<FormattedMessage id="sites.sslKey" />}
       value={site.ssl_key}
       maxRows={7}
      />
      <TextField
       multiline
       fullWidth
       label={<FormattedMessage id="sites.sslRequest" />}
       value={site.ssl_request}
       maxRows={7}
      />
      {!generatingKey && (
       <Button onClick={handleGenerateCertificate}>
        <FormattedMessage id="sites.generateKey" />
       </Button>
      )}
      {generatingKey && (
       <Stack direction="row" spacing={1} alignItems="center">
        <CircularProgress />
        <Typography>
         <FormattedMessage id="sites.genetatingKey" />
        </Typography>
       </Stack>
      )}
     </Stack>
     <Stack>
      <TextField
       disabled={generatingKey || site.ssl_key === "" || site.ssl_request === ""}
       multiline
       maxRows={7}
       label={<FormattedMessage id="sites.sslCertificate" />}
       value={newCert}
       onChange={({ currentTarget }) => setNewCert(currentTarget.value)}
      />
     </Stack>
    </Stack>
   </AppModal>
  </>
 );
};

export default UpdateCertificate;
