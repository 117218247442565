import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import { ICloudbucketAPI } from "types/api/addonsApiInterface";

const initialState: ICloudbucketAPI = { dataset: [], totalCount: 0 };

const CloudbucketReducer = createSlice({
 name: "cloudbuckets",
 initialState,
 reducers: {
  setCloudbuckets: (state, { payload }: PayloadAction<ICloudbucketAPI>) => {
   return payload;
  },
  resetCloudbuckets: () => {
   return initialState;
  }
 }
});

export default CloudbucketReducer;
