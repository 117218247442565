import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { postRemoveFolderFromCloudbucket } from "redux/handlers/dockerHandler";

import { getServerDetails } from "redux/selectors/serversSelector";

import { useAppDispatch } from "hooks/reduxHook";

const DisconnectCloudbucketFolder = ({
 id,
 folders
}: {
 id: number;
 folders: Array<string>;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const { bucket_id_db } = useSelector(getServerDetails);

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);
 const [selectedFolder, setSelectedFolder] = useState<string>("");

 const handleOpen = () => setOpen(true);
 const handleClose = () => setOpen(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postRemoveFolderFromCloudbucket(id, bucket_id_db || 0, selectedFolder));
  setLoading(false);
  handleClose();
 };

 return (
  <>
   <Tooltip title={intl.formatMessage({ id: "docker.website.removeFolder" })} placement="top">
    <IconButton onClick={handleOpen}>
     <IconSelector icon="LinkOffIcon" props={{ fontSize: "small" }} />
    </IconButton>
   </Tooltip>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "docker.website.removeFolder" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}
    disabled={loading || selectedFolder === ""}>
    <Stack spacing={2}>
     <Typography>
      <FormattedMessage id="docker.website.removeFolderMessage" />
     </Typography>
     <FormControl>
      <InputLabel>
       <FormattedMessage id="addons.cloudbox.folder" />
      </InputLabel>
      <Select
       label={<FormattedMessage id="addons.cloudbox.folder" />}
       value={selectedFolder}
       onChange={(e) => setSelectedFolder(e.target.value)}>
       {folders.map((element, index) => {
        return (
         <MenuItem key={`folder-number-${index}`} value={element}>
          {element}
         </MenuItem>
        );
       })}
      </Select>
     </FormControl>
    </Stack>
   </AppModal>
  </>
 );
};

export default DisconnectCloudbucketFolder;
