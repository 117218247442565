import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { LineChart } from "@mui/x-charts/LineChart";
import { formatMonth } from "helpers/user";

import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { getMonthlyExpenses } from "redux/handlers/userHandler";

import { useAppDispatch } from "hooks/reduxHook";

const ProfileBox = (): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const user = useSelector((state: any) => state.user);

 const [expenses, setExpenses] = useState<Array<number>>([]);
 const [months, setMonths] = useState<Array<number>>([]);
 const [total, setTotal] = useState<number>(0);

 useEffect(() => {
  (async () => {
   const result = await dispatch(getMonthlyExpenses());
   const monthArr = [];
   const expArr = [];
   let spent = 0;
   for (let element of result) {
    monthArr.push(element.month);
    expArr.push(element?.totalSpent);
    spent += element?.totalSpent;
   }
   setTotal(spent);
   setMonths(monthArr);
   setExpenses(expArr);
  })();
 }, []);

 const convertEpochToDate = (value: number) => {
  return intl.formatMessage({ id: formatMonth(value) });
 };

 return (
  <Paper elevation={0} sx={{ mt: 3, p: 2, borderRadius: "10px", boxShadow: 0 }}>
   <Stack spacing={1}>
    <Typography textAlign="left" variant="h5" fontWeight="bold" textTransform="uppercase">
     Ciao {`${user.firstname} ${user.lastname}`}
    </Typography>
    <Stack direction="row" spacing={1}>
     <Typography fontWeight="bold">Balance:</Typography>
     <Typography fontStyle="italic" sx={{ color: "#00a152" }}>{`${user.balance} €`}</Typography>
    </Stack>
    <LineChart
     xAxis={[
      {
       data: months,
       valueFormatter: (value: number) => convertEpochToDate(value),
       tickInterval: months
      }
     ]}
     series={[
      {
       curve: "linear",
       data: expenses,
       label: "Euro"
      }
     ]}
     height={300}
     margin={{ left: 30, right: 30, top: 30, bottom: 30 }}
     grid={{ vertical: true, horizontal: true }}
    />
   </Stack>
   <Stack>
    <Stack direction="row">
     <Typography variant="body1">
      <FormattedMessage id="app.totalSpent" />
      {`: ${total.toFixed(2)}€`}
     </Typography>
    </Stack>
   </Stack>
  </Paper>
 );
};

export default ProfileBox;
