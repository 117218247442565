import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import AddIcon from "@mui/icons-material/Add";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import IconSelector from "components/shared/images/IconSelector";

import { getAllServerCloudbuckets } from "redux/handlers/serverHandler";

import { getServerDbId, getServerProviderId } from "redux/selectors/serversSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { IServerBuckets } from "types/api/serversApiInterface";

import DisconnectCloudbucket from "./cloudbucketActions/DisconnectCloudbucket";

const ServerCloudbuckets = (): ReactElement => {
 const navigate = useNavigate();
 const dispatch = useAppDispatch();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));

 const server_id = useSelector(getServerProviderId);
 const id = useSelector(getServerDbId);

 const [serverCloudbuckets, setServerCloudbuckets] = useState<Array<IServerBuckets>>([]);

 useEffect(() => {
  (async () => {
   const addons = await dispatch(getAllServerCloudbuckets(id));

   setServerCloudbuckets(addons);
  })();
 }, [server_id]);

 return (
  <>
   <Paper elevation={0} sx={{ mt: 2, borderRadius: "10px", boxShadow: 0 }}>
    <Stack p={2} spacing={2}>
     <Stack direction="row" justifyContent={"space-between"}>
      <Stack direction="row" spacing={1}>
       <IconSelector icon="CameraAltIcon" props={{ fontSize: "medium", color: "secondary" }} />
       <Typography fontWeight="bold" fontSize={16} textTransform="uppercase">
        <FormattedMessage id="server.addons.cloudbucket" />
       </Typography>
      </Stack>
      <Button
       variant="kxActionButton"
       endIcon={<AddIcon />}
       onClick={() => navigate("/addons/cloudbucket/add")}>
       <FormattedMessage id="addons.cloudbucket.addCloudbucket" />
      </Button>
     </Stack>
     {serverCloudbuckets.length === 0 ? (
      <Alert severity="warning">
       <FormattedMessage id="server.addons.cloudbucketsNotFound" />
      </Alert>
     ) : (
      <TableContainer component="div">
       <Table sx={{ minWidth: desktopViewPort ? 650 : 450 }} aria-label="simple table">
        <TableHead>
         <TableRow>
          <TableCell>
           <FormattedMessage id="app.name" />
          </TableCell>
          <TableCell>
           <FormattedMessage id="app.url" />
          </TableCell>
          <TableCell>
           <FormattedMessage id="app.folders" />
          </TableCell>
          <TableCell>
           <FormattedMessage id="table.actions" />
          </TableCell>
         </TableRow>
        </TableHead>
        <TableBody>
         {serverCloudbuckets.map((element, index) => (
          <TableRow key={`server-snapshot-${index}`}>
           <TableCell>
            <Typography variant="subtitle2" fontWeight="bold">
             {element.name}
            </Typography>
           </TableCell>
           <TableCell>
            <Typography variant="caption">{element.s3Url}</Typography>
           </TableCell>
           <TableCell>
            <Typography variant="caption">{`N° dir: ${element.folder || 0}`}</Typography>
           </TableCell>
           <TableCell>
            {element.folder > 0 ? (
             <Tooltip title={<FormattedMessage id="addons.cloudbucket.disconnectFolders" />}>
              <Stack>
               <IconSelector icon="InfoIcon" />
              </Stack>
             </Tooltip>
            ) : (
             <DisconnectCloudbucket id={element.id} server_id_db={id || 0} />
            )}
           </TableCell>
          </TableRow>
         ))}
        </TableBody>
       </Table>
      </TableContainer>
     )}
    </Stack>
   </Paper>
  </>
 );
};

export default ServerCloudbuckets;
