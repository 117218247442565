import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Alert from "@mui/material/Alert";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { postEditTransport } from "redux/handlers/gatewayHandler";

import { useAppDispatch } from "hooks/reduxHook";

type transportData = {
 id: number;
 name: string;
 host: string;
 protocol: string;
 port: number;
 use_mx: boolean;
};

const EditTransport = ({
 id,
 transport
}: {
 id: number;
 transport: transportData;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [isLoading, setIsLoading] = useState<boolean>(false);
 const [hostName, setHostName] = useState<string>(transport.host);
 const [port, setPort] = useState<number>(transport.port);
 const [protocol, setProtocol] = useState<string>(transport.protocol);
 const [mx, setMx] = useState<boolean>(transport.use_mx);

 const resetData = () => {
  setProtocol(transport.protocol);
  setPort(transport.port);
  setHostName(transport.host);
  setMx(transport.use_mx);
 };

 const handleOpen = () => {
  resetData();

  setOpenModal(true);
 };
 const handleClose = () => setOpenModal(false);

 const handleConfirm = async () => {
  setIsLoading(true);
  await dispatch(postEditTransport(id, transport.id, hostName, protocol, port, mx));
  handleClose();
  setIsLoading(false);
 };

 const disableCheck = (): boolean => {
  return protocol === "" || port <= 0 || hostName === "";
 };

 return (
  <Stack direction="row">
   <Tooltip title={<FormattedMessage id="gateway.editTransport" />}>
    <IconButton onClick={() => handleOpen()}>
     <IconSelector icon="EditIcon" />
    </IconButton>
   </Tooltip>
   <AppModal
    open={openModal}
    close={handleClose}
    handleClose={handleClose}
    loading={isLoading}
    disabled={disableCheck()}
    handleConfirm={handleConfirm}
    title={intl.formatMessage({ id: "gateway.editTransport" })}>
    <Stack spacing={2}>
     <Alert severity="info">
      <Typography>
       <FormattedMessage id="gateway.editTransport.transportMessage" />
      </Typography>
     </Alert>
     <TextField
      autoComplete="off"
      label={intl.formatMessage({ id: "gateway.addTransport.host" })}
      InputLabelProps={{ shrink: true }}
      value={hostName}
      onChange={({ currentTarget }) => setHostName(currentTarget.value)}
     />
     <Stack direction="row" spacing={2}>
      <FormControl fullWidth>
       <InputLabel>
        <FormattedMessage id="gateway.addTransport.protocol" />
       </InputLabel>
       <Select
        value={protocol || ""}
        label={<FormattedMessage id="gateway.addTransport.protocol" />}
        onChange={(e) => setProtocol(e.target.value)}>
        <MenuItem key="protocol-smtp" value={"SMTP"}>
         SMTP
        </MenuItem>
        <MenuItem key="protocol-lmtp" value={"LMTP"}>
         LMTP
        </MenuItem>
       </Select>
      </FormControl>
     </Stack>
     <TextField
      autoComplete="off"
      label={intl.formatMessage({ id: "gateway.addTransport.port" })}
      InputLabelProps={{ shrink: true }}
      type="number"
      value={port}
      onChange={({ currentTarget }) => setPort(Number(currentTarget.value))}
     />
     <FormControlLabel
      control={
       <Checkbox
        checked={mx}
        onChange={(e) => {
         setMx(e.target.checked);
        }}
       />
      }
      label={intl.formatMessage({ id: "gateway.addTransport.mx" })}
     />
    </Stack>
   </AppModal>
  </Stack>
 );
};

export default EditTransport;
