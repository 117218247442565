import React, { ReactElement, useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { AppContext } from "AppContext";
import { formatMemLimit, formatMemVisual } from "helpers/addons";

import { isNil } from "ramda";

import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";

import { getServerDetails } from "redux/selectors/serversSelector";

import { IDockerSiteData } from "types/api/dockerApiInterface";

import ConnectCloudbucketFolder from "../../actions/ConnectCloudbucketFolder";
import DisconnectCloudbucketFolder from "../../actions/DisconnectCloudbucketFolder";
import EditSiteTuning from "../../actions/EditSiteTuning";
import InstallModule from "../../actions/InstallModule";
import PowerOffSite from "../../actions/PowerOffSite";
import PowerOnSite from "../../actions/PowerOnSite";
import RebootSite from "../../actions/RebootSite";
import RepairDatabaseTable from "../../actions/RepairDatabaseTable";
import ResetPrivileges from "../../actions/ResetPrivileges";
import RunCustomScript from "../../actions/RunCustomScript";

import LitespeedBox from "./LitespeedBox";

const StatisticSection = ({
 rowData,
 hasDb
}: {
 rowData: IDockerSiteData;
 hasDb: boolean;
}): ReactElement => {
 const intl = useIntl();

 const { socket } = useContext(AppContext);
 const { agentoken } = useSelector(getServerDetails);
 const { cloudbucket_connected } = useSelector(getServerDetails);

 const [netIo, setNetIo] = useState<string>("not available");
 const [blockIo, setBlockIo] = useState<string>("not available");
 const [netIoDb, setNetIoDb] = useState<string>("not available");
 const [blockIoDb, setBlockIoDb] = useState<string>("not available");
 const [cpu, setCpu] = useState<number>(0);
 const [ram, setRam] = useState<number>(0);
 const [siteCpu, setSiteCpu] = useState<number>(0);
 const [siteRam, setSiteRam] = useState<number>(0);

 useEffect(() => {
  if (!isNil(socket)) {
   socket.on("container_website_performance", (value): void => {
    if (value?.length > 0) {
     for (let element of value) {
      if (element.token === agentoken && element.name_ct === rowData.site_name) {
       setSiteCpu(Number(element?.cpu_ct.substring(0, element?.cpu_ct.length - 1)));
       setSiteRam(
        Number(element?.mem_usage_ct_pc.substring(0, element?.mem_usage_ct_pc.length - 1))
       );
       setNetIo(element?.net_io);
       setBlockIo(element?.block_io);
      }
      if (element.token === agentoken && element.name_ct === `db_${rowData.site_name}`) {
       setCpu(Number(element?.cpu_ct.substring(0, element?.cpu_ct.length - 1)));
       setRam(Number(element?.mem_usage_ct_pc.substring(0, element?.mem_usage_ct_pc.length - 1)));
       setNetIoDb(element?.net_io);
       setBlockIoDb(element?.block_io);
      }
     }
    }
   });
  }
  return () => {
   !isNil(socket) && socket.off("container_website_performance");
  };
 }, [socket]);

 return (
  <Grid container spacing={2}>
   <Grid xs={6}>
    <Stack spacing={2}>
     <Stack spacing={2}>
      <Typography fontWeight="bold">
       <FormattedMessage id="docker.website.siteStatistics" />
       {` ${rowData.site_name}`}
      </Typography>
      <Stack>
       <Stack direction="row" alignItems="center">
        <Typography variant="body1" fontSize={12} textTransform="uppercase" minWidth={70}>
         {`${intl.formatMessage({ id: "server.cpu" })} ${siteCpu.toFixed(0)}%`}
        </Typography>
        <LinearProgress
         sx={{
          width: "100%",
          ml: 2,
          backgroundColor: "#F1F2F7",
          "& .MuiLinearProgress-bar": {
           backgroundColor: siteCpu < 50 ? "#41C58C" : siteCpu < 75 ? "#FF9800" : "#F44336"
          },
          height: 10,
          borderRadius: 0.8
         }}
         variant="determinate"
         value={siteCpu > 100 ? 100 : siteCpu}
        />
       </Stack>
       <Stack direction="row" alignItems="center">
        <Typography variant="body1" fontSize={12} textTransform="uppercase" minWidth={70}>
         {`${intl.formatMessage({ id: "server.ram" })} ${siteRam.toFixed(0)}%`}
        </Typography>
        <LinearProgress
         sx={{
          width: "100%",
          ml: 2,
          backgroundColor: "#F1F2F7",
          "& .MuiLinearProgress-bar": {
           backgroundColor: siteRam < 50 ? "#41C58C" : siteRam < 75 ? "#FF9800" : "#F44336"
          },
          height: 10,
          borderRadius: 0.8
         }}
         variant="determinate"
         value={siteRam > 100 ? 100 : siteRam}
        />
       </Stack>
       <Stack direction="row" alignItems="center">
        <Typography variant="body1" fontSize={12} textTransform="uppercase" minWidth={20}>
         {`MEM limit: ${
          rowData.site_mem_limit ? formatMemVisual(rowData.site_mem_limit) : "not selected"
         }`}
        </Typography>
       </Stack>
       <Stack direction="row" alignItems="center">
        <Typography variant="body1" fontSize={12} textTransform="uppercase" minWidth={20}>
         {`CPU core limit: ${rowData.site_cpu_limit || "not selected"}`}
        </Typography>
       </Stack>
       <Stack direction="row" alignItems="center">
        <Typography variant="body1" fontSize={12} textTransform="uppercase" minWidth={20}>
         {`NET I/O: ${netIo}`}
        </Typography>
       </Stack>
       <Stack direction="row" alignItems="center">
        <Typography variant="body1" fontSize={12} textTransform="uppercase" minWidth={20}>
         {`BLOCK I/O: ${blockIo}`}
        </Typography>
       </Stack>
      </Stack>
      <Stack>
       <Typography fontWeight="bold">
        <FormattedMessage id="docker.website.siteOperations" />
       </Typography>
       <Stack direction="row" spacing={1}>
        <RebootSite id={rowData.id} type="site" />
        <PowerOffSite id={rowData.id} type="site" />
        <EditSiteTuning
         id={rowData.id}
         type="site"
         defaultMem={formatMemLimit(rowData.site_mem_limit)}
         defaultCpu={rowData.site_cpu_limit}
        />
        <InstallModule
         id={rowData.id}
         installed_modules={rowData.installed_modules || []}
         version={rowData.version}
        />
        <RunCustomScript id={rowData.id} />
        <ResetPrivileges id={rowData.id} />
        {cloudbucket_connected && (
         <Stack direction="row">
          <ConnectCloudbucketFolder
           id={rowData.id}
           folders={rowData.bucket_folder || []}
           available={rowData.available_folder || []}
          />
          {rowData.bucket_folder.length > 0 && (
           <DisconnectCloudbucketFolder id={rowData.id} folders={rowData.bucket_folder} />
          )}
         </Stack>
        )}
       </Stack>
      </Stack>
     </Stack>
    </Stack>
   </Grid>
   <Grid xs={6}>
    <Stack spacing={2}>
     <Typography fontWeight="bold">
      <FormattedMessage id="docker.website.databaseStatistics" />
      {` db_${rowData.site_name}`}
     </Typography>
     {hasDb ? (
      rowData.db_status === "online" ? (
       <Stack spacing={2}>
        <Stack>
         <Stack direction="row" alignItems="center">
          <Typography variant="body1" fontSize={12} textTransform="uppercase" minWidth={70}>
           {`${intl.formatMessage({ id: "server.cpu" })} ${cpu.toFixed(0)}%`}
          </Typography>
          <LinearProgress
           sx={{
            width: "100%",
            ml: 2,
            backgroundColor: "#F1F2F7",
            "& .MuiLinearProgress-bar": {
             backgroundColor: cpu < 50 ? "#41C58C" : cpu < 75 ? "#FF9800" : "#F44336"
            },
            height: 10,
            borderRadius: 0.8
           }}
           variant="determinate"
           value={cpu > 100 ? 100 : cpu}
          />
         </Stack>
         <Stack direction="row" alignItems="center">
          <Typography variant="body1" fontSize={12} textTransform="uppercase" minWidth={70}>
           {`${intl.formatMessage({ id: "server.ram" })} ${ram.toFixed(0)}%`}
          </Typography>
          <LinearProgress
           sx={{
            width: "100%",
            ml: 2,
            backgroundColor: "#F1F2F7",
            "& .MuiLinearProgress-bar": {
             backgroundColor: ram < 50 ? "#41C58C" : ram < 75 ? "#FF9800" : "#F44336"
            },
            height: 10,
            borderRadius: 0.8
           }}
           variant="determinate"
           value={ram > 100 ? 100 : ram}
          />
         </Stack>
         <Stack direction="row" alignItems="center">
          <Typography variant="body1" fontSize={12} textTransform="uppercase" minWidth={20}>
           {`MEM limit: ${
            rowData.db_mem_limit ? formatMemVisual(rowData.db_mem_limit) : "not selected"
           }`}
          </Typography>
         </Stack>
         <Stack direction="row" alignItems="center">
          <Typography variant="body1" fontSize={12} textTransform="uppercase" minWidth={20}>
           {`CPU core limit: ${rowData.db_cpu_limit || "not selected"}`}
          </Typography>
         </Stack>
         <Stack direction="row" alignItems="center">
          <Typography variant="body1" fontSize={12} textTransform="uppercase" minWidth={20}>
           {`NET I/O: ${netIoDb}`}
          </Typography>
         </Stack>
         <Stack direction="row" alignItems="center">
          <Typography variant="body1" fontSize={12} textTransform="uppercase" minWidth={20}>
           {`BLOCK I/O: ${blockIoDb}`}
          </Typography>
         </Stack>
        </Stack>
        <Stack>
         <Typography fontWeight="bold">
          <FormattedMessage id="docker.website.dbOperations" />
         </Typography>
         <Stack direction="row" spacing={1}>
          <RebootSite id={rowData.id} type="db" />
          <PowerOffSite id={rowData.id} type="db" />
          <EditSiteTuning
           id={rowData.id}
           type="db"
           defaultMem={formatMemLimit(rowData.db_mem_limit)}
           defaultCpu={rowData.db_cpu_limit}
          />
          <RepairDatabaseTable id={rowData.id} logs={rowData.repair_log || ""} />
         </Stack>
        </Stack>
       </Stack>
      ) : rowData.db_status === "restarting" ? (
       <Stack direction="row" spacing={1}>
        <CircularProgress />
        <Typography>
         <FormattedMessage id="docker.website.dbRestarting" />
        </Typography>
       </Stack>
      ) : (
       <Stack spacing={2}>
        <Alert severity="warning">
         <Typography>
          <FormattedMessage id="docker.website.dbIsOffline" />
         </Typography>
        </Alert>
        <Stack>
         <Typography fontWeight="bold">
          <FormattedMessage id="docker.website.dbOperations" />
         </Typography>
         <Stack direction="row">
          <PowerOnSite id={rowData.id} type="db" />
          <EditSiteTuning
           id={rowData.id}
           type="db"
           defaultMem={formatMemLimit(rowData.db_mem_limit)}
           defaultCpu={rowData.db_cpu_limit}
          />
         </Stack>
        </Stack>
       </Stack>
      )
     ) : (
      <Stack>
       <Alert severity="warning">
        <Typography>
         <FormattedMessage id="docker.website.noDbStatistics" />
        </Typography>
       </Alert>
      </Stack>
     )}
    </Stack>
   </Grid>
   {rowData.version === "litespeed" && (
    <Grid xs={6}>
     <LitespeedBox
      id={rowData.id}
      password={rowData.litespeed_password || null}
      port={rowData.litespeed_port || 0}
     />
    </Grid>
   )}
  </Grid>
 );
};

export default StatisticSection;
