/* eslint-disable react/style-prop-object */
import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";

import { getServerDetails } from "redux/selectors/serversSelector";

import ContaboUpgrade from "./upgrade/ContaboUpgrade";
import HetznerUpgrade from "./upgrade/HetznerUpgrade";

const ServerUpgrade = (): ReactElement => {
 const server = useSelector(getServerDetails);

 const RenderUpgradeSection = () => {
  switch (server.provider) {
   case "kx":
    return <HetznerUpgrade />;
   case "htx":
    return <HetznerUpgrade />;
   case "ctb":
    return <ContaboUpgrade />;
   default:
    return <></>;
  }
 };

 return (
  <Paper elevation={0} sx={{ mt: 2, borderRadius: "10px", boxShadow: 0 }}>
   <Stack direction="column" p={2}>
    <Stack direction="row" alignItems="center">
     <IconSelector icon="UpgradeIcon" props={{ fontSize: "medium", color: "secondary" }} />
     <Typography fontWeight="bold" fontSize={16} ml={1} textTransform="uppercase">
      <FormattedMessage id="server.upgrade" />
     </Typography>
    </Stack>
    <RenderUpgradeSection />
   </Stack>
  </Paper>
 );
};

export default ServerUpgrade;
