import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { IconButton, useMediaQuery } from "@mui/material";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { postResetAuthinfo } from "redux/handlers/domainsHandler";

import { getDomainsList } from "redux/selectors/domainsSelector";

import { useAppDispatch } from "hooks/reduxHook";

const ResetAuthinfo = (): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();
 const theme = useTheme();
 const phoneView = useMediaQuery(theme.breakpoints.up("sm"));

 const domainData = useSelector(getDomainsList)[0];

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postResetAuthinfo(domainData.id));
  setLoading(false);
 };

 return (
  <>
   {!phoneView ? (
    <IconButton onClick={handleOpen} color="kxBlue">
     <IconSelector icon="AddIcon" />
    </IconButton>
   ) : (
    <Button onClick={handleOpen}>
     <FormattedMessage id="domain.generateAuthinfo" />
    </Button>
   )}
   <AppModal
    open={openModal}
    close={handleClose}
    title={intl.formatMessage({ id: "domain.generateAuthinfo" })}
    handleClose={handleClose}
    disabled={loading}
    handleConfirm={handleConfirm}>
    <Typography>
     <FormattedMessage id="domain.generateNewAuthinfo" />
    </Typography>
   </AppModal>
  </>
 );
};

export default ResetAuthinfo;
