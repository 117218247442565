import React, { ReactElement, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { ctbCloudbucketStps, htzCloudbucketStps } from "constants/addons";

import IconSelector from "components/shared/images/IconSelector";
import ProviderImage from "components/shared/images/ProviderImage";

import {
 getAddonsLocations,
 getAddonsProvider,
 getCloudbucketHetznerPrice,
 postCalculateBucketPrice,
 postCheckBucketName
} from "redux/handlers/addonsHandle";

import { useAppDispatch } from "hooks/reduxHook";

import { ILocations, IProviders } from "types/api/serversApiInterface";

import BuyContaboCloudbucket from "./cloudbucketWizardComponents/BuyContaboCloudbucket";
import BuyHetznerCloudbucket from "./cloudbucketWizardComponents/BuyHetznerCloudbucket";
import ContaboCloudbucketCreation from "./cloudbucketWizardComponents/ContaboCloudbucketCreation";
import ContaboCloudbucketRecapBox from "./cloudbucketWizardComponents/ContaboCloudbucketRecapBox";
import HetznerCloudbucketCreation from "./cloudbucketWizardComponents/HetznerCloudbucketCreation";
import HetznerCloudbucketRecapBox from "./cloudbucketWizardComponents/HetznerCloudbucketRecapBox";
import ProviderBox from "./serverWizardComponents/ProviderBox";

const CloudbucketWizard = (): ReactElement => {
 const dispatch = useAppDispatch();
 const intl = useIntl();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));

 const [activeStep, setActiveStep] = useState<number>(0);
 const [nameChecked, setNameChecked] = useState<boolean | null>(null);
 const [stepLoading, setStepLoading] = useState<boolean>(false);
 const [providerList, setProviderList] = useState<Array<IProviders>>([]);
 const [price, setPrice] = useState<number>(0);
 const [open, setOpen] = useState<boolean>(false);

 const [locationList, setLocationList] = useState<Array<ILocations>>([]);

 useEffect(() => {
  (async () => {
   setProviderList(await dispatch(getAddonsProvider("bucket")));
  })();
 }, []);

 const handleOpen = () => setOpen(true);
 const handleClose = () => setOpen(false);

 const handleReset = () => {
  reset();
  setActiveStep(0);
 };

 const checkServerName = async () => {
  setStepLoading(true);
  setNameChecked(await dispatch(postCheckBucketName(watch("name"))));
  setStepLoading(false);
 };

 const { control, formState, reset, watch, setValue } = useForm({
  defaultValues: {
   name: "",
   provider: 0,
   location: 0,
   size: 250,
   autoscaling: false,
   max_size: 25000,
   publicVisibility: false
  }
 });

 const renderBucketChecked = () => {
  if (nameChecked) {
   return (
    <Alert severity="success">
     <FormattedMessage id="addons.cloudbucket.nameAvailable" />
    </Alert>
   );
  } else {
   return (
    <Alert severity="error">
     <FormattedMessage id="addons.cloudbucket.nameNotAvailable" />
    </Alert>
   );
  }
 };

 const getLocationData = async () => {
  setStepLoading(true);
  setLocationList(await dispatch(getAddonsLocations(watch("provider"), "bucket")));
  setStepLoading(false);
 };

 const getBucketPrice = async () => {
  setStepLoading(true);
  setPrice(await dispatch(postCalculateBucketPrice(watch("size") / 1000)));
  setStepLoading(false);
 };

 const getHzBucketPrice = async () => {
  setStepLoading(true);
  setPrice(await dispatch(getCloudbucketHetznerPrice()));
  setStepLoading(false);
 };

 const handleNext = async () => {
  if (activeStep === 1) getLocationData();
  if (activeStep === 3 && watch("provider") === 2) getBucketPrice();
  if (activeStep === 2 && watch("provider") === 1) getHzBucketPrice();
  setActiveStep((prevActiveStep) => prevActiveStep + 1);
 };

 const handleBack = () => {
  setActiveStep((prevActiveStep) => prevActiveStep - 1);
 };

 const RenderStepInside = () => {
  switch (watch("provider")) {
   case 1:
    return (
     <>
      {htzCloudbucketStps.map((step, index) => {
       return (
        <Step index={index + 2} key={step.label}>
         <StepLabel>
          <FormattedMessage id={step.label} />
         </StepLabel>
         <RenderCreationSections description={step.description} index={index} />
        </Step>
       );
      })}
     </>
    );
   case 2:
    return (
     <>
      {ctbCloudbucketStps.map((step, index) => {
       return (
        <Step index={index + 2} key={step.label}>
         <StepLabel>
          <FormattedMessage id={step.label} />
         </StepLabel>
         <RenderCreationSections description={step.description} index={index} />
        </Step>
       );
      })}
     </>
    );
   case 3:
    return (
     <Stack>
      <Typography fontStyle="italic">Work in progress</Typography>
     </Stack>
    );
   case 4:
    return (
     <Stack>
      <Typography fontStyle="italic">Work in progress</Typography>
     </Stack>
    );
   case 5:
    return (
     <Stack>
      <Typography fontStyle="italic">Work in progress</Typography>
     </Stack>
    );
   case 6:
    return (
     <Stack>
      <Typography fontStyle="italic">Work in progress</Typography>
     </Stack>
    );
   default:
    return <></>;
  }
 };

 const RenderCreationSections = ({
  description,
  index
 }: {
  description: string;
  index: number;
 }): ReactElement => {
  switch (watch("provider")) {
   case 1:
    return (
     <HetznerCloudbucketCreation
      stepLoading={stepLoading}
      description={description}
      index={index}
      locationList={locationList}
      location={watch("location")}
      publicVisibility={watch("publicVisibility")}
      nextStep={handleNext}
      previousStep={handleBack}
      resetData={handleReset}
      handleOpen={handleOpen}
      setData={(section, value) => setReceivedData(section, value)}
     />
    );
   case 2:
    return (
     <ContaboCloudbucketCreation
      stepLoading={stepLoading}
      description={description}
      index={index}
      locationList={locationList}
      location={watch("location")}
      size={watch("size")}
      autoscaling={watch("autoscaling")}
      max_size={watch("max_size")}
      nextStep={handleNext}
      previousStep={handleBack}
      resetData={handleReset}
      handleOpen={handleOpen}
      setData={(section, value) => setReceivedData(section, value)}
     />
    );
   default:
    return <></>;
  }
 };

 const setReceivedData = (section: string, value: string | number | boolean | ILocations) => {
  switch (section) {
   case "location":
    setValue("location", value as number);
    break;
   case "size":
    setValue("size", value as number);
    break;
   case "autoscaling":
    setValue("autoscaling", value as boolean);
    break;
   case "publicVisibility":
    setValue("publicVisibility", value as boolean);
    break;
   default:
    break;
  }
 };

 const RenderRecapBox = (): ReactElement => {
  switch (watch("provider")) {
   case 1:
    return (
     <HetznerCloudbucketRecapBox
      locationData={locationList.find((element) => element.id === watch("location")) || null}
      publicVisibility={watch("publicVisibility")}
      price={price}
     />
    );
   case 2:
    return (
     <ContaboCloudbucketRecapBox
      locationData={locationList.find((element) => element.id === watch("location")) || null}
      size={watch("size")}
      price={price}
      autoscaling={watch("autoscaling")}
     />
    );
   default:
    return <></>;
  }
 };

 const RenderBuyModal = (): ReactElement => {
  switch (watch("provider")) {
   case 1:
    return (
     <BuyHetznerCloudbucket
      open={open}
      dataToSend={{
       name: watch("name"),
       location: watch("location"),
       provider: watch("provider"),
       publicVisibility: watch("publicVisibility"),
       price: price
      }}
      handleClose={handleClose}
      handleReset={handleReset}
     />
    );
   case 2:
    return (
     <BuyContaboCloudbucket
      open={open}
      dataToSend={{
       name: watch("name"),
       location: watch("location"),
       provider: watch("provider"),
       max_space: watch("max_size"),
       size: watch("size"),
       price: price,
       autoScaling: watch("autoscaling")
      }}
      handleClose={handleClose}
      handleReset={handleReset}
     />
    );
   default:
    return <></>;
  }
 };

 return (
  <Stack spacing={2}>
   <Stack p={4} alignItems="center">
    <IconSelector icon="Inventory2Icon" props={{ fontSize: "large" }} />
    <Typography variant="h5">
     <FormattedMessage id="addons.cloudbucket.newCloudbucket" />
    </Typography>
   </Stack>
   <Stack direction={desktopViewPort ? "row" : "column"} justifyContent="center">
    <Box
     sx={{ maxWidth: desktopViewPort ? 1200 : "auto", minWidth: desktopViewPort ? 500 : "auto" }}
     m={10}>
     <Stepper activeStep={activeStep} orientation="vertical">
      <Step>
       <StepLabel>
        <FormattedMessage id="addons.cloudbucket.selectName" />
       </StepLabel>
       {!stepLoading ? (
        <StepContent>
         <Stack spacing={2}>
          <Alert severity="info">
           <Typography>
            <FormattedMessage id="addons.cloudbucket.nameDescription" />
           </Typography>
          </Alert>
          <Controller
           name="name"
           control={control}
           rules={{
            required: true
           }}
           render={({ field }) => (
            <TextField
             {...field}
             fullWidth={true}
             onChange={(event) => {
              setValue("name", event.target.value);
              setNameChecked(null);
             }}
             label={intl.formatMessage({ id: "addons.cloudbucket.name" })}
             error={formState.isDirty && !!formState?.errors?.name}
             InputLabelProps={{ shrink: true }}
             sx={{ my: 2 }}
             autoComplete="on"
            />
           )}
          />
          {nameChecked !== null && renderBucketChecked()}
         </Stack>
         <Box sx={{ mb: 2 }}>
          <Button
           disabled={watch("name").length === 0 || nameChecked === false}
           variant="contained"
           onClick={() => (nameChecked === null ? checkServerName() : handleNext())}
           sx={{ mt: 1, mr: 1 }}>
           {nameChecked === null ? (
            <FormattedMessage id="addons.cloudbucket.checkName" />
           ) : (
            <FormattedMessage id="app.wizard.continue" />
           )}
          </Button>
          <Button disabled onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
           <FormattedMessage id="app.wizard.back" />
          </Button>
         </Box>
        </StepContent>
       ) : (
        <StepContent>
         <Stack spacing={2}>
          <Alert severity="info">
           <Typography>
            <FormattedMessage id="server.checkExecution" />
           </Typography>
          </Alert>
          <CircularProgress />
         </Stack>
        </StepContent>
       )}
      </Step>
      <Step>
       <StepLabel>
        <FormattedMessage id="addons.cloudbucket.selectProvider" />
       </StepLabel>
       {!stepLoading ? (
        <StepContent>
         <Stack spacing={2}>
          <Alert severity="info">
           <Typography>
            <FormattedMessage id="addons.cloudbucket.selectProviderMessage" />
           </Typography>
          </Alert>
          <ProviderBox
           providers={providerList}
           selectProvider={(id) => setValue("provider", id)}
           selectedProvider={watch("provider")}
           showContinuity={false}
          />
         </Stack>
         <Box sx={{ mb: 2 }}>
          <Button
           disabled={watch("provider") >= 3 || watch("provider") === 0}
           variant="contained"
           onClick={handleNext}
           sx={{ mt: 1, mr: 1 }}>
           <FormattedMessage id="app.wizard.continue" />
          </Button>
          <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
           <FormattedMessage id="app.wizard.back" />
          </Button>
         </Box>
        </StepContent>
       ) : (
        <StepContent>
         <Stack spacing={2}>
          <Alert severity="info">
           <Typography>
            <FormattedMessage id="server.checkExecution" />
           </Typography>
          </Alert>
          <CircularProgress />
         </Stack>
        </StepContent>
       )}
      </Step>
      <RenderStepInside />
     </Stepper>
    </Box>
    <Paper
     elevation={0}
     sx={{
      m: 5,
      p: 2,
      maxHeight: 600,
      position: "sticky",
      top: 30,
      minWidth: desktopViewPort ? 400 : "auto",
      borderRadius: "10px",
      boxShadow: 0
     }}>
     <Stack mb={2}>
      <Typography variant="h5" fontWeight="bold">
       <FormattedMessage id="addons.cloudbucket.recap" />
      </Typography>
     </Stack>
     <Divider textAlign="left">
      <Typography variant="overline">
       <FormattedMessage id="addons.cloudbucket.bucketData" />
      </Typography>
     </Divider>
     <Stack direction="row" spacing={2}>
      <Typography minWidth={150} textAlign="start" fontWeight="bold">
       <FormattedMessage id="addons.cloudbucket.name" />:
      </Typography>
      <Typography noWrap>{watch("name")}</Typography>
     </Stack>
     <Stack direction="row" spacing={2}>
      <Typography minWidth={150} textAlign="start" fontWeight="bold">
       <FormattedMessage id="addons.cloudbucket.provider" />:
      </Typography>
      <Stack direction="row" spacing={1} alignItems="center">
       <ProviderImage
        provider={providerList.find((element) => element.id === watch("provider"))?.name || ""}
       />
       <Typography>
        {providerList.find((element) => element.id === watch("provider"))?.name || ""}
       </Typography>
      </Stack>
     </Stack>
     <RenderRecapBox />
    </Paper>
   </Stack>
   <RenderBuyModal />
  </Stack>
 );
};

export default CloudbucketWizard;
