// One time slot every 30 minutes.
export const timeSlots = Array.from(new Array(24 * 2)).map(
 (_, index) => `${index < 20 ? "0" : ""}${Math.floor(index / 2)}:${index % 2 === 0 ? "00" : "30"}`
);

export const weekDays = [
 {
  label: "lunedì",
  key: "1"
 },
 {
  label: "martedì",
  key: "2"
 },
 {
  label: "mercoledì",
  key: "3"
 },
 {
  label: "giovedì",
  key: "4"
 },
 {
  label: "venedì",
  key: "5"
 },
 {
  label: "sabato",
  key: "6"
 },
 {
  label: "domenica",
  key: "7"
 }
];

export const volumesSteps = [
 {
  label: "volumes.add.selectName",
  description: "volumes.add.selectNameMessage"
 },
 {
  label: "volumes.add.datacenter",
  description: "volumes.add.datacenterMessage"
 },
 {
  label: "volumes.add.format",
  description: "volumes.add.formatMessage"
 },
 {
  label: "volumes.add.size",
  description: "volumes.add.sizeMessage"
 },
 {
  label: "volumes.add.server",
  description: "volumes.add.serverMessage"
 }
];

export const floatingIpSteps = [
 {
  label: "addons.floatingip.add.selectName",
  description: "addons.floatingip.add.selectNameMessage"
 },
 {
  label: "addons.floatingip.add.datacenter",
  description: "addons.floatingip.add.datacenterMessage"
 },
 {
  label: "addons.floatingip.add.type",
  description: "addons.floatingip.add.typeMessage"
 },
 {
  label: "addons.floatingip.add.server",
  description: "addons.floatingip.add.serverMessage"
 }
];

export const cloudboxSteps = [
 {
  label: "addons.cloudbox.add.selectName",
  description: "addons.cloudbox.add.selectNameMessage"
 },
 {
  label: "addons.cloudbox.add.datacenter",
  description: "addons.cloudbox.add.datacenterMessage"
 },
 {
  label: "addons.cloudbox.add.type",
  description: "addons.cloudbox.add.typeMessage"
 }
];

export const snapshotSteps = [
 {
  label: "server.add.fromSnapshot",
  description: "server.add.fromSnapshotMessage"
 },
 {
  label: "server.add.name",
  description: "server.add.nameDescription"
 }
];

export const networkSteps = [
 {
  label: "addons.network.name",
  description: "addons.network.nameMessage"
 },
 {
  label: "addons.network.ipRange",
  description: "addons.network.ipRangeDescription"
 }
];

export const loadBalancerSteps = [
 {
  label: "addons.loadBalancer.name",
  description: "addons.loadBalancer.nameMessage"
 },
 {
  label: "addons.loadBalancer.algorithm",
  description: "addons.loadBalancer.algorithmDescription"
 },
 {
  label: "addons.loadBalancer.type",
  description: "addons.loadBalancer.typeDescription"
 }
];

export const firewallSteps = [
 {
  label: "addons.firewall.description",
  description: "addons.firewall.descriptionMessage"
 },
 {
  label: "addons.firewall.name",
  description: "addons.firewall.nameDescription"
 }
];

export const firewallRuleProtocols = ["TCP", "UDP", "ICMP", "ESP", "GRE"];

export const cloudboxDetailsElements = [
 {
  section: "name",
  text: "addons.cloudbox.name"
 },
 {
  section: "password",
  text: "addons.cloudbox.password"
 },
 {
  section: "login",
  text: "addons.cloudbox.login"
 },
 {
  section: "server",
  text: "addons.cloudbox.server"
 }
];

export const cloudboxSettingsElements = [
 {
  section: "samba",
  text: "addons.cloudbox.samba"
 },
 {
  section: "webdav",
  text: "addons.cloudbox.webdav"
 },
 {
  section: "ssh",
  text: "addons.cloudbox.ssh"
 },
 {
  section: "external",
  text: "addons.cloudbox.external"
 }
];

export const ctbCloudbucketStps = [
 {
  label: "addons.cloudbucket.selectBucketLocation",
  description: "addons.cloudbucket.selectBucketLocationMessage"
 },
 {
  label: "addons.cloudbucket.selectSize",
  description: "addons.cloudbucket.selectSizeMessage"
 },
 {
  label: "addons.cloudbucket.selectAutoscaling",
  description: "addons.cloudbucket.selectAutoscalingMessage"
 }
];

export const htzCloudbucketStps = [
 {
  label: "addons.cloudbucket.selectBucketLocation",
  description: "addons.cloudbucket.selectBucketLocationMessage"
 },
 {
  label: "addons.cloudbucket.selectPublicVisibility",
  description: "addons.cloudbucket.selectPublicVisibilityMessage"
 }
];

export const addonsList = [
 {
  name: "addons.cloudbox",
  link: "/addons/cloudbox",
  icon: "CloudboxIcon",
  add: "/addons/cloudbox/add",
  internElement: "addons.cloudbox.totalCloudbox",
  sectionMessage: "addons.cloudbox.description",
  section: "cloudbox",
  addTitle: "addons.cloudbox.addCloudbox"
 },
 {
  name: "addons.cloudbucket",
  link: "/addons/cloudbucket",
  icon: "Inventory2Icon",
  add: "/addons/cloudbucket/add",
  internElement: "addons.cloudbucket.totalStorage",
  sectionMessage: "addons.cloudbucket.description",
  section: "cloudbucket",
  addTitle: "addons.cloudbucket.addCloudbucket"
 },
 {
  name: "addons.floatingIp",
  link: "/addons/floatingip",
  icon: "SettingsInputAntennaIcon",
  add: "/addons/floatingip/add",
  internElement: "addons.floatingIp.totalFloatingIp",
  sectionMessage: "addons.floatingIp.descriptionInfo",
  section: "floatingIp",
  addTitle: "addons.floatingIp.addFloatingIp"
 },
 {
  name: "addons.network",
  link: "/addons/network",
  icon: "NetworkIcon",
  add: "/addons/network/add",
  internElement: "addons.network.totalNetwork",
  sectionMessage: "addons.network.descriptionInfo",
  section: "network",
  addTitle: "addons.network.addNetwork"
 },
 {
  name: "addons.snapshots",
  link: "/addons/snapshots",
  icon: "SnapshotIcon",
  add: "/addons/snapshot/add",
  internElement: "addons.snapshot.totalSnapshot",
  sectionMessage: "addons.snapshot.info",
  section: "snapshot",
  addTitle: "addons.snapshot.addSnapshot"
 },
 {
  name: "addons.volumes",
  link: "/addons/volumes",
  icon: "VolumeIcon",
  add: "/addons/volumes/add",
  internElement: "addons.volumes.totalVolumes",
  sectionMessage: "addons.volumes.info",
  section: "volumes",
  addTitle: "addons.volumes.addVolume"
 },
 {
  name: "addons.firewall",
  link: "/addons/firewall",
  icon: "FirewallIcon",
  add: "/addons/firewall/add",
  internElement: "addons.firewall.totalFirewalls",
  sectionMessage: "addons.firewall.info",
  section: "firewall",
  addTitle: "addons.firewall.addFirewall"
 },
 {
  name: "addons.loadBalancer",
  link: "/addons/loadBalancer",
  icon: "BalanceIcon",
  add: "/addons/loadBalancer/add",
  internElement: "addons.loadBalancer.totalBalancer",
  sectionMessage: "addons.loadBalancer.description",
  section: "loadBalancer",
  addTitle: "addons.loadBalancer.addBalancer"
 }
];
