import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "redux/store";

import { IWebsitesDataset } from "types/api/websitesApiInterface";

export const sitesApi = (state: AppState) => state.websites;

export const sitesDetaislApi = (state: AppState) => state.websitesDetails;

export const getWebsitesNumber = createSelector(sitesApi, (websites) => {
 return websites?.length || 0;
});

export const getWebsitesList = createSelector(sitesApi, (websites) => {
 return (
  websites?.sites.map((sites: IWebsitesDataset) => {
   return {
    id: sites.id,
    active: sites.active,
    domain_id: sites.domain_id,
    php: sites.php,
    php_version: sites.php_version,
    site: sites.site,
    isp_id: sites.isp_id,
    server_id: sites.server_id,
    type: sites.type
   };
  }) || []
 );
});

export const getWebsitesDetails = createSelector(sitesDetaislApi, (sites) => {
 return {
  active: sites?.details?.active || "",
  apache_directives: sites?.details?.apache_directives || "",
  nginx_directives: sites?.details?.nginx_directives || "",
  custom_php_ini: sites?.details?.custom_php_ini || "",
  domain_id: sites?.details?.domain_id || 0,
  hd_quota: sites?.details?.hd_quota || 0,
  php: sites?.details?.php || "",
  php_version: sites?.details?.php_version || "",
  rewrite_to_https: sites?.details?.rewrite_to_https || "",
  site: sites?.details?.site || "",
  ssl: sites?.details?.ssl || "",
  ssl_letsencrypt: sites?.details?.ssl_letsencrypt || "",
  traffic_quota: sites?.details?.traffic_quota || 0,
  isp_id: sites?.details?.isp_id || 0,
  ftp: sites?.details?.ftp || [],
  db: sites?.details?.db || [],
  alias: sites?.details?.alias || [],
  cronjob: sites?.details?.cronjob || [],
  backup: sites?.details?.backup || [],
  backup_copies: sites?.details?.backup_copies,
  backup_interval: sites?.details?.backup_interval,
  backup_restore: sites?.details?.backup_restore,
  ssl_cert: sites?.details?.ssl_cert,
  ssl_key: sites?.details?.ssl_key,
  ssl_request: sites?.details?.ssl_request,
  id: sites?.details?.id
 };
});
