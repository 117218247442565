export const openUrl = [
 "/auth/login",
 "/auth/register",
 "/user/forgetpwd",
 "/user/resetpwd",
 "/users/register",
 "/users/resetpass",
 "/users/reset",
 "/verify",
 "/auth/verify-2fa",
 "/auth/recovery"
];
