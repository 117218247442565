import { IAppsInfo } from "types/api/appsApiInterface";

export const formatSizePercent = (size: number, totalSize: number) => {
 return Math.floor((size * 100) / totalSize);
};

export const formatDateColor = (status: string): string => {
 switch (status) {
  case "expiring":
   return "warning";
  case "expired":
   return "error";
  default:
   return "";
 }
};

export const filterAppsInfo = (appsInfo: IAppsInfo, section: string) => {
 switch (section) {
  case "website":
   return appsInfo.site;
  case "maildomain":
   return appsInfo.mail;
  case "zone":
   return appsInfo.zone;
  default:
   return null;
 }
};
