import React, { useRef, useState } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { useSelector } from "react-redux";
import useDebounce from "react-use/lib/useDebounce";
import { handleChangePageNumber } from "helpers/pagination";

import SearchIcon from "@mui/icons-material/Search";

import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import TablePagination from "@mui/material/TablePagination";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { hostsTable } from "constants/tables";

import AddHost from "components/profile/hosts/actions/AddHost";
import SortingTable from "components/shared/tables/SortingTable";

import { getAllHost } from "redux/handlers/hostHandler";

import { getHostDataSet, getHostNumber } from "redux/selectors/hostSelector";

import { useAppDispatch } from "hooks/reduxHook";

const Host = ({ isLoading }: { isLoading: boolean }) => {
 const searchRef = useRef<HTMLInputElement>(null);
 const dispatch = useAppDispatch();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));

 const hostList = useSelector(getHostDataSet);
 const hostNumber = useSelector(getHostNumber);

 const [currentPage, setCurrentPage] = useState<number>(0);
 const [rowPerPage, setRowPerPage] = useState<number>(10);
 const [searchValue, setSearchValue] = useState<string>("");

 useDebounce(
  () => {
   if (!isLoading) {
    setCurrentPage(0);
    dispatch(getAllHost(currentPage, rowPerPage, searchRef?.current?.value));
   }
  },
  1000,
  [searchValue]
 );

 const handleOnRowsPerPageChange = (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
 ) => {
  const data = handleChangePageNumber(parseInt(event.target.value, 10), currentPage, rowPerPage);
  setRowPerPage(data.newRow);
  setCurrentPage(Number(data.newPage.toFixed(0)));

  dispatch(
   getAllHost(
    Number(data.newPage.toFixed(0)),
    parseInt(event.target.value, 10),
    searchRef?.current?.value
   )
  );
 };

 const handleOnPageChange = (
  event: React.MouseEvent<HTMLButtonElement> | null,
  newPage: number
 ) => {
  setCurrentPage(newPage);
  dispatch(getAllHost(newPage, rowPerPage, searchRef?.current?.value));
 };

 return (
  <Stack>
   <Paper elevation={0} sx={{ borderRadius: "10px", boxShadow: 0 }}>
    <Stack
     direction={desktopViewPort ? "row" : "column"}
     spacing={2}
     justifyContent="space-between"
     alignItems="center">
     <Stack justifyContent="flex-start" alignItems="center" p={2} direction="row">
      <Typography variant="subtitle2">
       <FormattedNumber value={hostNumber || 0} /> <FormattedMessage id="app.totalHost" />
      </Typography>
      <TextField
       size="small"
       autoComplete="off"
       inputRef={searchRef}
       label={<FormattedMessage id="search.host" />}
       sx={{ ml: 4 }}
       value={searchValue}
       InputLabelProps={{ shrink: true }}
       InputProps={{ startAdornment: <SearchIcon color="disabled" /> }}
       onChange={({ currentTarget }) => setSearchValue(currentTarget.value)}
      />
     </Stack>
     <AddHost />
    </Stack>
    <Divider sx={{ borderColor: "#f86e06" }} />
    <Stack>
     <SortingTable tableData={hostsTable} maxWidth={650} caller="hosts" listToShow={hostList} />
     <TablePagination
      component="div"
      count={hostNumber || 0}
      page={currentPage}
      rowsPerPage={rowPerPage}
      onPageChange={handleOnPageChange}
      onRowsPerPageChange={handleOnRowsPerPageChange}
     />
    </Stack>
   </Paper>
  </Stack>
 );
};

export default Host;
