import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import AppModal from "components/shared/modal/AppModal";

import { postCompleteCTPUpgradeOrder } from "redux/handlers/serverHandler";

import { useAppDispatch } from "hooks/reduxHook";

const ConfirmContaboUpgrade = ({ id }: { id: number }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => setOpen(true);
 const handleClose = () => setOpen(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postCompleteCTPUpgradeOrder(id));
  setLoading(false);
  handleClose();
 };

 return (
  <>
   <Button onClick={handleOpen}>
    <FormattedMessage id="server.completeUpgrade" />
   </Button>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "server.completeUpgrade" })}
    handleClose={handleClose}
    disabled={loading}
    handleConfirm={handleConfirm}>
    <Typography>
     <FormattedMessage id="server.completeUpgradeMessage" />
    </Typography>
   </AppModal>
  </>
 );
};

export default ConfirmContaboUpgrade;
