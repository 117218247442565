import React, { ReactElement } from "react";
import { useSelector } from "react-redux";

import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import AppChat from "components/chat/AppChat";
import AppFooter from "components/footer/AppFooter";
import AcceptContract from "components/shared/acceptContract/AcceptContract";

import { getUserAuthenticated, getUserContract } from "redux/selectors/userSelector";

import AppHeader from "../header/AppHeader";

type Props = {
 children: ReactElement;
};
const AppContainer = ({ children }: Props): ReactElement => {
 const contract = useSelector(getUserContract);
 const isAuthenticated: boolean = useSelector(getUserAuthenticated);
 const url = window.location.href;
 const theme = useTheme();
 const phoneView = useMediaQuery(theme.breakpoints.up("sm"));

 return (
  <Stack
   height={"100vh"}
   sx={{
    justifyContent: "space-between",
    overflow: isAuthenticated && !contract ? "hidden" : "auto"
   }}>
   <Stack>
    {isAuthenticated && !url.includes("payment") && <AppHeader />}
    {children}
   </Stack>
   {phoneView && <AppChat />}
   {isAuthenticated && !contract && <AcceptContract />}
   {!url.includes("payment") && <AppFooter />}
  </Stack>
 );
};

export default AppContainer;
