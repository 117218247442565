import React, { ReactElement, useState } from "react";
import { FormattedMessage } from "react-intl";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import StepContent from "@mui/material/StepContent";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";

import { htzCloudbucketStps } from "constants/addons";

import { ILocations } from "types/api/serversApiInterface";

import CountryBox from "../serverWizardComponents/CountryBox";

const HetznerCloudbucketCreation = ({
 stepLoading,
 description,
 index,
 locationList,
 location,
 publicVisibility,
 nextStep,
 previousStep,
 resetData,
 handleOpen,
 setData
}: {
 stepLoading: boolean;
 description: string;
 index: number;
 locationList: Array<ILocations>;
 location: number;
 publicVisibility: boolean;
 nextStep: () => void;
 previousStep: () => void;
 resetData: () => void;
 handleOpen: () => void;
 setData: (section: string, value: string | number | boolean | ILocations) => void;
}): ReactElement => {
 const [vPublic, setVPublic] = useState<boolean>(publicVisibility);

 const handleNext = () => {
  nextStep();
 };

 const handleBack = () => {
  previousStep();
 };

 const handleOpenModal = () => {
  setData("publicVisibility", vPublic);
  handleOpen();
 };

 const renderStepContent = (index: number) => {
  switch (index) {
   case 0:
    return (
     <CountryBox
      locationList={locationList}
      selectLocation={(location) => {
       setData("location", location);
      }}
      selectedLocation={location}
     />
    );
   case 1:
    return (
     <Stack spacing={2}>
      <Stack justifyContent="center" alignContent="center" direction="column">
       <FormControlLabel
        control={<Switch checked={vPublic} onChange={(e) => setVPublic(e.target.checked)} />}
        label={<FormattedMessage id="addons.cloudbucket.publicVisibility" />}
       />
      </Stack>
     </Stack>
    );
   default:
    return <></>;
  }
 };

 const disableNextButton = (index: number) => {
  switch (index) {
   case 0:
    return location === 0;
   default:
    return false;
  }
 };

 return (
  <>
   {!stepLoading ? (
    <StepContent>
     <Stack spacing={2}>
      <Alert severity="info">
       <Typography textAlign="left">
        <FormattedMessage id={description} />
       </Typography>
      </Alert>
      {renderStepContent(index)}
     </Stack>
     <Box sx={{ mb: 2 }}>
      <div>
       {index !== htzCloudbucketStps.length - 1 ? (
        <Button
         disabled={disableNextButton(index)}
         variant="contained"
         onClick={handleNext}
         sx={{ mt: 1, mr: 1 }}>
         <FormattedMessage id="app.wizard.continue" />
        </Button>
       ) : (
        <></>
       )}
       {index === 1 && (
        <>
         <Button sx={{ mt: 1, mr: 1 }} variant="contained" onClick={handleOpenModal}>
          <FormattedMessage id="app.wizard.confirm" />
         </Button>
         <Button onClick={resetData} sx={{ mt: 1, mr: 1 }}>
          <FormattedMessage id="app.wizard.reset" />
         </Button>
        </>
       )}
       <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
        <FormattedMessage id="app.wizard.back" />
       </Button>
      </div>
     </Box>
    </StepContent>
   ) : (
    <StepContent>
     <Stack spacing={2}>
      <Alert severity="info">
       <Typography>
        <FormattedMessage id="server.checkExecution" />
       </Typography>
      </Alert>
      <CircularProgress />
     </Stack>
    </StepContent>
   )}
  </>
 );
};

export default HetznerCloudbucketCreation;
