export const locationList = [
 {
  location: "app.location.falkenstein",
  flag: "de",
  provider: "hetzner",
  tag: "fsn1"
 },
 {
  location: "app.location.nuremberg",
  flag: "de",
  provider: "hetzner",
  tag: "nbg1"
 },
 {
  location: "app.location.helsinki",
  flag: "fi",
  provider: "hetzner",
  tag: "hel1"
 },
 {
  location: "app.location.ashburn",
  flag: "us",
  provider: "hetzner",
  tag: "ash"
 },
 {
  location: "app.location.hillsboro",
  flag: "us",
  provider: "hetzner",
  tag: "hill"
 },
 {
  location: "app.location.europe",
  flag: "eu",
  provider: "contabo",
  tag: "eu"
 }
];
