import React, { ReactElement } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import Chip from "@mui/material/Chip";
//import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
//import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
//import Box from "@mui/material/Box";
//import Collapse from "@mui/material/Collapse";
//import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
//import Table from "@mui/material/Table";
//import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
//import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { ListOfServices } from "constants/services";

import { getAllServices, getServerDetails } from "redux/selectors/serversSelector";

import { IServerService } from "types/api/serversApiInterface";

import ConfigPhp from "./servicesActions/config/ConfigPhp";
import ServiceLogs from "./servicesActions/logs/ServiceLogs";
import ServicePermit from "./servicesActions/permit/ServicePermit";
import RebootService from "./servicesActions/reboot/RebootService";
import ServiceRectify from "./servicesActions/repair/ServiceRectify";
import ServiceRepair from "./servicesActions/repair/ServiceRepair";
import ServiceConfig from "./servicesActions/ServiceConfig";
import ServiceCpu from "./servicesActions/ServiceCpu";

const ServiceTableData = ({
 service,
 name,
 sqlLogs
}: {
 service: string;
 name: string;
 sqlLogs: string;
}): ReactElement => {
 const intl = useIntl();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));

 const server = useSelector(getServerDetails);
 const services = useSelector(getAllServices);

 const RenderAction = ({ action }: { action: string }): ReactElement => {
  switch (action) {
   case "permit":
    return <ServicePermit />;
   case "config":
    return <ServiceConfig service={service} />;
   case "phpConfig":
    return <ConfigPhp />;
   case "logs":
    return <ServiceLogs service={service} />;
   case "repair":
    return <ServiceRepair name={service} sqlLogs={sqlLogs} />;
   case "rectify":
    return <ServiceRectify service={service} />;
   default:
    return <RebootService name={service} service={service} id={server.id || 0} />;
  }
 };

 const RenderVersion = (): ReactElement => {
  switch (service) {
   case "apache2":
    return (
     <Typography variant="caption" fontStyle="italic">
      {services?.apache2}
     </Typography>
    );
   case "mysql":
    return (
     <Typography variant="caption" fontStyle="italic">
      {services?.mysql}
     </Typography>
    );
   default:
    return <></>;
  }
 };

 return services &&
  services[service as keyof IServerService] !== "no" &&
  !String(services[service as keyof IServerService]).includes("not installed") ? (
  <>
   <TableRow>
    <TableCell>
     <Stack direction="column" spacing={1}>
      <Typography>{name}</Typography>
      <RenderVersion />
     </Stack>
    </TableCell>
    {desktopViewPort && (
     <TableCell>
      <Chip
       size="small"
       label={intl.formatMessage({
        id:
         services[service as keyof IServerService] === "no"
          ? "server.services.serviceOff"
          : "server.services.serviceOn"
       })}
       color={services[service as keyof IServerService] === "no" ? "error" : "success"}
      />
     </TableCell>
    )}
    {desktopViewPort && (
     <TableCell>
      <ServiceCpu service={service} />
     </TableCell>
    )}
    <TableCell>
     {ListOfServices.find((element) => element.dataToPass === service)?.operation.map(
      (element, index) => {
       return <RenderAction key={`service-action-${index}`} action={element} />;
      }
     )}
    </TableCell>
   </TableRow>
   {/*<RenderPostfixData />*/}
  </>
 ) : (
  <></>
 );
};

export default ServiceTableData;
