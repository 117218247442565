import React, { ReactElement, useContext, useEffect, useState } from "react";
import { FormattedMessage, FormattedTime } from "react-intl";
import { useSelector } from "react-redux";
import { AppContext } from "AppContext";

import { isNil } from "ramda";

import PhonelinkIcon from "@mui/icons-material/Phonelink";
import PhonelinkOffIcon from "@mui/icons-material/PhonelinkOff";

import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import ClickToCopy from "components/shared/clipboardCopy/ClickToCopy";
import IconSelector from "components/shared/images/IconSelector";
import OsImage from "components/shared/images/OsImage";
import ProviderImage from "components/shared/images/ProviderImage";

import { getAgentVersion } from "redux/handlers/serverHandler";
import { postSyncronizeSocket } from "redux/handlers/socketHandler";

import { getServerDetails } from "redux/selectors/serversSelector";

import { useAppDispatch } from "hooks/reduxHook";

import AgentInstaller from "../AgentInstaller";
import AgentReboot from "../AgentReboot";
import AgentUpdate from "../AgentUpdate";

const ServerDetails = (): ReactElement => {
 const dispatch = useAppDispatch();
 const { socket } = useContext(AppContext);
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));
 const phoneView = useMediaQuery(theme.breakpoints.up("sm"));
 const server = useSelector(getServerDetails);

 const [serverTime, setServerTime] = useState<string>("");
 const [agentVersion, setAgentVersion] = useState<number>(0);
 const [serverAgentVersion, setServerAgentVersion] = useState<number>(-1);
 const [reloadData, setReloadData] = useState<boolean>(true);

 useEffect(() => {
  !isNil(socket) &&
   socket.on("server_logs", (value): void => {
    if (value?.length > 0) {
     for (let i = 0; i < value.length; i++) {
      if (server.agentoken === value[i]?.token) {
       setServerTime(value[i]?.tz || "");
       setServerAgentVersion(Number(value[i].agentVersion));
       if (Number(value[i].agentVersion) === 0) setReloadData(true);
      }
     }
    }
   });
  return () => {
   !isNil(socket) && socket.off("server_logs");
  };
 }, [socket, server]);

 useEffect(() => {
  (async () => {
   if (reloadData) {
    setAgentVersion(await dispatch(getAgentVersion()));
    setReloadData(false);
    await dispatch(postSyncronizeSocket("server"));
   }
  })();
 }, [reloadData]);

 return (
  <Stack
   mt={desktopViewPort ? 5 : 1}
   justifyContent="space-between"
   direction={desktopViewPort ? "row" : "column"}>
   <Stack>
    <Stack direction="row" alignItems="center" spacing={1}>
     <Chip
      size="small"
      color={
       server.server_status === "off" || server.server_status === "stopped" ? "default" : "success"
      }
      sx={{ height: "16px" }}
     />
     <OsImage osType={server.os || "debian"} />
     <ProviderImage provider={server?.provider || ""} />
     {!phoneView && server.business_continuity && (
      <Tooltip title="Business Continuity Plan">
       <Stack>
        <IconSelector icon="SyncIcon" props={{ fontSize: "small", color: "success" }} />
       </Stack>
      </Tooltip>
     )}
     {phoneView && (
      <Typography fontWeight="bold" fontSize={16} ml={1} textTransform="uppercase">
       {server.serverName} ({server.server_type})
      </Typography>
     )}
     {phoneView && server.business_continuity && (
      <Tooltip title="Business Continuity Plan">
       <Stack>
        <IconSelector icon="SyncIcon" props={{ fontSize: "small", color: "success" }} />
       </Stack>
      </Tooltip>
     )}
    </Stack>
    {!phoneView && (
     <Stack direction="row" my={1}>
      <Typography fontWeight="bold" fontSize={16} ml={1} textTransform="uppercase">
       {server.serverName} ({server.server_type})
      </Typography>
     </Stack>
    )}
    <Stack py={1} alignItems="center" direction="row">
     <Stack component="span" className={`fi fi-${server.countryCode}`} />
     <Stack direction="row">
      <ClickToCopy text={server.ipAddress || ""} />
      <Typography variant="extraSmall" px={0.5}>
       {server.ipAddress}
      </Typography>
     </Stack>
    </Stack>
   </Stack>
   <Stack alignItems={desktopViewPort ? "flex-end" : "flex-start"}>
    <Typography variant="small">
     <FormattedMessage id="server.datetime" />:{" "}
     {serverTime ? (
      <FormattedTime value={new Date(serverTime)} />
     ) : (
      <FormattedMessage id="server.manage.chargingData" />
     )}
    </Typography>
    {server.agentinstall ? (
     <Stack direction="row" alignItems="center">
      <Typography variant="small" pr={1}>
       <FormattedMessage id="server.agent.connected" />
       {` ${serverAgentVersion !== -1 ? `v${serverAgentVersion}` : ""}`}
      </Typography>
      <PhonelinkIcon
       color={agentVersion !== serverAgentVersion ? "warning" : "success"}
       fontSize="small"
       sx={{ mr: 1 }}
      />
      {server.agentoken ? (
       agentVersion !== 0 &&
       agentVersion !== serverAgentVersion &&
       serverAgentVersion !== -1 &&
       server.os !== "windows" ? (
        <AgentUpdate id={server.id || 0} />
       ) : (
        <AgentReboot id={server.id || 0} />
       )
      ) : (
       <AgentInstaller />
      )}
     </Stack>
    ) : (
     <Stack direction="row" alignItems="center">
      <Typography variant="small" pr={1} sx={{ color: "red" }}>
       <FormattedMessage id="server.agent.notconnected" />
      </Typography>
      <PhonelinkOffIcon color="error" fontSize="small" sx={{ mr: 1 }} />
      {server.agentoken ? (
       agentVersion !== 0 &&
       agentVersion > serverAgentVersion &&
       serverAgentVersion !== -1 &&
       server.os !== "windows" ? (
        <AgentUpdate id={server.id || 0} />
       ) : (
        <AgentReboot id={server.id || 0} />
       )
      ) : (
       <AgentInstaller />
      )}
     </Stack>
    )}
   </Stack>
  </Stack>
 );
};

export default ServerDetails;
