import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "redux/store";

import { ICloudbucketDataset } from "types/api/addonsApiInterface";

export const getCloudbucket = (state: AppState) => state.cloudbuckets;

export const getCloudbucketNumber = createSelector(getCloudbucket, (buckets) => {
 return buckets?.totalCount || 0;
});

export const getCloudbucketDataset = createSelector(getCloudbucket, (buckets) => {
 return buckets?.dataset || [];
});

export const getCloudbucketConfigurator = createSelector(getCloudbucket, (cloudbucket) => {
 return cloudbucket?.dataset.map((cloudbucket: ICloudbucketDataset) => {
  return {
   id: cloudbucket.id,
   name: cloudbucket.name,
   userid: cloudbucket.userid,
   location: cloudbucket.region,
   status: cloudbucket.bucketStatus,
   key: cloudbucket.key,
   secret: cloudbucket.secret,
   s3url: cloudbucket.s3Url
  };
 });
});
