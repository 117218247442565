import React, { ReactElement, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate, useNavigation } from "react-router-dom";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { postLoginWith2FA } from "redux/handlers/userHandler";

import userReducer from "redux/reducers/userReducer";

import { useAppDispatch } from "hooks/reduxHook";

const DoubleAuthentication = (): ReactElement => {
 const intl = useIntl();
 const navigate = useNavigate();
 const navigation = useNavigation();
 const dispatch = useAppDispatch();

 const [loading, setLoading] = useState<boolean>(false);
 const [otpError, setOtpError] = useState<boolean>(false);

 const { control, handleSubmit, formState } = useForm({
  defaultValues: {
   otp: ""
  }
 });

 const onSubmit: SubmitHandler<{ otp: string }> = async (data: { otp: string }) => {
  setLoading(true);
  navigation.state = "loading";
  const isLogged = await dispatch(postLoginWith2FA(data.otp));
  if (!isLogged) {
   setOtpError(true);
   setLoading(false);
  } else {
   setLoading(false);
   setOtpError(false);
   dispatch(userReducer.actions.setDoubleAuthenticated());
   navigate("/domains");
  }
 };

 return (
  <Stack>
   <form
    onSubmit={handleSubmit(onSubmit)}
    autoComplete="off"
    style={{
     display: "flex",
     flexDirection: "column",
     alignItems: "center",
     justifyContent: "center"
    }}>
    <Controller
     name="otp"
     control={control}
     rules={{
      required: true
     }}
     render={({ field }) => (
      <TextField
       {...field}
       fullWidth={true}
       label="OTP"
       error={formState.isDirty && !!formState?.errors?.otp}
       InputLabelProps={{ shrink: true }}
       sx={{ my: 2 }}
       autoComplete="off"
       helperText={
        formState.isDirty &&
        !!formState?.errors?.otp &&
        intl.formatMessage({ id: "login.invalidOTP" })
       }
      />
     )}
    />
    {otpError && !loading && (
     <Typography color="error" mt={2}>
      <FormattedMessage id="login.wrongLogin" />
     </Typography>
    )}
    <Button
     disabled={loading}
     variant="kxActionButton"
     onClick={handleSubmit(onSubmit)}
     sx={{ mt: 2 }}>
     Login
    </Button>
   </form>
  </Stack>
 );
};

export default DoubleAuthentication;
