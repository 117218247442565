import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";

import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import useMediaQuery from "@mui/material/useMediaQuery";

import AppFooter from "components/footer/AppFooter";
import ConfirmedEmail from "components/login/ConfirmedEmail";

const EmailConfirmPage = (): ReactElement => {
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));

 return (
  <Grid container height="100vh">
   <Grid
    xs={desktopViewPort ? 4 : 12}
    display="flex"
    justifyContent={"center"}
    alignItems={"center"}
    height="100%"
    padding={2}>
    <Stack justifyContent={"space-between"} height={"100%"}>
     <Stack spacing={4} justifyContent={"center"} height="90%">
      <Stack spacing={2} alignItems={"center"}>
       <img
        alt="KonsoleX Logo"
        src={`${process.env.PUBLIC_URL}/svg/loghi/Konsolex.png`}
        style={{ marginBottom: "20px", maxWidth: "80%" }}
       />
       <Typography variant="h4">
        <FormattedMessage id="login.registerButton" />
       </Typography>
      </Stack>
      <ConfirmedEmail />
     </Stack>
     <AppFooter />
    </Stack>
   </Grid>
   {desktopViewPort && (
    <Grid
     xs={8}
     sx={{
      backgroundImage: `url(${process.env.PUBLIC_URL}/backgrounds/loginBG.webp)`,
      backgroundPositionY: "center",
      overflow: "hidden",
      height: "100vh",
      backgroundSize: "cover"
     }}></Grid>
   )}
  </Grid>
 );
};

export default EmailConfirmPage;
