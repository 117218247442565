import React, { ReactElement, useState } from "react";
import { FormattedMessage } from "react-intl";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";

import IconSelector from "components/shared/images/IconSelector";

import { IAllServers } from "types/api/serversApiInterface";
import { cloudbucketTableData } from "types/global/table";

import DisconnectCloudbucket from "./actions/DisconnectCloudbucket";
import LinkServer from "./actions/LinkServer";

const CloudbucketActions = ({
 rowData,
 servers
}: {
 rowData: cloudbucketTableData;
 servers: Array<IAllServers>;
}): ReactElement => {
 const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
 const open = Boolean(anchorEl);

 const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
  setAnchorEl(event.currentTarget);
 };

 const handleClose = () => {
  setAnchorEl(null);
 };

 return rowData.folder > 0 ? (
  <Stack direction="row" justifyContent="flex-end" pr={2}>
   <Tooltip title={<FormattedMessage id="addons.cloudbucket.disconnectFolders" />}>
    <Stack>
     <IconSelector icon="InfoIcon" props={{ color: "kxBlue" }} />
    </Stack>
   </Tooltip>
  </Stack>
 ) : (
  <>
   <Button onClick={handleOpen}>
    <IconSelector icon="MenuIcon" />
   </Button>
   <Menu
    id="basic-menu"
    anchorEl={anchorEl}
    open={open}
    onClose={handleClose}
    MenuListProps={{
     "aria-labelledby": "basic-button"
    }}>
    <Stack>
     {rowData.server_id_db ? (
      <DisconnectCloudbucket
       id={rowData.id}
       server_id_db={rowData.server_id_db}
       closeMenu={handleClose}
      />
     ) : (
      <LinkServer id={rowData.id} servers={servers} closeMenu={handleClose} />
     )}
    </Stack>
   </Menu>
  </>
 );
};

export default CloudbucketActions;
