import React, { ReactElement, useState } from "react";
import { FormattedMessage } from "react-intl";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import Slider from "@mui/material/Slider";
import Stack from "@mui/material/Stack";
import StepContent from "@mui/material/StepContent";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { ctbCloudbucketStps } from "constants/addons";

import { ILocations } from "types/api/serversApiInterface";

import CountryBox from "../serverWizardComponents/CountryBox";

const ContaboCloudbucketCreation = ({
 stepLoading,
 description,
 index,
 locationList,
 size,
 location,
 autoscaling,
 max_size,
 nextStep,
 previousStep,
 resetData,
 handleOpen,
 setData
}: {
 stepLoading: boolean;
 description: string;
 index: number;
 locationList: Array<ILocations>;
 size: number;
 location: number;
 autoscaling: boolean;
 max_size: number;
 nextStep: () => void;
 previousStep: () => void;
 resetData: () => void;
 handleOpen: () => void;
 setData: (section: string, value: string | number | boolean | ILocations) => void;
}): ReactElement => {
 const [sliderSize, setSliderSize] = useState<number>(size);
 const [autoscale, setAutoscale] = useState<boolean>(autoscaling);
 const [maxSize, setMaxSize] = useState<number>(max_size);

 const handleNext = () => {
  if (index === 1) setData("size", sliderSize);
  nextStep();
 };

 const handleBack = () => {
  previousStep();
 };

 const handleOpenModal = () => {
  setData("autoscaling", autoscale);
  setData("max_size", Number(maxSize));
  handleOpen();
 };

 const renderStepContent = (index: number) => {
  switch (index) {
   case 0:
    return (
     <CountryBox
      locationList={locationList}
      selectLocation={(location) => {
       setData("location", location);
      }}
      selectedLocation={location}
     />
    );
   case 1:
    return (
     <Stack direction="row" spacing={2} alignItems="center">
      <Slider
       aria-label="Always visible"
       defaultValue={250}
       value={sliderSize}
       onChange={(e, nV) => {
        setSliderSize(nV as number);
       }}
       step={250}
       max={25000}
       min={250}
       valueLabelDisplay="on"
      />
     </Stack>
    );
   case 2:
    return (
     <Stack spacing={2}>
      <Stack justifyContent="center" alignContent="center" direction="column">
       <FormControlLabel
        control={<Switch checked={autoscale} onChange={(e) => setAutoscale(e.target.checked)} />}
        label={<FormattedMessage id="addons.cloudbucket.autoscaling" />}
       />
      </Stack>
      <TextField
       fullWidth={true}
       onChange={(event) => {
        setMaxSize(Number(event.target.value));
       }}
       value={maxSize}
       label={<FormattedMessage id="addons.cloudbucket.maxSize" />}
       InputLabelProps={{ shrink: true }}
       error={maxSize < size}
       type="number"
      />
     </Stack>
    );
   default:
    return <></>;
  }
 };

 const disableNextButton = (index: number) => {
  switch (index) {
   case 0:
    return location === 0;
   case 1:
    return size === 0;
   default:
    return false;
  }
 };

 return (
  <>
   {!stepLoading ? (
    <StepContent>
     <Stack spacing={2}>
      <Alert severity="info">
       <Typography textAlign="left">
        <FormattedMessage id={description} />
       </Typography>
      </Alert>
      {renderStepContent(index)}
     </Stack>
     <Box sx={{ mb: 2 }}>
      <div>
       {index !== ctbCloudbucketStps.length - 1 ? (
        <Button
         disabled={disableNextButton(index)}
         variant="contained"
         onClick={handleNext}
         sx={{ mt: 1, mr: 1 }}>
         <FormattedMessage id="app.wizard.continue" />
        </Button>
       ) : (
        <></>
       )}
       {index === 2 && (
        <>
         <Button
          sx={{ mt: 1, mr: 1 }}
          disabled={maxSize < size}
          variant="contained"
          onClick={handleOpenModal}>
          <FormattedMessage id="app.wizard.confirm" />
         </Button>
         <Button onClick={resetData} sx={{ mt: 1, mr: 1 }}>
          <FormattedMessage id="app.wizard.reset" />
         </Button>
        </>
       )}
       <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
        <FormattedMessage id="app.wizard.back" />
       </Button>
      </div>
     </Box>
    </StepContent>
   ) : (
    <StepContent>
     <Stack spacing={2}>
      <Alert severity="info">
       <Typography>
        <FormattedMessage id="server.checkExecution" />
       </Typography>
      </Alert>
      <CircularProgress />
     </Stack>
    </StepContent>
   )}
  </>
 );
};

export default ContaboCloudbucketCreation;
