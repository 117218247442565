/* eslint-disable react/style-prop-object */
import React, { useEffect, useRef, useState } from "react";
import { FormattedDate, FormattedMessage, FormattedNumber } from "react-intl";
import { useSelector } from "react-redux";
import { useDebounce } from "react-use";

import { isNil } from "ramda";

import SearchIcon from "@mui/icons-material/Search";

import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import AddMovement from "components/profile/movements/actions/AddMovement";
import DownloadMovements from "components/profile/movements/actions/DownloadMovements";
import AdminWrapper from "components/shared/admin/AdminWrapper";
import SearchForPartner from "components/shared/search/SearchForPartner";

import { getAllCredits } from "redux/handlers/creditsHandler";
import { getUsersList } from "redux/handlers/userHandler";

import { getCreditsDataSet, getCreditsNumber } from "redux/selectors/creditsSelector";
import { getIsAdmin } from "redux/selectors/userSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { IUsersList } from "types/api/userApiInterface";

const Credits = () => {
 const searchRef = useRef<HTMLInputElement>(null);
 const dispatch = useAppDispatch();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));

 const creditsList = useSelector(getCreditsDataSet);
 const creditsNumber = useSelector(getCreditsNumber);
 const isAdmin = useSelector(getIsAdmin);

 const [currentPage, setCurrentPage] = useState<number>(0);
 const [rowPerPage, setRowPerPage] = useState<number>(10);
 const [partnerId, setPartnerId] = useState<string>("");
 const [customerList, setCustomerList] = useState<Array<IUsersList>>([]);
 const [searchValue, setSearchValue] = useState<string>("");
 const [isLoading, setIsLoading] = useState<boolean>(true);

 useEffect(() => {
  (async () => {
   isAdmin && setCustomerList(await dispatch(getUsersList()));
   setIsLoading(false);
  })();
 }, []);

 useDebounce(
  async () => {
   if (!isLoading) {
    setCurrentPage(0);
    dispatch(getAllCredits(currentPage, rowPerPage, searchRef?.current?.value || "", partnerId));
   }
  },
  1000,
  [searchValue]
 );

 const handleSearchPartner = (partnerIdValue: string) => {
  setCurrentPage(0);
  setPartnerId(partnerIdValue);
  dispatch(getAllCredits(currentPage, rowPerPage, searchRef?.current?.value || "", partnerIdValue));
 };

 const handleOnRowsPerPageChange = (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
 ) => {
  const newRowsPerPage = parseInt(event.target.value, 10);
  setRowPerPage(newRowsPerPage);
  setCurrentPage(0);

  dispatch(
   getAllCredits(0, parseInt(event.target.value, 10), searchRef?.current?.value || "", partnerId)
  );
 };

 const handleOnPageChange = (
  event: React.MouseEvent<HTMLButtonElement> | null,
  newPage: number
 ) => {
  const startIndex = newPage * rowPerPage;
  setCurrentPage(newPage);
  dispatch(getAllCredits(startIndex, rowPerPage, searchRef?.current?.value || "", partnerId));
 };

 return (
  <Stack>
   <Stack justifyContent="space-between" alignItems="flex-end" direction="row" pb={1}>
    <Typography fontWeight="bold" fontSize={16} textTransform="uppercase">
     <FormattedMessage id="app.credits" />
     {` (${creditsNumber})`}
    </Typography>
    <Stack direction={"row"} spacing={desktopViewPort ? 2 : 0} alignItems="center">
     <DownloadMovements contactList={customerList} />
     <AdminWrapper>
      <AddMovement />
     </AdminWrapper>
    </Stack>
   </Stack>
   <Paper elevation={0} sx={{ borderRadius: "10px", boxShadow: 0 }}>
    <Stack direction="row" justifyContent="space-between" alignItems="center">
     <Stack
      justifyContent="flex-start"
      alignItems={desktopViewPort ? "center" : "flex-start"}
      spacing={desktopViewPort ? 0 : 2}
      p={2}
      direction={desktopViewPort ? "row" : "column"}>
      <TextField
       size="small"
       autoComplete="off"
       inputRef={searchRef}
       label={<FormattedMessage id="search.credits" />}
       InputLabelProps={{ shrink: true }}
       InputProps={{ startAdornment: <SearchIcon color="disabled" /> }}
       onChange={({ currentTarget }) => setSearchValue(currentTarget.value)}
      />
      <AdminWrapper sx={{ ml: 2 }}>
       <SearchForPartner
        contactList={customerList}
        handleSearchPartner={(partner) => handleSearchPartner(partner)}
       />
      </AdminWrapper>
     </Stack>
    </Stack>
    <Divider sx={{ borderColor: "#f86e06" }} />
    <Stack>
     <TableContainer component="div">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
       <TableHead>
        <TableRow sx={{ backgroundColor: "transparent" }}>
         <TableCell>
          <Typography variant="kxColoredSmall" fontWeight="bold">
           <FormattedMessage id="app.product" />
          </Typography>
         </TableCell>
         <TableCell>
          <Typography variant="kxColoredSmall" fontWeight="bold">
           <FormattedMessage id="app.details" />
          </Typography>
         </TableCell>
         <TableCell align="right">
          <Typography variant="kxColoredSmall" fontWeight="bold">
           <FormattedMessage id="app.date" />
          </Typography>
         </TableCell>
         <TableCell align="right">
          <Typography variant="kxColoredSmall" fontWeight="bold">
           <FormattedMessage id="app.bonus" />
          </Typography>
         </TableCell>
         <TableCell align="right">
          <Typography variant="kxColoredSmall" fontWeight="bold">
           <FormattedMessage id="app.amount" />
          </Typography>
         </TableCell>
        </TableRow>
       </TableHead>
       <TableBody>
        {creditsList.map((row, index) => (
         <TableRow key={`credits-list-${index}`}>
          <TableCell component="th" scope="row">
           <Typography variant="subtitle2">{row.product}</Typography>
           <AdminWrapper>
            <Typography variant="subtitle2">{row.companyName || "Company not provided"}</Typography>
           </AdminWrapper>
          </TableCell>
          <TableCell component="th" scope="row">
           <Stack spacing={1}>
            <Typography variant="subtitle2">{row.details}</Typography>
            {row.type ? (
             <Typography variant="subtitle2" fontStyle="italic">
              {row.type === "cash" ? (
               <FormattedMessage id="profile.credits.cashMovement" />
              ) : (
               <FormattedMessage id="profile.credits.bonusMovement" />
              )}
             </Typography>
            ) : (
             <></>
            )}
           </Stack>
          </TableCell>
          <TableCell component="th" scope="row" align="right">
           <FormattedDate
            value={row.createdAt || new Date()}
            year="numeric"
            month="long"
            day="2-digit"
           />
          </TableCell>
          <TableCell component="th" scope="row" align="right">
           <FormattedNumber
            style="currency"
            currency="EUR"
            value={isNil(row.bonus) ? 0 : Number(row.bonus)}
           />
          </TableCell>
          <TableCell component="th" scope="row" align="right">
           <FormattedNumber
            style="currency"
            currency="EUR"
            value={isNil(row.amount) ? 0 : Number(row.amount)}
           />
          </TableCell>
         </TableRow>
        ))}
       </TableBody>
      </Table>
     </TableContainer>
     <TablePagination
      component="div"
      count={creditsNumber || 0}
      page={currentPage}
      rowsPerPage={rowPerPage}
      onPageChange={handleOnPageChange}
      onRowsPerPageChange={handleOnRowsPerPageChange}
     />
    </Stack>
   </Paper>
  </Stack>
 );
};

export default Credits;
