import { createTheme, PaletteColorOptions } from "@mui/material/styles";

declare module "@mui/material/Typography" {
 interface TypographyPropsVariantOverrides {
  small: true;
  extraSmall: true;
  kxColored: true;
  kxColoredSmall: true;
 }
}

declare module "@mui/material/styles" {
 interface Palette {
  kxOrange: string;
  kxBlue: string;
 }
 interface PaletteOptions {
  kxOrange: PaletteColorOptions;
  kxBlue: PaletteColorOptions;
 }
}

declare module "@mui/material/IconButton" {
 interface IconButtonPropsColorOverrides {
  tertiary: true;
  kxBlue: true;
 }
}

declare module "@mui/material/SvgIcon" {
 interface SvgIconPropsColorOverrides {
  tertiary: true;
  kxOrange: true;
  kxBlue: true;
 }
}

declare module "@mui/material/LinearProgress" {
 interface LinearProgressPropsColorOverrides {
  kxOrange: true;
  kxBlue: true;
 }
}

declare module "@mui/material/Button" {
 interface ButtonPropsVariantOverrides {
  actionPrimarySmall: true;
  actionPrimary: true;
  actionDisable: true;
  kxActionButton: true;
  kxModalConfirm: true;
  kxModalDeny: true;
  kxRedActionButton: true;
 }
}

export default createTheme({
 palette: {
  secondary: {
   main: "#9e91cc"
  },
  kxOrange: {
   main: "#f86e06"
  },
  kxBlue: {
   main: "#0D1934"
  }
 },
 typography: {
  fontFamily: "Archivo"
 },
 components: {
  MuiButton: {
   styleOverrides: {
    root: ({ ownerState }) => ({
     ...(ownerState.variant === "contained" &&
      ownerState.color === "primary" && {
       backgroundColor: "#9e91cc",
       color: "#fff",
       "&:hover": {
        backgroundColor: "#b4a7e3"
       }
      })
    })
   },
   variants: [
    {
     props: { variant: "actionPrimarySmall" },
     style: {
      height: "30px",
      backgroundColor: "transparent",
      border: "1px solid #000",
      color: "#000",
      "&:hover": {
       backgroundColor: "#e7e7ee"
      }
     }
    },
    {
     props: { variant: "kxActionButton" },
     style: {
      height: "30px",
      fontWeight: "bold",
      backgroundColor: "transparent",
      color: "#0D1934",
      "&:hover": {
       backgroundColor: "#e7e7ee"
      }
     }
    },
    {
     props: { variant: "kxRedActionButton" },
     style: {
      height: "30px",
      fontWeight: "bold",
      backgroundColor: "#a12325",
      color: "white",
      "&:hover": {
       backgroundColor: "#b85a5a"
      }
     }
    },
    {
     props: { variant: "kxModalConfirm" },
     style: {
      fontWeight: "bold",
      backgroundColor: "#0D1934",
      color: "white",
      "&:hover": {
       backgroundColor: "#475d90"
      },
      "&:disabled": {
       backgroundColor: "#92949a",
       cursor: "not-allowed"
      }
     }
    },
    {
     props: { variant: "kxModalDeny" },
     style: {
      fontWeight: "bold",
      backgroundColor: "#f86e06",
      color: "white",
      "&:hover": {
       backgroundColor: "#FF9D59"
      }
     }
    },
    {
     props: { variant: "actionPrimary" },
     style: {
      backgroundColor: "transparent",
      border: "1px solid #000",
      color: "#000",
      "&:hover": {
       backgroundColor: "#e7e7ee"
      }
     }
    },
    {
     props: { variant: "actionDisable" },
     style: {
      height: "30px",
      backgroundColor: "transparent",
      border: "1px solid #ed6c18",
      color: "#ed6c18",
      "&:hover": {
       backgroundColor: "#e7e7ee"
      }
     }
    }
   ]
  },
  MuiAppBar: {
   styleOverrides: {
    root: {
     backgroundColor: "#fff",
     color: "#000"
    }
   }
  },
  MuiTablePagination: {
   styleOverrides: {
    root: {
     color: "#0D1934",
     fontWeight: "bold"
    }
   }
  },
  MuiTypography: {
   variants: [
    {
     props: { variant: "small" },
     style: {
      textTransform: "none",
      fontSize: "0.85rem"
     }
    },
    {
     props: { variant: "extraSmall" },
     style: {
      textTransform: "none",
      color: "rgb(77,73,73)",
      fontSize: "0.70rem"
     }
    },
    {
     props: { variant: "kxColored" },
     style: {
      color: "#f86e06"
     }
    },
    {
     props: { variant: "kxColoredSmall" },
     style: {
      color: "#f86e06",
      fontSize: "0.85rem"
     }
    }
   ]
  },
  MuiIconButton: {
   variants: [
    {
     props: { color: "tertiary" },
     style: {
      color: "#FAFAFA"
     }
    }
   ]
  },
  MuiSvgIcon: {
   variants: [
    {
     props: { color: "tertiary" },
     style: {
      color: "#fff"
     }
    }
   ]
  },
  MuiPaper: {
   styleOverrides: {
    root: {
     boxShadow: "0 3px 6px #00000029"
    }
   }
  },
  MuiMenu: {
   styleOverrides: {
    paper: {
     borderRadius: "12px"
    }
   }
  },
  MuiTableRow: {
   styleOverrides: {
    root: {
     "&:hover": {
      backgroundColor: "#f8f6f0"
     }
    },
    head: {
     backgroundColor: "rgba(213,213,213,0.58)"
    }
   }
  }
 }
});
