import React, { ReactElement, useEffect, useState } from "react";

import Stack from "@mui/material/Stack";

import Cloudbucket from "components/addons/cloudbucket/Cloudbucket";
import SkeletonAddons from "components/shared/skeletons/SkeletonAddons";

import { getAllCloudbucket } from "redux/handlers/addonsHandle";

import { useAppDispatch } from "hooks/reduxHook";

const CloudbucketPage = (): ReactElement => {
 const dispatch = useAppDispatch();

 const [isLoading, setIsLoading] = useState<boolean>(true);

 useEffect(() => {
  (async () => {
   await dispatch(getAllCloudbucket(0, 10));
   setIsLoading(false);
  })();
 }, []);

 return <Stack>{isLoading ? <SkeletonAddons id="addons.cloudbucket" /> : <Cloudbucket />}</Stack>;
};

export default CloudbucketPage;
