import React, { ReactElement, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import IconSelector from "components/shared/images/IconSelector";

import { postChangeDomainAutomigration } from "redux/handlers/domainsHandler";

import { getDomainsList } from "redux/selectors/domainsSelector";

import { useAppDispatch } from "hooks/reduxHook";

import ResetAuthinfo from "../actions/ResetAuthinfo";

const DomainSpecifications = (): ReactElement => {
 const dispatch = useAppDispatch();
 const theme = useTheme();
 const phoneView = useMediaQuery(theme.breakpoints.up("sm"));

 const domainData = useSelector(getDomainsList)[0];

 const [automigration, setAutomigration] = useState<boolean>(domainData.automigration);

 const handleSwitchToggle = async (newValue: boolean) => {
  setAutomigration(newValue);
  await dispatch(postChangeDomainAutomigration(domainData.id, newValue));
 };

 return (
  <Paper elevation={0} sx={{ borderRadius: "10px", boxShadow: 0 }}>
   <Stack alignItems="center" justifyContent="flex-start" direction="row" px={2} pt={1}>
    <IconSelector icon="KeyIcon" props={{ fontSize: "medium", color: "secondary" }} />
    <Typography variant="h6" component="h2" ml={1}>
     <FormattedMessage id="domains.details" />{" "}
    </Typography>
   </Stack>
   <Stack>
    <Stack
     direction="row"
     alignItems="center"
     justifyContent="space-between"
     textAlign="start"
     py={0.5}
     pr={2}>
     <Typography fontWeight="bold" variant="subtitle1" minWidth={phoneView ? 150 : 100} px={2}>
      <FormattedMessage id="domains.authInfo" />
     </Typography>
     <Stack direction="row" alignItems="center" spacing={2}>
      <Typography>{domainData.authinfo}</Typography>
      <ResetAuthinfo />
     </Stack>
    </Stack>
    <Divider />
    <Stack
     direction="row"
     alignItems="center"
     justifyContent="space-between"
     textAlign="start"
     py={0.5}
     pr={2}>
     <Typography fontWeight="bold" variant="subtitle1" minWidth={150} px={2}>
      <FormattedMessage id="domain.automigrate" />
     </Typography>
     <Switch
      defaultChecked={automigration}
      onChange={(event) => handleSwitchToggle(event.target.checked)}
     />
    </Stack>
    <Divider />
   </Stack>
  </Paper>
 );
};

export default DomainSpecifications;
