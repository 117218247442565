import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "redux/store";

import { servicesList } from "constants/services";

import { IServerData } from "types/api/serversApiInterface";

export const getServersState = (state: AppState) => state.servers;

export const getServerDetailState = (state: AppState) => state.serverDetails;

export const getServerServices = (state: AppState) => state.serverServices;

export const getAllServices = createSelector(getServerServices, (services) => {
 return services;
});

export const getServerNumber = createSelector(getServersState, (server) => {
 return server?.totalCount;
});

export const getServersTableList = createSelector(getServersState, (servers) => {
 return (
  servers?.dataset.map((server: IServerData) => {
   return {
    id: server.id,
    hz_id: server.server_id,
    status: server.server_status,
    os: server.image_os_flavor,
    serverName: server.server_name,
    ipAddress: server.ipv4_ip,
    owner: server?.personal_name,
    countryCode: server?.datacenter_location_country,
    renewDate: server.expirationdate,
    serverMachine: server.server_type_name,
    price: server.amount,
    provider: server.provider,
    autorenew: server.autorenew,
    agentoken: server.agentoken,
    userid: server.userid,
    type: server.type,
    expiration: server.expirationdate,
    status_on: server.service_status,
    server_type_cores: server.server_type_cores,
    server_type_disk: server.server_type_disk,
    server_type_memory: server.server_type_memory,
    private_net: server.private_net
   };
  }) || []
 );
});

export const getServerName = createSelector(getServerDetailState, (serverDetails) => {
 return serverDetails?.server_name;
});

export const getServerDbId = createSelector(getServerDetailState, (serverDetails) => {
 return serverDetails?.id || 0;
});

export const getServerDetails = createSelector(getServerDetailState, (serverDetails) => {
 return {
  id: serverDetails?.id,
  server_id: serverDetails?.server_id || 0,
  serverName: serverDetails?.server_name || "No Name",
  provider: serverDetails?.provider,
  os: serverDetails?.image_os_flavor,
  password: serverDetails?.server_password,
  product: serverDetails?.server_type_name,
  server_type: serverDetails?.server_type_name,
  type: serverDetails?.type,
  ipAddress: serverDetails?.ipv4_ip,
  countryCode: serverDetails?.datacenter_location_country?.toLocaleLowerCase(),
  cpu: serverDetails?.server_type_cores,
  ram: serverDetails?.server_type_memory,
  disk: serverDetails?.server_type_disk,
  cpuCore: serverDetails?.server_type_cores || 0,
  ramSize: serverDetails?.server_type_memory || 0,
  diskSize: serverDetails?.server_type_disk || 0,
  ipv4: serverDetails?.ipv4_ip,
  ipv4rDns: serverDetails?.ipv4_dns_ptr || "",
  ipv6: serverDetails?.ipv6_ip,
  ipv6rDns: serverDetails?.ipv6_dns_ptr || "",
  ipv6_enabled: serverDetails?.ipv6_enabled,
  agentTime: "",
  timeZone: "",
  outgoingTraffic: 0,
  ingoingTraffic: 0,
  agentoken: serverDetails?.agentoken,
  serverMachine: "",
  price: Number(serverDetails?.amount),
  system_family: serverDetails?.image_os_flavor,
  backupWindow: "",
  serverRoles: "",
  location: "",
  php_config: "",
  locked: serverDetails?.locked,
  server_status: serverDetails?.server_status,
  backup_enabled: serverDetails?.backup_enabled,
  agentinstall: serverDetails?.agentinstall,
  rescale: serverDetails?.temporary_rescale,
  iso_id: serverDetails?.iso_id,
  service_status: serverDetails?.service_status,
  business_continuity: serverDetails?.business_continuity,
  cloudbucket_connected: serverDetails?.cloudbucket_connected,
  bucket_id_db: serverDetails?.bucket_id_db
 };
});

export const getServiceDetails = createSelector(getServerDetailState, (serverDetails) => {
 const filteredServices = servicesList.filter((service) => {
  if (service.os !== serverDetails?.system_family) return false;
  if (service?.product && !serverDetails?.serverRoles?.includes(service?.product)) return false;
  // @ts-ignore
  /* TODO: inserire il check per ogni tipologia di servizio attivo sul server
  const serviceCheck: string | undefined = serverDetails?.server?.[service.serverKey];
  // TODO: hack per come sono strutturate le api
  if (serviceCheck && service?.phpEngine !== serverDetails?.server?.php_engine) return false;*/

  return true;
 });

 const completedServices = filteredServices.map((service) => {
  switch (service.serverKey) {
   case "apache2check":
    return {
     ...service,
     cpu: serverDetails?.apache2_cpu ? serverDetails?.apache2_cpu : 0,
     logs: serverDetails?.apache2_log,
     check: serverDetails?.apache2check
    };
   case "nginxcheck":
    return {
     ...service,
     cpu: serverDetails?.nginx_cpu ? serverDetails?.nginx_cpu : 0,
     logs: serverDetails?.nginx_log,
     check: serverDetails?.nginxcheck
    };
   case "ssh":
    return {
     ...service,
     cpu: 0,
     logs: null,
     check: serverDetails?.ssh_check
    };
   case "postfix":
    return {
     ...service,
     cpu: serverDetails?.post_cpu ? serverDetails?.post_cpu : 0,
     logs: serverDetails?.post_log,
     check: serverDetails?.postcheck
    };
   case "dovecot":
    return {
     ...service,
     cpu: serverDetails?.dove_cpu ? serverDetails?.dove_cpu : 0,
     logs: serverDetails?.dove_log,
     check: serverDetails?.dovecheck
    };
   case "webmailcheck":
    return {
     ...service,
     cpu: 0,
     logs: null,
     check: 0
    };
   case "policyd":
    return {
     ...service,
     cpu: 0,
     logs: null,
     check: serverDetails?.policyd_check
    };
   case "sql":
    return {
     ...service,
     cpu: serverDetails?.mysql_cpu ? serverDetails?.mysql_cpu : 0,
     logs: serverDetails?.mysql_log,
     check: serverDetails?.mysqlcheck
    };
   case "php":
    return {
     ...service,
     cpu: serverDetails?.phpengine_cpu ? serverDetails?.phpengine_cpu : 0,
     logs: null,
     check: serverDetails?.php_engine ? 1 : 0
    };
   case "ftp":
    return {
     ...service,
     cpu: 0,
     logs: serverDetails?.ftp_log,
     check: serverDetails?.ftpcheck
    };
   case "memcache":
    return {
     ...service,
     cpu: 0,
     logs: null,
     check: serverDetails?.memccheck
    };
   case "redis":
    return {
     ...service,
     cpu: serverDetails?.redi_cpu ? serverDetails?.redi_cpu : 0,
     logs: serverDetails?.redi_log,
     check: serverDetails?.redicheck
    };
   case "rdp":
    return {
     ...service,
     cpu: 0,
     logs: null,
     check: serverDetails?.rdpcheck
    };
   case "iis":
    return {
     ...service,
     cpu: 0,
     logs: null,
     check: serverDetails?.iischeck
    };
   default:
    return { ...service, cpu: 0, logs: null, check: 2 };
  }
 });

 return completedServices;
});

export const getPhpConfigData = createSelector(getServerDetailState, (serverDetails) => {
 return serverDetails?.php_config ? JSON.parse(serverDetails?.php_config) : "";
});

export const getServerProviderId = createSelector(getServerDetailState, (serverDetails) => {
 return serverDetails?.server_id || 0;
});
