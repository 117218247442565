import { generateUniqueId } from "helpers/generic";
import { AppAction } from "redux/store";

import notificationReducer from "redux/reducers/notificationReducer";
import ServicesReducer from "redux/reducers/servicesReducer";

import { IServiceApi } from "types/api/servicesApiInterface";

import { ApiService } from "service/ApiService";

export const getAllServices =
 (
  currentIndex: number,
  sizePerPage: number,
  q?: string,
  userid?: string,
  type?: string,
  from?: string,
  to?: string,
  status?: boolean
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<IServiceApi>(
    `/services/allservices?currentIndex=${currentIndex}&sizePerPage=${sizePerPage}&q=${
     q || ""
    }&userid=${userid || ""}&type=${type || ""}&from=${from || ""}&to=${to || ""}&status=${
     status || false
    }`
   );
   dispatch(ServicesReducer.actions.setServices(data || {}));
  } catch (error) {
   console.warn("FAILED TO GET SERVICES");
  }
 };

export const getAllServicesWithUserid =
 (
  currentIndex: number,
  sizePerPage: number,
  q: string,
  userid: string,
  type: string,
  from: string,
  to: string,
  status: boolean
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<IServiceApi>(
    `/services/allservices?currentIndex=${currentIndex}&sizePerPage=${sizePerPage}&q=${q}&userid=${userid}&type=${type}&from=${from}&to=${to}&status=${status}`
   );
   dispatch(ServicesReducer.actions.setServices(data || {}));
  } catch (error) {
   console.warn("FAILED TO GET SERVICES");
  }
 };

export const postServiceChangeAutorenew =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/services/updateautorenew", "POST", {
    id: id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Autorenew changed successfully",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to change autorenew - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postServiceChangeRenewDate =
 (id: number, expire: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/services/updateexpiration", "POST", {
    id: id,
    expire: expire
   });
   if (data) {
    await dispatch(getAllServices(0, 10));
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Service expire date changed",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to change expire date - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postRenewService =
 (id: number, endpoint: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>(endpoint, "POST", {
    id: id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Service in renew",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to renew service - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const getUserTypes = async (): Promise<Array<string>> => {
 try {
  const { data } = await ApiService<Array<string>>("/services/gettypes");
  return data;
 } catch (error: any) {
  console.warn(error);
  return [];
 }
};

export const postAddNewService =
 (
  name: string,
  userid: string,
  type: string,
  expire: string,
  duration: number,
  amount: number,
  autorenew: boolean,
  handling: boolean
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/services/addservicemanually", "POST", {
    name: name,
    userid: userid,
    type: type,
    expire: expire,
    duration: duration,
    amount: amount,
    autorenew: autorenew,
    handling: handling
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Adding service...",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to add service - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postDeleteService =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/services/deleteserviceadmin", "POST", {
    id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Deleting service...",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to delete service - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };
