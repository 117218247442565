/* eslint-disable react/style-prop-object */
import React, { ReactElement, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import LanguageIcon from "@mui/icons-material/Language";

import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { contacts, hosts, recap, steps } from "constants/domains";
import { domainValidation } from "constants/regexp";

import RegistarImage from "components/shared/images/RegistarImage";
import AppModal from "components/shared/modal/AppModal";
import DomainProviderBox from "components/wizard/domainWizardComponents/DomainProviderBox";

import {
 getContactsList,
 getHostList,
 getRegisterList,
 postCheckDomainAvailability,
 postCreateDomain,
 postGetDomainPrice
} from "redux/handlers/domainsHandler";

import { getUserBalance } from "redux/selectors/userSelector";

import { useAppDispatch } from "hooks/reduxHook";

import {
 IContactData,
 IHostData,
 IRegisterData,
 IRegistrarPrice
} from "types/api/domainsApiInterface";

const defaultPrice = {
 amount: 0,
 max: 1,
 min: 1
};

const AddDomain = (): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();
 const theme = useTheme();
 const navigate = useNavigate();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));

 const userBalance = useSelector(getUserBalance);

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [activeStep, setActiveStep] = useState<number>(0);
 const [domainAvailability, setDomainAvailability] = useState<string>("");
 const [contactList, setContactList] = useState<Array<IContactData>>([]);
 const [hostList, setHostList] = useState<Array<IHostData>>([]);
 const [registerList, setRegisterList] = useState<Array<IRegisterData>>([]);
 const [stepLoading, setStepLoading] = useState<boolean>(false);
 const [providerPrice, setProviderPrice] = useState<IRegistrarPrice>(defaultPrice);
 const [loading, setLoading] = useState<boolean>(false);

 useEffect(() => {
  (async () => {
   setContactList(await getContactsList());
   setHostList(await getHostList());
   setRegisterList(await getRegisterList());
  })();
 }, []);

 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);

 const handleConfirm = async () => {
  setLoading(true);
  const dataToSend = getValues();
  await dispatch(
   postCreateDomain({
    domain: dataToSend.domain,
    authCode: dataToSend.authinfo,
    provider: dataToSend.provider,
    period: dataToSend.year,
    registrant: dataToSend.registrant,
    admin: dataToSend.admin,
    billing: dataToSend.billing,
    technical: dataToSend.technical,
    ws: dataToSend.web,
    ms: dataToSend.mail,
    ns: dataToSend.ns,
    action: domainAvailability === "TRANSFER" ? "transfer" : "register",
    amount: providerPrice.amount * dataToSend.year
   })
  );

  handleClose();
  setLoading(false);
  handleReset();
  navigate("/domains");
 };

 const handleNext = async (index: number) => {
  if (domainAvailability !== "EXTENSION NOT AVAILABLE") {
   if (domainAvailability !== "TRANSFER" && index === 0) {
    setActiveStep((prevActiveStep) => prevActiveStep + 2);
   } else {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
   }
  }

  if (index === 2) {
   checkPrice();
  }
 };

 const handleBack = (index: number) => {
  if (domainAvailability === "AVAILABLE" && index === 2) {
   setActiveStep((prevActiveStep) => prevActiveStep - 2);
  } else {
   setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }
 };

 const handleReset = () => {
  reset();
  setDomainAvailability("");
  setActiveStep(0);
 };

 const { control, watch, reset, getValues, setValue } = useForm({
  defaultValues: {
   domain: "",
   authinfo: "",
   provider: "",
   year: 1,
   registrant: 0,
   admin: 0,
   billing: 0,
   technical: 0,
   web: 0,
   mail: 0,
   ns: 0
  }
 });

 const checkDomain = async () => {
  setStepLoading(true);
  setDomainAvailability(await postCheckDomainAvailability(watch("domain")));
  setStepLoading(false);
 };

 const checkPrice = async () => {
  setStepLoading(true);
  setProviderPrice(
   await postGetDomainPrice(
    watch("domain"),
    watch("provider") === "ASC" ? "ascio" : "internetbs",
    domainAvailability
   )
  );
  setStepLoading(false);
 };

 const renderOptionalMessage = (index: number) => {
  if (index === 1) {
   if (domainAvailability === "TRANSFER") {
    return (
     <Typography variant="caption">
      <FormattedMessage id="domain.authinfoNeeded" />
     </Typography>
    );
   } else {
    return (
     <Typography variant="caption">
      <FormattedMessage id="domain.authinfoNotNeeded" />
     </Typography>
    );
   }
  } else {
   return <></>;
  }
 };

 const renderStepContent = (index: number) => {
  switch (index) {
   case 0:
    return (
     <Controller
      name={"domain"}
      control={control}
      render={({ field }) => (
       <TextField
        {...field}
        onChange={(event) => {
         setValue("domain", event.target.value.toLocaleLowerCase());
         setDomainAvailability("");
        }}
        label={intl.formatMessage({ id: "domain.domainName" })}
       />
      )}
     />
    );
   case 1:
    return (
     <Controller
      name="authinfo"
      control={control}
      render={({ field }) => (
       <TextField
        {...field}
        onChange={(event) => setValue("authinfo", event.target.value)}
        label={intl.formatMessage({ id: "domain.domainAuthinfo" })}
       />
      )}
     />
    );
   case 2:
    return (
     <DomainProviderBox
      providers={registerList}
      selectProvider={(name) => setValue("provider", name)}
      selectedProvider={watch("provider")}
     />
    );
   case 3:
    return (
     <Controller
      name={"year"}
      control={control}
      render={({ field }) => (
       <TextField
        {...field}
        onChange={(event) => setValue("year", Number(event.target.value))}
        value={watch("year")}
        type="number"
        label={intl.formatMessage({ id: "domain.domainYear" })}
       />
      )}
     />
    );
   case 4:
    return (
     <Stack spacing={2}>
      {contacts.map((contact, index) => {
       return (
        <Stack key={`${contact.name}-${index}`}>
         <Controller
          name={contact.name}
          control={control}
          render={({ field }) => (
           <Autocomplete
            fullWidth={true}
            autoHighlight
            sx={{ my: 2 }}
            onChange={(e, value) => setValue(contact.name, value?.id || 0)}
            options={contactList.map((element, index) => {
             return {
              label: `${element.firstname} ${element.lastname}`,
              id: element.id,
              key: `${contact.label} - ${index}`
             };
            })}
            value={contactList
             .map((element) => {
              return {
               label: `${element?.firstname} ${element?.lastname}`,
               id: element.id,
               key: `${contact.label} - ${index}`
              };
             })
             .find((element) => element.id === getValues(contact.name))}
            renderOption={(props, option) => {
             return (
              <li {...props} key={option.key}>
               {option.label}
              </li>
             );
            }}
            renderInput={(params) => (
             <TextField
              {...field}
              {...params}
              label={intl.formatMessage({ id: contact.label })}
              InputLabelProps={{ shrink: true }}
             />
            )}
           />
          )}
         />
        </Stack>
       );
      })}
     </Stack>
    );
   case 5:
    return (
     <Stack spacing={2}>
      {hosts.map((host, index) => {
       return (
        <Stack key={`${host.type}-${index}`}>
         <Controller
          name={host.type}
          control={control}
          render={({ field }) => (
           <Autocomplete
            fullWidth={true}
            autoHighlight
            sx={{ my: 2 }}
            onChange={(e, value) => setValue(host.type, value?.id || 0)}
            options={hostList
             .filter((server) => server.type === host.type)
             .map((element, index) => {
              return {
               label: element.name,
               id: element.id,
               key: `${host.label} - ${index}`
              };
             })}
            value={hostList
             .map((element) => {
              return { label: element.name, id: element.id, key: `${host.label} - ${index}` };
             })
             .find((element) => element.id === getValues(host.type))}
            renderOption={(props, option) => {
             return (
              <li {...props} key={option.key}>
               {option.label}
              </li>
             );
            }}
            renderInput={(params) => (
             <TextField
              {...field}
              {...params}
              label={intl.formatMessage({ id: host.label })}
              InputLabelProps={{ shrink: true }}
             />
            )}
           />
          )}
         />
        </Stack>
       );
      })}
     </Stack>
    );
   default:
    return <TextField />;
  }
 };

 const disableNextButton = (index: number) => {
  switch (index) {
   case 0:
    return (
     watch("domain").length === 0 ||
     domainAvailability === "EXTENSION NOT AVAILABLE" ||
     !domainValidation.test(watch("domain"))
    );
   case 1:
    return watch("authinfo").length === 0;
   case 2:
    return watch("provider").length === 0;
   case 3:
    return watch("year") > providerPrice.max || watch("year") < providerPrice.min;
   case 4:
    return (
     watch("registrant") === 0 ||
     watch("admin") === 0 ||
     watch("billing") === 0 ||
     watch("technical") === 0
    );
   default:
    return false;
  }
 };

 const renderDomainCheckMessage = () => {
  switch (domainAvailability) {
   case "TRANSFER":
    return (
     <Alert severity="success">
      <FormattedMessage id="domain.domainTransferAvailable" />
     </Alert>
    );
   case "AVAILABLE":
    return (
     <Alert severity="success">
      <FormattedMessage id="domain.domainBuyAvailable" />
     </Alert>
    );
   default:
    return (
     <Alert severity="error">
      <FormattedMessage id="domain.domainNotAvailable" />
     </Alert>
    );
  }
 };

 const checkDisableConfirm = () => {
  return (
   watch("domain") === "" ||
   watch("provider") === "" ||
   watch("admin") === 0 ||
   watch("billing") === 0 ||
   watch("ns") === 0 ||
   watch("mail") === 0 ||
   watch("provider") === "" ||
   watch("registrant") === 0 ||
   watch("technical") === 0 ||
   watch("web") === 0
  );
 };

 return (
  <Stack spacing={2}>
   <Stack alignItems="center" mt={5} p={3}>
    <LanguageIcon />
    <Typography>
     <FormattedMessage
      id={domainAvailability === "TRANSFER" ? "domain.newDomainTransfer" : "domain.newDomain"}
     />
    </Typography>
   </Stack>
   <Stack direction={desktopViewPort ? "row" : "column"} justifyContent="center">
    <Box
     sx={{ maxWidth: desktopViewPort ? 1000 : "auto", minWidth: desktopViewPort ? 500 : "auto" }}
     m={desktopViewPort ? 10 : 0}>
     <Stepper activeStep={activeStep} orientation="vertical">
      {steps.map((step, index) => (
       <Step key={step.label}>
        <StepLabel optional={renderOptionalMessage(index)}>
         <FormattedMessage id={step.label} />
        </StepLabel>
        {!stepLoading ? (
         <StepContent>
          <Stack spacing={2}>
           <Alert severity="info">
            <Typography>
             <FormattedMessage id={step.description} />
            </Typography>
           </Alert>
           {renderStepContent(index)}
           {index === 0 && domainAvailability !== "" && renderDomainCheckMessage()}
          </Stack>
          <Box sx={{ mb: 2 }}>
           <div>
            {index !== steps.length - 1 ? (
             <Button
              disabled={disableNextButton(index)}
              variant="contained"
              onClick={() => (domainAvailability === "" ? checkDomain() : handleNext(index))}
              sx={{ mt: 1, mr: 1 }}>
              {index === 0 && domainAvailability === "" ? (
               <FormattedMessage id="domain.checkDomain" />
              ) : (
               <FormattedMessage id="domain.continue" />
              )}
             </Button>
            ) : (
             <></>
            )}
            {index === 5 && (
             <>
              <Button
               sx={{ mt: 1, mr: 1 }}
               variant="contained"
               disabled={checkDisableConfirm()}
               onClick={handleOpen}>
               <FormattedMessage id="domain.domainBuy" />
              </Button>
              <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
               <FormattedMessage id="domain.domainReset" />
              </Button>
             </>
            )}
            <Button disabled={index === 0} onClick={() => handleBack(index)} sx={{ mt: 1, mr: 1 }}>
             <FormattedMessage id="domain.back" />
            </Button>
           </div>
          </Box>
         </StepContent>
        ) : (
         <StepContent>
          <Stack spacing={2}>
           <Alert severity="info">
            <Typography>
             <FormattedMessage id="domain.checkExecution" />
            </Typography>
           </Alert>
           {renderStepContent(index)}
           <CircularProgress />
          </Stack>
         </StepContent>
        )}
       </Step>
      ))}
     </Stepper>
    </Box>
    <Paper
     elevation={0}
     sx={{
      m: desktopViewPort ? 5 : 1,
      p: 2,
      maxHeight: 580,
      position: "sticky",
      top: 30,
      minWidth: desktopViewPort ? 400 : "auto",
      borderRadius: "10px",
      boxShadow: 0
     }}>
     <Stack mb={2}>
      <Typography variant="h5" fontWeight="bold">
       <FormattedMessage id="domain.add.recap" />
      </Typography>
     </Stack>
     <Divider textAlign="left">
      <Typography variant="overline">
       <FormattedMessage id="domain.domainData" />
      </Typography>
     </Divider>
     <Stack direction="row" spacing={2}>
      <Typography minWidth={200} textAlign="start" fontWeight="bold">
       <FormattedMessage id="domain.name" />:
      </Typography>
      <Typography noWrap>{watch("domain")}</Typography>
     </Stack>
     <Stack direction="row" spacing={2}>
      <Typography minWidth={200} textAlign="start" fontWeight="bold">
       <FormattedMessage id="domain.authinfo" />:
      </Typography>
      <Typography>{watch("authinfo").length === 0 ? "Non inserito" : "Inserito"}</Typography>
     </Stack>
     <Stack direction="row" spacing={2}>
      <Typography minWidth={200} textAlign="start" fontWeight="bold">
       <FormattedMessage id="domain.provider" />:
      </Typography>
      <Stack direction="row" spacing={2}>
       <Typography>{watch("provider")}</Typography>
       {desktopViewPort && watch("provider") !== "" && (
        <RegistarImage provider={watch("provider") === "internetbs" ? "internetbs" : "ascio"} />
       )}
      </Stack>
     </Stack>
     <Stack direction="row" spacing={2}>
      <Typography minWidth={200} textAlign="start" fontWeight="bold">
       <FormattedMessage id="domain.year" />:
      </Typography>
      <Typography>{watch("year")}</Typography>
     </Stack>
     <Divider textAlign="left" sx={{ mt: 2 }}>
      <Typography variant="overline">
       <FormattedMessage id="domain.domainContactData" />
      </Typography>
     </Divider>
     {recap.map((recap, index) => {
      return (
       <Stack direction="row" spacing={2} key={`recap-list-${index}`}>
        <Typography minWidth={200} textAlign="start" fontWeight="bold">
         <FormattedMessage id={recap.label} />:
        </Typography>
        {watch(recap.name) !== 0 && (
         <Typography noWrap>{`${contactList.find((element) => element.id === watch(recap.name))
          ?.firstname} ${contactList.find((element) => element.id === watch(recap.name))
          ?.lastname}`}</Typography>
        )}
       </Stack>
      );
     })}
     <Divider textAlign="left" sx={{ mt: 2 }}>
      <Typography variant="overline">
       <FormattedMessage id="domain.domainHostData" />
      </Typography>
     </Divider>
     {hosts.map((recap, index) => {
      return (
       <Stack direction="row" spacing={2} key={`recap-list2-${index}`}>
        <Typography minWidth={200} textAlign="start" fontWeight="bold">
         <FormattedMessage id={recap.label} />:
        </Typography>
        {watch(recap.type) !== 0 && (
         <Typography noWrap>
          {hostList.find((element) => element.id === watch(recap.type))?.name}
         </Typography>
        )}
       </Stack>
      );
     })}
     <Divider textAlign="left" sx={{ mt: 2 }}>
      <Typography variant="overline">
       <FormattedMessage id="domain.domainCurrencyData" />
      </Typography>
     </Divider>
     <Stack direction="row" spacing={2}>
      <Typography minWidth={200} textAlign="start" fontWeight="bold">
       <FormattedMessage id="domain.recap.price" />:
      </Typography>
      <Typography>
       <FormattedNumber
        value={providerPrice.amount * watch("year")}
        style={"currency"}
        currency="EUR"
       />
      </Typography>
     </Stack>
     <Stack direction="row" spacing={2}>
      <Typography minWidth={200} textAlign="start" fontWeight="bold">
       <FormattedMessage id="domain.recap.balance" />:
      </Typography>
      <Typography>
       <FormattedNumber value={userBalance} style={"currency"} currency="EUR" />
      </Typography>
     </Stack>
    </Paper>
   </Stack>
   <AppModal
    open={openModal}
    close={handleClose}
    title={intl.formatMessage({ id: "domain.buyDomain" })}
    handleClose={handleClose}
    loading={loading}
    handleConfirm={handleConfirm}
    disabled={userBalance < providerPrice.amount * watch("year")}>
    <Stack>
     {userBalance < providerPrice.amount * watch("year") ? (
      <Alert severity="error">
       <FormattedMessage id="domain.balanceNotSufficient" />
      </Alert>
     ) : (
      <Typography>
       <FormattedMessage id="domain.confirmBuyDomain" />
      </Typography>
     )}
    </Stack>
   </AppModal>
  </Stack>
 );
};

export default AddDomain;
