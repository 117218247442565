import React, { ReactElement, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { isNil } from "ramda";

import AddIcon from "@mui/icons-material/Add";

import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { emailValidation } from "constants/regexp";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { getAllUserContacts, postAddTeamsUser } from "redux/handlers/teamsHandler";
import { generatePassword } from "redux/handlers/userHandler";

import { getUserId } from "redux/selectors/userSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { IContactData } from "types/api/teamsApiInterface";

type userType = {
 email: string;
 password: string;
 contact_id: number;
};

const AddTeamsUser = (): ReactElement => {
 const intl = useIntl();
 const userId: number = useSelector(getUserId);
 const dispatch = useAppDispatch();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));

 const [isLoading, setIsLoading] = useState<boolean>(false);
 const [openModal, setOpenModal] = useState<boolean>(false);
 const [contacts, setContacts] = useState<Array<IContactData>>([]);
 const [passwordType, setPasswordType] = useState<string>("password");

 const { control, handleSubmit, setValue, watch, formState, reset } = useForm({
  defaultValues: {
   email: "",
   password: "",
   contact_id: 0
  }
 });

 const onSubmit: SubmitHandler<userType> = async (data: userType) => {
  setIsLoading(true);
  await dispatch(postAddTeamsUser(userId, data.email, data.password, data.contact_id));
  setIsLoading(false);
  setOpenModal(false);
 };

 const handleOpen = async () => {
  reset();
  setContacts(await dispatch(getAllUserContacts()));
  setOpenModal(true);
 };

 const handleClose = () => {
  setOpenModal(false);
 };

 const checkDisabled = (): boolean => {
  return watch("email") === "" || watch("password") === "";
 };

 const handleGeneratePassword = async () => {
  setValue("password", await generatePassword());
 };

 return (
  <>
   {desktopViewPort ? (
    <Button variant="kxActionButton" onClick={handleOpen} endIcon={<AddIcon />}>
     <FormattedMessage id="teams.action.add" />
    </Button>
   ) : (
    <IconButton onClick={handleOpen} color="kxBlue">
     <IconSelector icon="AddIcon" />
    </IconButton>
   )}
   <AppModal
    open={openModal}
    close={handleClose}
    handleClose={handleClose}
    loading={isLoading}
    handleConfirm={handleSubmit(onSubmit)}
    disabled={isLoading || checkDisabled()}
    title={intl.formatMessage({ id: "teams.action.add" })}>
    <form onSubmit={handleSubmit(onSubmit)}>
     <Controller
      name="email"
      control={control}
      rules={{
       required: true,
       pattern: emailValidation
      }}
      render={({ field }) => (
       <Autocomplete
        fullWidth={true}
        autoHighlight
        sx={{ my: 2 }}
        onChange={(e, value) => {
         isNil(value) ? setValue("email", "") : setValue("email", value.email);
         isNil(value) ? setValue("contact_id", 0) : setValue("contact_id", value.id);
        }}
        options={contacts.map((element) => {
         return {
          id: element.id,
          email: element.email,
          label: `${
           element.company !== "" ? element.company : `${element.firstname} ${element.lastname}`
          } - ${element.email}`
         };
        })}
        renderInput={(params) => (
         <TextField
          {...params}
          {...field}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
           e.stopPropagation();
          }}
          label={<FormattedMessage id="teams.email" />}
          error={formState.isDirty && !!formState?.errors?.email}
          InputLabelProps={{ shrink: true }}
         />
        )}
       />
      )}
     />
     <Stack direction={"row"} spacing={2}>
      <Controller
       name="password"
       control={control}
       rules={{
        required: true,
        minLength: 8
       }}
       render={({ field }) => (
        <FormControl fullWidth={true} variant="outlined">
         <InputLabel htmlFor="outlined-adornment-password">
          {intl.formatMessage({ id: "teams.password" })}
         </InputLabel>
         <OutlinedInput
          {...field}
          autoComplete="new-password"
          type={passwordType}
          error={formState.isDirty && !!formState?.errors?.password}
          endAdornment={
           <InputAdornment position="end">
            <IconButton
             aria-label="toggle password visibility"
             onClick={() => setPasswordType(passwordType === "password" ? "text" : "password")}
             edge="end">
             <IconSelector
              icon={passwordType === "text" ? "VisibilityOffIcon" : "VisibilityIcon"}
              props={{ fontSize: "small" }}
             />
            </IconButton>
           </InputAdornment>
          }
          label="Password"
         />
        </FormControl>
       )}
      />
      <Stack direction="row" alignItems="center">
       <Button variant="kxActionButton" sx={{ minWidth: "150px" }} onClick={handleGeneratePassword}>
        <Typography variant="caption">
         <FormattedMessage id="teams.generatePassword" />
        </Typography>
       </Button>
      </Stack>
     </Stack>
    </form>
   </AppModal>
  </>
 );
};

export default AddTeamsUser;
