import React, { ReactElement, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import AppModal from "components/shared/modal/AppModal";

import { postUpdateCloudbucketKey } from "redux/handlers/addonsHandle";

import { useAppDispatch } from "hooks/reduxHook";

import { CloudbucketData } from "types/api/addonsApiInterface";

const UpdateCloudbucketKey = ({
 id,
 key,
 secret,
 url,
 closeMenu,
 reloadData
}: {
 id: number;
 key: string;
 secret: string;
 url: string;
 closeMenu: () => void;
 reloadData?: () => void;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [isLoading, setIsLoading] = useState<boolean>(false);

 const handleOpen = () => {
  reset();
  setOpenModal(true);
 };
 const handleClose = () => setOpenModal(false);

 const { control, handleSubmit, reset } = useForm({
  defaultValues: {
   key: key,
   secret: secret,
   s3url: url
  }
 });

 const onSubmit: SubmitHandler<CloudbucketData> = async (data: CloudbucketData) => {
  setIsLoading(true);
  await dispatch(postUpdateCloudbucketKey(id, data.key, data.secret, data.s3url));
  setIsLoading(false);
  reloadData && reloadData();
  setOpenModal(false);
  closeMenu();
 };

 return (
  <Stack>
   <MenuItem onClick={() => handleOpen()}>
    <FormattedMessage id="configurator.cloudbucket.updateCloudbucket" />
   </MenuItem>
   <AppModal
    open={openModal}
    close={handleClose}
    handleClose={handleClose}
    loading={isLoading}
    handleConfirm={handleSubmit(onSubmit)}
    title={intl.formatMessage({ id: "configurator.cloudbucket.updateCloudbucket" })}>
    <form onSubmit={handleSubmit(onSubmit)}>
     <Stack>
      <Controller
       name={"key"}
       control={control}
       rules={{
        required: true
       }}
       render={({ field }) => (
        <TextField
         {...field}
         fullWidth={true}
         size="small"
         label={intl.formatMessage({ id: "configurator.cloudbucket.key" })}
         InputLabelProps={{ shrink: true }}
         sx={{ my: 2 }}
         autoComplete="off"
        />
       )}
      />
      <Controller
       name={"secret"}
       control={control}
       rules={{
        required: true
       }}
       render={({ field }) => (
        <TextField
         {...field}
         fullWidth={true}
         size="small"
         label={intl.formatMessage({ id: "configurator.cloudbucket.secret" })}
         InputLabelProps={{ shrink: true }}
         sx={{ my: 2 }}
         autoComplete="off"
        />
       )}
      />
      <Controller
       name={"s3url"}
       control={control}
       rules={{
        required: true
       }}
       render={({ field }) => (
        <TextField
         {...field}
         fullWidth={true}
         size="small"
         label={intl.formatMessage({ id: "configurator.cloudbucket.s3url" })}
         InputLabelProps={{ shrink: true }}
         sx={{ my: 2 }}
         autoComplete="off"
        />
       )}
      />
     </Stack>
    </form>
   </AppModal>
  </Stack>
 );
};

export default UpdateCloudbucketKey;
