import React, { ReactElement, useContext, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "react-use";
import { AppContext } from "AppContext";

import { isNil } from "ramda";

import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import TablePagination from "@mui/material/TablePagination";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { firewallsTable } from "constants/tables";

import AdminWrapper from "components/shared/admin/AdminWrapper";
import IconSelector from "components/shared/images/IconSelector";
import SearchForPartner from "components/shared/search/SearchForPartner";
import SortingTable from "components/shared/tables/SortingTable";

import { getAllFirewall } from "redux/handlers/addonsHandle";
import { getUsersList } from "redux/handlers/userHandler";

import { getFirewallsDataset, getFirewallsNumber } from "redux/selectors/firewallSelector";
import { getIsAdmin } from "redux/selectors/userSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { IUsersList } from "types/api/userApiInterface";

const Firewalls = (): ReactElement => {
 const intl = useIntl();
 const navigate = useNavigate();
 const dispatch = useAppDispatch();
 const searchRef = useRef<HTMLInputElement>(null);
 const { socket } = useContext(AppContext);
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));

 const firewallList = useSelector(getFirewallsDataset);
 const firewallNumber = useSelector(getFirewallsNumber);
 const isAdmin = useSelector(getIsAdmin);

 const [currentPage, setCurrentPage] = useState<number>(0);
 const [rowPerPage, setRowPerPage] = useState<number>(10);
 const [searchValue, setSearchValue] = useState<string>("");
 const [partnerList, setPartnerList] = useState<Array<IUsersList>>([]);
 const [isLoading, setIsLoading] = useState<boolean>(true);
 const [selectedPartner, setSelectedPartner] = useState<string>("");
 const [reloadData, setReloadData] = useState<boolean>(false);

 useEffect(() => {
  !isNil(socket) &&
   socket.on("addons_data", (value): void => {
    if (value?.completed && value?.section === "firewall") {
     setReloadData(true);
    }
   });
  return () => {
   !isNil(socket) && socket.off("addons_data");
  };
 }, [socket]);

 useEffect(() => {
  (async () => {
   if (reloadData) {
    dispatch(getAllFirewall(currentPage, rowPerPage, searchRef?.current?.value, selectedPartner));
    setReloadData(false);
   }
  })();
 }, [reloadData]);

 useEffect(() => {
  (async () => {
   setCurrentPage(0);
   isAdmin && setPartnerList(await dispatch(getUsersList()));
   setIsLoading(false);
  })();
 }, []);

 useDebounce(
  () => {
   if (!isLoading) {
    setCurrentPage(0);
    dispatch(getAllFirewall(currentPage, rowPerPage, searchRef?.current?.value, selectedPartner));
   }
  },
  1000,
  [searchValue]
 );

 const handleOnRowsPerPageChange = async (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
 ) => {
  const newRowsPerPage = parseInt(event.target.value, 10);
  setRowPerPage(newRowsPerPage);
  setCurrentPage(0);
  dispatch(getAllFirewall(0, newRowsPerPage, searchRef?.current?.value, selectedPartner));
 };

 const handleOnPageChange = async (
  event: React.MouseEvent<HTMLButtonElement> | null,
  newPage: number
 ) => {
  const startIndex = newPage * rowPerPage;
  setCurrentPage(newPage);
  dispatch(getAllFirewall(startIndex, rowPerPage, searchRef?.current?.value, selectedPartner));
 };

 const handleSearchPartner = (partnerIdValue: string) => {
  setCurrentPage(0);
  setSelectedPartner(partnerIdValue);
  dispatch(
   getAllFirewall(currentPage, rowPerPage, searchRef?.current?.value || "", partnerIdValue)
  );
 };

 return (
  <Stack mt={desktopViewPort ? 5 : 2}>
   <Stack justifyContent="space-between" alignItems="flex-end" direction="row" pb={1}>
    <Typography fontWeight="bold" fontSize={16} textTransform="uppercase">
     <FormattedMessage id="addons.firewall" />
     {` (${firewallNumber})`}
    </Typography>
    {desktopViewPort ? (
     <Button
      variant="kxActionButton"
      endIcon={<AddIcon />}
      onClick={() => navigate("/addons/firewall/add")}>
      <FormattedMessage id="addons.firewall.addFirewall" />
     </Button>
    ) : (
     <IconButton color="kxBlue" onClick={() => navigate("/addons/firewall/add")}>
      <IconSelector icon="AddIcon" />
     </IconButton>
    )}
   </Stack>
   <Alert severity="info" sx={{ mb: 2 }}>
    <FormattedMessage id="addons.firewall.info" />
   </Alert>
   <Paper elevation={0} sx={{ borderRadius: "10px", boxShadow: 0 }}>
    <Stack
     justifyContent="flex-start"
     alignItems="center"
     p={2}
     spacing={2}
     direction={desktopViewPort ? "row" : "column"}>
     <TextField
      size="small"
      autoComplete="off"
      inputRef={searchRef}
      label={intl.formatMessage({ id: "addons.firewall.searchFirewall" })}
      InputLabelProps={{ shrink: true }}
      InputProps={{ startAdornment: <SearchIcon color="disabled" /> }}
      onChange={({ currentTarget }) => setSearchValue(currentTarget.value)}
     />
     <AdminWrapper>
      <SearchForPartner
       contactList={partnerList}
       handleSearchPartner={(partner) => handleSearchPartner(partner)}
      />
     </AdminWrapper>
    </Stack>
    <Divider sx={{ borderColor: "#f86e06" }} />
    <Stack>
     <SortingTable
      tableData={firewallsTable}
      maxWidth={650}
      caller="firewalls"
      listToShow={firewallList}
     />
     <TablePagination
      component="div"
      count={firewallNumber || 0}
      page={currentPage}
      rowsPerPage={rowPerPage}
      onPageChange={handleOnPageChange}
      onRowsPerPageChange={handleOnRowsPerPageChange}
     />
    </Stack>
   </Paper>
  </Stack>
 );
};

export default Firewalls;
