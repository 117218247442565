/* eslint-disable react/style-prop-object */
import React, { ReactElement } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { formatCountryCode } from "helpers/server";

import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { ILocations } from "types/api/serversApiInterface";

const ContaboCloudbucketRecapBox = ({
 size,
 autoscaling,
 price,
 locationData
}: {
 size: number;
 autoscaling: boolean;
 price: number;
 locationData: ILocations | null;
}): ReactElement => {
 return (
  <>
   <Divider textAlign="left" sx={{ mt: 2 }}>
    <Typography variant="overline">
     <FormattedMessage id="addons.cloudbucket.specs" />
    </Typography>
   </Divider>
   <Stack direction="row" spacing={2}>
    <Typography minWidth={150} textAlign="start" fontWeight="bold">
     <FormattedMessage id="addons.cloudbucket.size" />:
    </Typography>
    <Typography>{`${size > 1000 ? size / 1000 : size} ${size > 1000 ? "TB" : "GB"}`}</Typography>
   </Stack>
   <Stack direction="row" spacing={2}>
    <Typography minWidth={150} textAlign="start" fontWeight="bold">
     <FormattedMessage id="addons.cloudbucket.location" />:
    </Typography>
    <Typography noWrap>{locationData?.city || ""}</Typography>
    <Stack component="span" className={`fi fi-${formatCountryCode(locationData?.country || "")}`} />
   </Stack>
   <Stack direction="row" spacing={2}>
    <Typography minWidth={150} textAlign="start" fontWeight="bold">
     <FormattedMessage id="addons.cloudbucket.autoscaling" />:
    </Typography>
    <Typography>{autoscaling ? "Attivo" : "Disattivo"}</Typography>
   </Stack>
   <Divider sx={{ my: 1 }} />
   <Stack>
    <Stack direction="row" spacing={2} alignItems="center">
     <Typography minWidth={150} textAlign="start" fontWeight="bold">
      <FormattedMessage id="addons.cloudbucket.totalPrice" />
     </Typography>
     <Typography fontSize={"x-large"} fontStyle="italic">
      <FormattedNumber value={price} style={"currency"} currency="EUR" />
     </Typography>
    </Stack>
   </Stack>
  </>
 );
};

export default ContaboCloudbucketRecapBox;
