import React, { ReactElement, useState } from "react";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import Stack from "@mui/material/Stack";

import IconSelector from "components/shared/images/IconSelector";

import CompleteCreation from "./actions/CompleteCreation";
import CompleteInstall from "./actions/CompleteInstall";
import UpdateCloudbucketKey from "./actions/UpdateCloudbucketKey";

const CloudbucketMenu = ({
 id,
 status,
 key,
 secret,
 url,
 reloadData
}: {
 id: number;
 status: string;
 key: string;
 secret: string;
 url: string;
 reloadData: () => void;
}): ReactElement => {
 const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
 const open = Boolean(anchorEl);

 const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
  setAnchorEl(event.currentTarget);
 };

 const handleClose = () => {
  setAnchorEl(null);
 };

 return (
  <>
   <Button onClick={handleOpen}>
    <IconSelector icon="MenuIcon" />
   </Button>
   <Menu
    id="basic-menu"
    anchorEl={anchorEl}
    open={open}
    onClose={handleClose}
    MenuListProps={{
     "aria-labelledby": "basic-button"
    }}>
    <Stack>
     <UpdateCloudbucketKey
      id={id}
      key={key}
      secret={secret}
      url={url}
      closeMenu={handleClose}
      reloadData={reloadData}
     />
     {status === "creating" && (
      <CompleteCreation id={id} closeMenu={handleClose} reloadData={reloadData} />
     )}
     {status === "updating" && (
      <CompleteInstall id={id} closeMenu={handleClose} reloadData={reloadData} />
     )}
    </Stack>
   </Menu>
  </>
 );
};

export default CloudbucketMenu;
