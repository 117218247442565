import React, { ReactElement, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import AddIcon from "@mui/icons-material/Add";

import { IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { postAddCronjob } from "redux/handlers/websitesHandler";

import { useAppDispatch } from "hooks/reduxHook";

type cronData = {
 command: string;
 run_min: string;
 run_hour: string;
 run_mday: string;
 run_month: string;
 run_wday: string;
};

const AddCronjob = ({
 isp_id,
 domain_id,
 domain_db_id
}: {
 isp_id: number;
 domain_id: number;
 domain_db_id: number;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);

 const onSubmit: SubmitHandler<cronData> = async (data: cronData) => {
  setLoading(true);
  await dispatch(
   postAddCronjob(
    isp_id,
    data.command,
    "url",
    domain_id,
    data.run_min,
    data.run_hour,
    data.run_mday,
    data.run_month,
    data.run_wday,
    domain_db_id
   )
  );
  setLoading(false);
  handleClose();
 };

 const { control, formState, handleSubmit } = useForm({
  defaultValues: {
   command: "",
   run_min: "",
   run_hour: "",
   run_mday: "",
   run_month: "",
   run_wday: ""
  }
 });

 return (
  <>
   {desktopViewPort ? (
    <Button variant="kxActionButton" endIcon={<AddIcon />} onClick={handleOpen}>
     <FormattedMessage id="sites.addCronjob" />
    </Button>
   ) : (
    <IconButton onClick={handleOpen} color="kxBlue">
     <IconSelector icon="AddIcon" />
    </IconButton>
   )}
   <AppModal
    open={openModal}
    close={handleClose}
    title={intl.formatMessage({ id: "sites.addCronjob" })}
    handleClose={handleClose}
    disabled={loading}
    handleConfirm={handleSubmit(onSubmit)}>
    <form onSubmit={handleSubmit(onSubmit)}>
     <Controller
      name="command"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <TextField
        {...field}
        size="small"
        fullWidth={true}
        label={<FormattedMessage id="sites.cronjob.command" />}
        error={formState.isDirty && !!formState?.errors?.command}
        InputLabelProps={{ shrink: true }}
        helperText={<FormattedMessage id="sites.cronjob.commandHelper" values={{ br: <br /> }} />}
        sx={{ my: 2 }}
        autoComplete="off"
       />
      )}
     />
     <Controller
      name="run_min"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <TextField
        {...field}
        size="small"
        fullWidth={true}
        label={<FormattedMessage id="sites.cronjob.run_min" />}
        error={formState.isDirty && !!formState?.errors?.run_min}
        helperText={<FormattedMessage id="sites.cronjob.run_minHelper" />}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="off"
       />
      )}
     />
     <Controller
      name="run_hour"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <TextField
        {...field}
        size="small"
        fullWidth={true}
        label={<FormattedMessage id="sites.cronjob.run_hour" />}
        error={formState.isDirty && !!formState?.errors?.run_hour}
        helperText={<FormattedMessage id="sites.cronjob.run_hourHelper" />}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="off"
       />
      )}
     />
     <Controller
      name="run_wday"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <TextField
        {...field}
        size="small"
        fullWidth={true}
        label={<FormattedMessage id="sites.cronjob.run_wday" />}
        error={formState.isDirty && !!formState?.errors?.run_wday}
        helperText={<FormattedMessage id="sites.cronjob.run_wdayHelper" />}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="off"
       />
      )}
     />
     <Controller
      name="run_mday"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <TextField
        {...field}
        size="small"
        fullWidth={true}
        label={<FormattedMessage id="sites.cronjob.run_mday" />}
        error={formState.isDirty && !!formState?.errors?.run_mday}
        helperText={<FormattedMessage id="sites.cronjob.run_mdayHelper" />}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="off"
       />
      )}
     />
     <Controller
      name="run_month"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <TextField
        {...field}
        size="small"
        fullWidth={true}
        label={<FormattedMessage id="sites.cronjob.run_month" />}
        error={formState.isDirty && !!formState?.errors?.run_month}
        helperText={<FormattedMessage id="sites.cronjob.run_monthHelper" />}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="off"
       />
      )}
     />
    </form>
   </AppModal>
  </>
 );
};

export default AddCronjob;
