import React, { ReactElement, useEffect, useState } from "react";

import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";

import Security from "components/profile/security/Security";
import SkeletonProfile from "components/shared/skeletons/SkeletonProfile";

const SecurityPage = (): ReactElement => {
 const [isLoading, setIsLoading] = useState<boolean>(true);

 useEffect(() => {
  (async () => {
   setIsLoading(false);
  })();
 }, []);

 return (
  <Container maxWidth="xl">
   <Stack>{isLoading ? <SkeletonProfile id="profile.security" /> : <Security />}</Stack>
  </Container>
 );
};

export default SecurityPage;
